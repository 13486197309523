import {
  LOGOUT,
  SET_TOKEN,
  SET_2FA_TOKEN,
  UPDATE_ME,
  SET_AUTHENTICATED,
  SET_2FA_METHODS,
  ADD_2FA_METHODS,
} from "../types";
import jwt_decode from "jwt-decode";
import {persistReducer} from "redux-persist";
import storage from "localforage";

const initialState = {
  authenticated: undefined,
  token: null,
  twoFAToken: null,
  admin: false,
  site_admin: false,
  user: null,
  access: [],
  change_password: null,
}

export default persistReducer({
  key: 'auth',
  storage,
  whitelist: ['token', 'twoFAToken', 'admin', "site_admin", 'access', 'change_password'],
}, function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return setToken(state, action);
    case SET_2FA_TOKEN:
      return set2FAToken(state, action);
    case LOGOUT:
      return logout(state, action);
    case UPDATE_ME:
      return updateMe(state, action);
    case SET_AUTHENTICATED:
      return setAuthenticated(state, action);
    case SET_2FA_METHODS:
      return set2FAMethods(state, action);
    case ADD_2FA_METHODS:
      return add2FAMethods(state, action);
    default:
      return state;
  }
});

function updateMe(state, action) {
  const newState = {...state};
  newState.user = {...newState.user};
  newState.user = Object.assign(newState.user, action.payload);
  return newState;
}

function logout(state) {
  return {
    ...state,
    "token": null,
    "authenticated": false,
    "admin": false,
    "site_admin": false,
    "user": null,
    "access": [],
    "change_password": null,
  };
}

function setToken(state, action) {
  let decoded = jwt_decode(action.payload);
  return {
    ...state,
    "token": action.payload,
    "authenticated": false,
    "admin": decoded.admin,
    "site_admin": decoded.site_admin,
    "access": decoded.site_access,
    "user": state.user,
    "change_password": decoded.change_password
  };
}

function set2FAToken(state, { payload }) {
  return {
    ...state,
    twoFAToken: payload,
  };
}

function setAuthenticated(state) {
  return {
    ...state,
    authenticated: true,
  };
}

function set2FAMethods(state, { payload: twoFAMethods }) {
  return {
    ...state,
    twoFAMethods,
  };
}

function add2FAMethods(state, { payload: twoFAMethods }) {
  return {
    ...state,
    twoFAMethods: [
      ...state.twoFAMethods ?? [],
      ...twoFAMethods?.filter?.(m => !state.twoFAMethods?.includes?.(m)) ?? [],
    ].sort(),
  };
}
