import { ADD_TOAST, REMOVE_TOAST, CLEAR_TOASTS } from "../types";
import { guid } from "../../utils/helpers";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST: {
      return addToast(state, action);
    }
    case REMOVE_TOAST: {
      return state.filter(x => x.id !== action.payload);
    }
    case CLEAR_TOASTS: {
      return [];
    }
    default: {
      return state;
    }
  }
}

function addToast(state, action) {
  let newState = [...state], index = newState.findIndex(x => x.message === action.payload.message);
  if (index >= 0) {
    newState[index].id = guid();
  } else {
    newState.push(action.payload);
  }
  return newState;
}