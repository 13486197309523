import { defineMessages } from "react-intl";

export default defineMessages({
  "GuestSignIn": {
    id: "CheckIn.GuestSignIn",
    defaultMessage: "Guest Sign In"
  },
  "FirstName": {
    id: "CheckIn.FirstName",
    defaultMessage: "First Name"
  },
  "LastName": {
    id: "CheckIn.LastName",
    defaultMessage: "Last Name"
  },
  "Phone": {
    id: "CheckIn.Phone",
    defaultMessage: "Phone"
  },
  "DependantsLabel": {
    id: "CheckIn.DependantsLabel",
    defaultMessage: "How many dependants are entering with you?",
  },
  "Dependants": {
    id: "CheckIn.Dependants",
    defaultMessage: "Dependants"
  },
  "Continue": {
    id: "CheckIn.Continue",
    defaultMessage: "Continue"
  },
  "Back": {
    id: "CheckIn.Back",
    defaultMessage: "Back"
  },
  "Details": {
    id: "CheckIn.Details",
    defaultMessage: "Details"
  },
  "Questions": {
    id: "CheckIn.Questions",
    defaultMessage: "Questions"
  },
  "EnterCode": {
    id: "CheckIn.EnterCode",
    defaultMessage: "Enter Code"
  },
  "RoleQuestionText": {
    id: "CheckIn.RoleQuestionText",
    defaultMessage: "Who are you attending as?"
  },
  "ScanTemperature": {
    id: "CheckIn.ScanTemperature",
    defaultMessage: "Scan Temperature."
  },
  "EnterTheCodeDisplayed": {
    id: "CheckIn.EnterTheCodeDisplayed",
    defaultMessage: "Enter the code displayed."
  },
  "WaitingForAResponse": {
    id: "CheckIn.WaitingForAResponse",
    defaultMessage: "Waiting for a response."
  },
  "PleaseTryAgain": {
    id: "CheckIn.PleaseTryAgain",
    defaultMessage: "Please try again."
  },
  "SubmissionFailed": {
    id: "CheckIn.SubmissionFailed",
    defaultMessage: "Submission failed."
  },
  "Retry": {
    id: "CheckIn.Retry",
    defaultMessage: "Retry"
  },
  "CoolGardPtyLtd": {
    id: "CheckIn.CoolGardPtyLtd",
    defaultMessage: "© CoolGard Pty Ltd"
  },
  "PulseMiningSystems": {
    id: "CheckIn.PulseMiningSystems",
    defaultMessage: "© Pulse Mining Systems"
  },
  "PoweredBy": {
    id: "CheckIn.PoweredBy",
    defaultMessage: "Powered by"
  },
  "StartAgain": {
    id: "CheckIn.StartAgain",
    defaultMessage: "Start Again"
  },
  "Success": {
    id: "CheckIn.Success",
    defaultMessage: "Success!"
  },
  "NameInfo": {
    id: "CheckIn.NameInfo",
    defaultMessage: "Name:"
  },
  "MobileInfo": {
    id: "CheckIn.MobileInfo",
    defaultMessage: "Mobile:"
  },
  "VenueInfo": {
    id: "CheckIn.VenueInfo",
    defaultMessage: "Venue:"
  },
  "CheckedInInfo": {
    id: "CheckIn.CheckedInInfo",
    defaultMessage: "Checked In:"
  },
  "CheckedOutInfo": {
    id: "CheckIn.CheckedOutInfo",
    defaultMessage: "Checked Out:"
  },
  "TemperatureInfo": {
    id: "CheckIn.TemperatureInfo",
    defaultMessage: "Temperature:"
  },
  "Error": {
    id: "CheckIn.Error",
    defaultMessage: "Error"
  },
  "SignInPageIsUnavailable": {
    id: "CheckIn.SignInPageIsUnavailable",
    defaultMessage: "The sign-in page you are trying to reach is unavailable."
  },
  "HowDoISignIn": {
    id: "CheckIn.HowDoISignIn",
    defaultMessage: "How do I sign in?"
  },
  "HowDoISignInMessage": {
    id: "CheckIn.HowDoISignInMessage",
    defaultMessage: "Please sign in manually on the CoolGard device or via the venues alternative method."
  },
  "UnavailableVenueStaff": {
    id: "CheckIn.UnavailableVenueStaff",
    defaultMessage: "Venue Staff"
  },
  "UnavailableVenueStaffMessage": {
    id: "CheckIn.UnavailableVenueStaffMessage",
    defaultMessage: "Please make sure your QR code is setup in your management portal."
  },
  "SuccessDetail": {
    id: "CheckIn.SuccessDetail",
    defaultMessage: "<p>You may need to show this confirmation to venue staff.</p>"
  },
  "HighTemperatureMessage": {
    id: "CheckIn.HighTemperatureMessage",
    defaultMessage: "Temperature Alert"
  },
  "HighTemperatureDetail": {
    id: "CheckIn.HighTemperatureDetail",
    defaultMessage: "<p>You have recorded an elevated temperature.</p><p>Do not proceed past this point and notify venue staff.</p>"
  },
  "LowTemperatureMessage": {
    id: "CheckIn.LowTemperatureMessage",
    defaultMessage: "Temperature Alert"
  },
  "LowTemperatureDetail": {
    id: "CheckIn.LowTemperatureDetail",
    defaultMessage: "<p>You have recorded a low temperature.</p><p>Do not proceed past this point and notify venue staff.</p>"
  },
  "BannedMessage": {
    id: "CheckIn.BannedMessage",
    defaultMessage: "You have an active ban"
  },
  "BannedDetail": {
    id: "CheckIn.BannedDetail",
    defaultMessage: "<p>Do not proceed past this point.</p><p>Please see venue staff for details.</p>"
  },
  "CheckOut": {
    id: "CheckIn.CheckOut",
    defaultMessage: "Check out"
  },
  "CheckOutSignInTime": {
    id: "CheckIn.CheckOutSignInTime",
    defaultMessage: "You checked in on {time, date, long} at {time, time, short}."
  },
  "CheckOutLabel": {
    id: "CheckIn.CheckOutLabel",
    defaultMessage: "Enter the time you checked out."
  }
});
