import { defineMessages } from "react-intl";

export default defineMessages({
  "LogOut": {
    id: "CheckInBoard.LogOut",
    defaultMessage: "Log Out",
  },
  "UserPhoto": {
    id: "CheckInBoard.UserPhoto",
    defaultMessage: "User photo",
  },
  "CheckedIn": {
    id: "CheckInBoard.CheckedIn",
    defaultMessage: "Checked In",
  },
  "CheckedOut": {
    id: "CheckInBoard.CheckedOut",
    defaultMessage: "Checked Out",
  },
  "CheckedInAt": {
    id: "CheckInBoard.CheckedInAt",
    defaultMessage: "Checked in at {logDate, time, short}",
  },
  "LastSeen": {
    id: "CheckInBoard.LastSeen",
    defaultMessage: "Last seen on {logDate, date, short}",
  },
  "NeverSeen": {
    id: "CheckInBoard.NeverSeen",
    defaultMessage: "Never seen",
  },
  "ChooseASite": {
    id: "CheckInBoard.ChooseASite",
    defaultMessage: "Choose a site",
  },
  "PhoneExt": {
    id: "CheckInBoard.PhoneExt",
    defaultMessage: "Ext: {phoneExt, number}",
  },
  "ShowAllUsers": {
    id: "CheckInBoard.ShowAllUsers",
    defaultMessage: "Show absent",
  },
  "CheckedPeople": {
    id: "CheckInBoard.CheckedPeople",
    defaultMessage: "Checked {checked, number} out of {people, plural, one {1 person} other {{people, number} people}}",
  },
  "PeopleOnSite": {
    id: "CheckInBoard.PeopleOnSite",
    defaultMessage: "{people, plural, one {1 person} other {{people, number} people}} on site at {site}",
  },
  "Reset": {
    id: "CheckInBoard.Reset",
    defaultMessage: "Reset",
  },
  "LastUpdatedAt": {
    id: "CheckInBoard.LastUpdatedAt",
    defaultMessage: "Last updated at {lastUpdated, date, short}, {lastUpdated, time, medium}",
  },
});
