import React, { useEffect, useRef, useReducer, useState, useMemo, useLayoutEffect } from "react";
import { injectIntl } from "react-intl";
import PinField from 'react-pin-field';
import { useUnloadBlock } from "../utils/react";
import Button from "../components/widgets/Button/Button";
import Input from "../components/widgets/Input/Input";
import Modal, {Section as ModalSection, Body as ModalBody} from "../components/widgets/Modal/Modal";
import Spinner from "../components/widgets/Spinner/Spinner";
import ToastInline from "../components/widgets/Toast/ToastInline";
import { connect } from 'react-redux';
import {
  update2FAMethods,
  enable2FAMethods,
  authenticateUser,
} from '../redux/actions';
import ajax from "../utils/ajax";
import { generateCheckInQrCode } from '../utils/qrcode';
import DomRenderer from '../components/DomRenderer';
import classes from './TwoFASetup.module.css';
import messages from './TwoFASetup.messages';

function IntroPage(props) {
  const { intl, toasts, totpEnabled, newTotpMethod, preSignIn,
          setTitle = () => {}, setCanClose = () => {},
          onBack = () => {}, onCancel = () => {}, onNext = () => {} } = props;

  useEffect(() => {
    if (newTotpMethod) {
      setCanClose(false);
      return () => setCanClose(true);
    }
  }, [newTotpMethod]);
  useUnloadBlock(newTotpMethod);

  useEffect(() => {
    if (preSignIn) {
      setTitle(intl.formatMessage(messages.Step1Title));
    } else if (totpEnabled) {
      setTitle(intl.formatMessage(messages.ResetAuthenticatorApp));
    } else {
      setTitle(intl.formatMessage(messages.EnableAuthenticatorApp));
    }
    return () => setTitle(null);
  }, [preSignIn, totpEnabled]);

  return <>
    <ModalBody>
      {toasts}
      <ModalSection className={classes.TwoFAModalBody}>
        {!totpEnabled ? <>
          <p>{intl.formatMessage(messages.TOTPCreateInstr1)}</p>
          <p>{intl.formatMessage(messages.TOTPCreateInstr2, {
            appStore: str => <a target="_blank" rel="noreferrer" href="https://apps.apple.com/au/app/google-authenticator/id388497605">{str}</a>,
            playStore: str => <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">{str}</a>,
            strong: str => <strong>{str}</strong>,
          })}</p>
          <p>{intl.formatMessage(messages.TOTPCreateInstr3)}</p>
        </> : <>
          <p>{intl.formatMessage(messages.TOTPResetInstr1)}</p>
          <p>{intl.formatMessage(messages.TOTPResetInstr2, {
            appStore: str => <a target="_blank" rel="noreferrer" href="https://apps.apple.com/au/app/google-authenticator/id388497605">{str}</a>,
            playStore: str => <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">{str}</a>,
            strong: str => <strong>{str}</strong>,
          })}</p>
          <p>{intl.formatMessage(messages.TOTPCreateInstr3)}</p>
        </>}
      </ModalSection>
    </ModalBody>

    <ModalSection layout="horizontal">
      {totpEnabled ? <>
        {newTotpMethod ?
          <Button onClick={onCancel} variant="danger" style={{width: "10rem"}}>{intl.formatMessage(messages.Cancel)}</Button> :
          <Button onClick={onBack} link style={{width: "6rem"}}>{intl.formatMessage(messages.Back)}</Button>}
        <div className="flex"/>
        <Button onClick={onNext} style={{width: "18rem"}}>{intl.formatMessage(messages.ResetAuthenticatorApp)}</Button>
      </> : <>
        <div className="flex"/>
        <Button onClick={onNext} style={{width: "12rem"}}>{intl.formatMessage(messages.Next)}</Button>
      </>}
    </ModalSection>
  </>;
}

function ListPage(props) {
  const { intl, toasts, recoveryCodesEnabled,
          onAuthenticatorClick = () => {}, onRecoveryCodesClick = () => {}} = props;

  return <>
    <ModalBody>
      {toasts}
      <ModalSection className={classes.TwoFAModalBody}>
        <h1>{intl.formatMessage(messages.AuthenticatorApp)}</h1>
        <p>{intl.formatMessage(messages.AuthenticatorAppDesc)}</p>
        <Button className={classes.ListActionButton}
                onClick={onAuthenticatorClick}>{intl.formatMessage(messages.ResetAuthenticatorApp)}</Button>
      </ModalSection>

      <ModalSection className={classes.TwoFAModalBody}>
        <h1>{intl.formatMessage(messages.RecoveryCodes)}</h1>
        {recoveryCodesEnabled ? <>
          <p>{intl.formatMessage(messages.RecoveryCodesDesc)}</p>
          <Button className={classes.ListActionButton}
                  onClick={onRecoveryCodesClick}>{intl.formatMessage(messages.ViewOrEditRecoveryCodes)}</Button>
        </> : <>
          <p>{intl.formatMessage(messages.RecoveryCodesNoneLeftDesc1)}</p>
          <p>{intl.formatMessage(messages.RecoveryCodesNoneLeftDesc2)}</p>
          <Button className={classes.ListActionButton}
                  onClick={onRecoveryCodesClick}>{intl.formatMessage(messages.GenerateNewCodes)}</Button>
        </>}
      </ModalSection>
    </ModalBody>
  </>;
}

function CodesPage(props) {
  const { intl, toasts, preSignIn, totpEnabled, isBatbooth, isPulse, newRecoveryCodesMethod,
          existingRecoveryCodesMethod, recoveryCodesEnabled,
          setTitle = () => {}, setCanClose = () => {},
          onBack = () => {}, onNext = () => {}, onNewCodes = () => {},
          onCancel = () => {}} = props;

  const printFrameRef = useRef();
  const textAreaRef = useRef();
  const [readyToEnableId, setReadyToEnableId] = useState(null);

  const nextEnabled = !newRecoveryCodesMethod || (readyToEnableId === props.method?.id);
  const method = newRecoveryCodesMethod || existingRecoveryCodesMethod;

  useEffect(() => {
    if (newRecoveryCodesMethod) {
      setCanClose(false);
      return () => setCanClose(true);
    }
  }, [newRecoveryCodesMethod]);
  useUnloadBlock(newRecoveryCodesMethod);

  useEffect(() => {
    if (preSignIn) {
      setTitle(intl.formatMessage(messages.Step4Title));
    } else if (newRecoveryCodesMethod) {
      setTitle(intl.formatMessage(messages.GenerateNewCodes));
    } else {
      setTitle(intl.formatMessage(messages.RecoveryCodes));
    }
    return () => setTitle(null);
  }, [preSignIn, newRecoveryCodesMethod]);

  function center(txt) {
    // Center txt on a 59 character line
    const numSpaces = Math.max(Math.floor((59 - txt.length) / 2), 0);
    return Array.from({length: numSpaces}, () => ' ').join('') + txt;
  }

  function wrap(txt) {
    // Wrap txt to 59 character lines
    return txt.replace(/(.{1,59})(?:([ \t]*\r?\n|$)|([ \t]+))/g,
      (_, line, tail, space) => `${line}${space ? '\r\n' : tail}`);
  }

  const title = intl.formatMessage(messages.CodesTxtHeading, {
    appName: isBatbooth ? 'Bat Booth' : (isPulse ? 'Pulse Presence' : 'CoolGard'),
  });
  const separator = '                         ----------';
  let txt = `${center(title)}\r\n\r\n${separator}`;

  let even = false;
  for (const recoveryCode of (method?.recoveryCodes ?? [])) {
    if (!even) {
      txt += `\r\n\r\n          ${recoveryCode}`;
    } else {
      txt += `              ${recoveryCode}`
    }
    even = !even;
  }

  const instruction = intl.formatMessage(messages.CodesTxtInstruction, {
    appName: isBatbooth ? 'Bat Booth' : (isPulse ? 'Pulse Presence' : 'CoolGard'),
  });
  txt += `\r\n\r\n${separator}\r\n\r\n${wrap(instruction)}\r\n`;

  function onDownload() {
    const link = document.createElement('a');
    link.download = `${isBatbooth ? 'batbooth' : (isPulse ? 'PulsePresence' : 'CoolGard')}-recovery-codes.txt`;
    link.href = 'data:text/plain;base64,' + btoa(txt);
    link.click();
    setReadyToEnableId(props.method?.id);
  }

  function onPrint() {
    const printFrame = printFrameRef.current;
    printFrame.srcdoc =
      `<!doctype html><meta charset="utf-8"><title>${title}</title><pre>${txt}</pre>`;
    printFrame.addEventListener('load', load);
    function load() {
      printFrame.removeEventListener('load', load);
      printFrame.contentWindow.focus();
      printFrame.contentWindow.print();
      setReadyToEnableId(props.method?.id);
    }
  }

  async function onCopy() {
    if (navigator.clipboard?.writeText) {
      await navigator.clipboard.writeText(txt);
      setReadyToEnableId(props.method?.id);
    } else {
      const textArea = textAreaRef.current;
      textArea.value = txt;
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      setReadyToEnableId(props.method?.id);
    }
  }

  return <>
    <ModalBody>
      {toasts}
      <ModalSection className={classes.TwoFAModalBody}>
        {(preSignIn || !newRecoveryCodesMethod) ?
          <p>{intl.formatMessage(messages.RecoveryCodesInstr1)}</p> :
          recoveryCodesEnabled ?
            <p>{intl.formatMessage(messages.RecoveryCodesRecreateInstr)}</p> :
            <p>{intl.formatMessage(messages.RecoveryCodesExpendedInstr)}</p>}
        <p>{intl.formatMessage(messages.RecoveryCodesInstr2)}</p>
        <div className={`${classes.Codes} ${isBatbooth || isPulse ? '' : classes.CoolGard}`}>
          {method?.recoveryCodes?.map?.((c, i) => <div key={i}>{c + ' '}</div>)}
        </div>
        <div className={classes.CodeButtons}>
          <Button onClick={onDownload}>{intl.formatMessage(messages.Download)}</Button>
          <Button onClick={onPrint}>{intl.formatMessage(messages.Print)}</Button>
          <Button onClick={onCopy}>{intl.formatMessage(messages.Copy)}</Button>
        </div>
        <iframe ref={printFrameRef} className={classes.PrintIFrame}/>
        <textarea ref={textAreaRef} className={classes.CopyTextArea}/>
      </ModalSection>
    </ModalBody>

    <ModalSection layout="horizontal">
      {!newRecoveryCodesMethod &&
        <Button onClick={onBack} link style={{width: "6rem"}}>{intl.formatMessage(messages.Back)}</Button>}
      {newRecoveryCodesMethod && recoveryCodesEnabled &&
        <Button onClick={onCancel} variant="danger" style={{width: "10rem"}}>{intl.formatMessage(messages.Cancel)}</Button>}
      <div className="flex"/>
      {newRecoveryCodesMethod ?
        totpEnabled ?
          <Button disabled={!nextEnabled} onClick={onNext} variant="success" style={{width: "18rem"}}>{intl.formatMessage(messages.ActivateNewCodes)}</Button> :
          <Button disabled={!nextEnabled} onClick={onNext} style={{width: "12rem"}}>{intl.formatMessage(messages.Next)}</Button> :
        <Button onClick={onNewCodes} style={{width: "18rem"}}>{intl.formatMessage(messages.GenerateNewCodes)}</Button>}
    </ModalSection>
  </>;
}

function AuthenticatorQrPage(props) {
  const { intl, toasts, preSignIn, method, isBatbooth, isPulse,
          setTitle = () => {}, setCanClose = () => {},
          onNext = () => {}, onBack = () => {} } = props;
  const [showCode, setShowCode] = useState(false);

  const svgText = useMemo(() => generateCheckInQrCode(method?.uri ?? '', {
    size: '204px',
    errorCorrectionLevel: 'L',
    primary: isBatbooth || isPulse ? '#066C98' : '#00686B',
    secondary: '#ffffff',
    border: isBatbooth || isPulse ? '#066C98' : '#00686B',
    logo: false,
  }), [method?.uri]);
  const svgDoc = (new DOMParser()).parseFromString(svgText, 'image/svg+xml');
  const svg = document.adoptNode(svgDoc.documentElement);

  const secret = [];
  if (method?.secret) {
    for (let i = 0; i < method.secret.length; i += 4)
      secret.push(method.secret.substring(i, i + 4));
  }

  useEffect(() => {
    if (preSignIn) {
      setTitle(intl.formatMessage(messages.Step2Title));
    } else {
      setTitle(intl.formatMessage(messages.EnableAuthenticatorApp));
    }
    return () => setTitle(null);
  }, [preSignIn]);

  useEffect(() => {
    setCanClose && setCanClose(false);
    return () => setCanClose(true);
  }, []);
  useUnloadBlock();

  function onManualEntry() {
    setShowCode(true);
  }
  function onQrEntry(e) {
    setShowCode(false);
  }

  return <>
    <ModalBody className={classes.TwoFASetupContent}>
      {toasts}
      <ModalSection className={classes.TwoFAModalBody}>
        {showCode ? <>
          <h3>{intl.formatMessage(messages.TOTPRegisterMethod2)}</h3>
          <p>{intl.formatMessage(messages.TOTPManualInstr)}</p>
          <div className={classes.SetupKey}>
            <h3>{intl.formatMessage(messages.SetupKey)}</h3>
            <p>{secret.map(s => <span key={s}>{s}</span>)}</p>
          </div>
          <button className={classes.CantScan} onClick={onQrEntry}>{intl.formatMessage(messages.ScanQrCodeInstead)}</button>
        </> : <>
          <h3>{intl.formatMessage(messages.TOTPRegisterMethod1)}</h3>
          <p>{intl.formatMessage(messages.TOTPScanInstr)}</p>
          <DomRenderer className={classes.TwoFAQRCode} elem={svg}/>
          <button className={classes.CantScan} onClick={onManualEntry}>{intl.formatMessage(messages.ICantScanThisQrCode)}</button>
        </>}

      </ModalSection>
    </ModalBody>

    <ModalSection layout="horizontal">
      <Button onClick={onBack} link style={{width: "6rem"}}>{intl.formatMessage(messages.Back)}</Button>
      <div className="flex"/>
      <Button onClick={onNext} style={{width: "12rem"}}>{intl.formatMessage(messages.Next)}</Button>
    </ModalSection>
  </>;
}

function AuthenticatorConfirmPage(props) {
  const { intl, toasts, preSignIn,
          setTitle = () => {}, setCanClose = () => {},
          onNext = () => {}, onBack = () => {}} = props;
  const wrapperRef = useRef();
  const totpCodesRef = useRef();

  useEffect(() => {
    if (preSignIn) {
      setTitle(intl.formatMessage(messages.Step3Title));
    } else {
      setTitle(intl.formatMessage(messages.EnableAuthenticatorApp));
    }
    return () => setTitle(null);
  }, [preSignIn]);

  useEffect(() => {
    setCanClose(false);
    return () => setCanClose(true);
  }, []);
  useUnloadBlock();

  useLayoutEffect(() => {
    if (totpCodesRef.current?.[0])
      totpCodesRef.current[0].focus();
  }, []);

  function onAnimationEnd() {
    wrapperRef.current.style.animation = '';
  }

  async function onTotpCodeComplete(code) {
    if (!await onNext(code)) {
      // Clear code fields
      if (totpCodesRef.current) {
        for (const input of totpCodesRef.current)
          input.value = '';
        if (totpCodesRef.current?.[0])
          totpCodesRef.current[0].focus();
      }

      // Show shake animation
      wrapperRef.current.style.animation = `${classes['invalid-code']} .5s linear`;
    }
  }

  return <>
    <ModalBody className={classes.TwoFASetupContent}>
      {toasts}
      <ModalSection className={classes.TwoFAModalBody}>
        <p>{intl.formatMessage(messages.TOTPConfirmInstr)}</p>
        <div ref={wrapperRef}
             className={classes.VerifyCode}
             onAnimationEnd={onAnimationEnd}>
          <PinField ref={totpCodesRef}
                    onComplete={onTotpCodeComplete}
                    type="tel"
                    inputMode="numeric"
                    required
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    spellCheck="false"
                    pattern="[0-9]*"
                    validate="0123456789"
                    length={6}/>
        </div>
      </ModalSection>
    </ModalBody>

    <ModalSection layout="horizontal">
      <Button onClick={onBack} link style={{width: "6rem"}}>{intl.formatMessage(messages.Back)}</Button>
      <div className="flex"/>
    </ModalSection>
  </>;
}

function CompletePage(props) {
  const { intl, toasts, preSignIn, setTitle = () => {},
          onNext = () => {} } = props;

  useEffect(() => {
    if (preSignIn) {
      setTitle(intl.formatMessage(messages.Step5Title));
      return () => setTitle(null);
    }
  }, [preSignIn]);

  return <>
    <ModalBody>
      {toasts}
      <ModalSection className={classes.TwoFAModalBody}>
        <p>{intl.formatMessage(messages.SuccessMessage1)}</p>
        <p>{intl.formatMessage(messages.SuccessMessage2)}</p>
      </ModalSection>
    </ModalBody>

    <ModalSection layout="horizontal">
      <div className="flex"/>
      <Button onClick={onNext} style={{width: "12rem"}}>{intl.formatMessage(messages.Continue)}</Button>
    </ModalSection>
  </>;
}

export const TwoFASetup = connect(
  state => ({
    isBatbooth: state.locale.isBatbooth,
    isPulse: state.locale.isPulse,
    twoFAMethods: state.auth.twoFAMethods,
  }),
  {
    update2FAMethods,
    enable2FAMethods,
    authenticateUser,
  }
)(injectIntl(function TwoFASetup(props) {
  const { intl, twoFAMethods, preSignIn } = props;
  const [busy, setBusy] = useReducer((s, a) => s + a, 0);
  const [page, setPage] = useState('list');
  const [toast, setToast] = useState({variant: 'error', message: ''});
  const { current: abortControllers } = useRef(new Set());

  const [newTotpMethod, setNewTotpMethod] = useState(null);
  const [newRecoveryCodesMethod, setNewRecoveryCodesMethod] = useState(null);
  const [existingRecoveryCodesMethod, setExistingRecoveryCodesMethod] = useState(null);

  const recoveryCodesEnabled = twoFAMethods?.includes?.('recovery-codes') ?? false;
  const totpEnabled = twoFAMethods?.includes?.('totp') ?? false;

  function setError(msg) {
    setToast({variant: 'error', message: msg});
  }
  function setSuccess(msg) {
    setToast({variant: 'success', message: msg});
  }

  const error = toast?.variant === 'error' ? toast.message : '';
  const success = toast?.variant === 'success' ? toast.message : '';

  useEffect(() => () => {
    abortControllers.forEach(ac => ac.abort());
  }, []);

  useEffect(() => {
    if (preSignIn)
      return;
    (async () => {
      const ac = new AbortController();
      abortControllers.add(ac);
      setBusy(1);
      try {
        // Fetch the list of enabled 2FA methods from the server
        const methods = await props.update2FAMethods(ac.signal);

        // If there is a totp method, but no recovery-codes method, the page
        // logic will throw the user right into the recovery-codes page, hence
        // we need to create a new set of codes here.
        if (methods.includes('totp') && !methods.includes('recovery-codes'))
          setNewRecoveryCodesMethod((await ajax.post('/2fa/new-recovery-codes', ac.signal)).data);
      } catch (e) {
        if (e.name !== 'AbortError') {
          setError(intl.formatMessage(messages.MethodsFetchError));
          console.error(e);
        }
      } finally {
        abortControllers.delete(ac);
        setBusy(-1);
      }
    })()
  }, [preSignIn]);

  async function onIntroPageNext() {
    if (!newTotpMethod) {
      const ac = new AbortController();
      abortControllers.add(ac);
      setBusy(1);
      try {
        setNewTotpMethod((await ajax.post('/2fa/new-totp-method', ac.signal)).data);
      } catch (e) {
        if (e.name !== 'AbortError') {
          setError(intl.formatMessage(messages.TotpCreateError));
          console.error(e);
          return;
        }
      } finally {
        abortControllers.delete(ac);
        setBusy(-1);
      }
    }

    setPage('authenticator-qr');
    setError('');
  }

  function onIntroPageBack() {
    setPage('list');
    setError('');
  }

  function onAuthenticatorClick() {
    setPage('intro');
    setError('');
  }

  async function onRecoveryCodesClick() {
    if ((recoveryCodesEnabled && !existingRecoveryCodesMethod) ||
        (!recoveryCodesEnabled && !newRecoveryCodesMethod)) {
      const ac = new AbortController();
      abortControllers.add(ac);
      setBusy(1);
      try {
        if (recoveryCodesEnabled && !existingRecoveryCodesMethod) {
          setExistingRecoveryCodesMethod((await ajax.get('/2fa/recovery-codes', ac.signal)).data);
        }
        if (!recoveryCodesEnabled && !newRecoveryCodesMethod) {
          setNewRecoveryCodesMethod((await ajax.post('/2fa/new-recovery-codes', ac.signal)).data);
        }
      } catch (e) {
        if (e.name !== 'AbortError') {
          setError(intl.formatMessage(messages.RecoveryCodesFetchError));
          console.error(e);
        }
      } finally {
        abortControllers.delete(ac);
        setBusy(-1);
      }
    }

    setPage('codes');
    setError('');
  }

  async function onCodesPageNext() {
    const ac = new AbortController();
    abortControllers.add(ac);
    setBusy(1);
    try {
      if (newTotpMethod) {
        await props.enable2FAMethods([{
          id: newTotpMethod.id,
          type: 'totp',
        }, {
          id: newRecoveryCodesMethod.id,
          type: 'recovery-codes',
        }], ac.signal);
        if (preSignIn) {
          setPage('complete');
        } else {
          setPage('list');
        }
        setSuccess(intl.formatMessage(messages.TotpEnrollSuccess));
        setNewTotpMethod(null);
      } else {
        await props.enable2FAMethods([{
          id: newRecoveryCodesMethod.id,
          type: 'recovery-codes',
        }], ac.signal);
        setPage('list');
        setSuccess(intl.formatMessage(messages.RecoveryCodesActivateSuccess));
      }
      setExistingRecoveryCodesMethod(newRecoveryCodesMethod);
      setNewRecoveryCodesMethod(null);
    } catch (e) {
      if (e.name !== 'AbortError') {
        if (newTotpMethod) {
          setError(intl.formatMessage(messages.TotpEnrollError));
        } else {
          setError(intl.formatMessage(messages.RecoveryCodesActivateError));
        }
        console.error(e);
      }
    } finally {
      abortControllers.delete(ac);
      setBusy(-1);
    }
  }

  async function onCodesPageCancel() {
    const ac = new AbortController();
    abortControllers.add(ac);
    setBusy(1);
    try {
      await ajax.post('/2fa/cancel', {
        methods: [newRecoveryCodesMethod.id],
      }, ac.signal);
      setError('');
      setNewRecoveryCodesMethod(null);
    } catch (e) {
      if (e.name !== 'AbortError') {
        setError(intl.formatMessage(messages.RecoveryCodesCancelError));
        console.error(e);
      }
    } finally {
      abortControllers.delete(ac);
      setBusy(-1);
    }
  }

  function onCodesPageBack() {
    setPage('list');
    setError('');
  }

  async function onNewCodes() {
    const ac = new AbortController();
    abortControllers.add(ac);
    setBusy(1);
    try {
      setNewRecoveryCodesMethod((await ajax.post('/2fa/new-recovery-codes', ac.signal)).data);
      setError('');
    } catch (e) {
      if (e.name !== 'AbortError') {
        setError(intl.formatMessage(messages.RecoveryCodesCreateError));
        console.error(e);
      }
    } finally {
      abortControllers.delete(ac);
      setBusy(-1);
    }
  }

  function onAuthenticatorQrPageNext() {
    setPage('authenticator-confirm');
  }
  function onAuthenticatorQrPageBack() {
    setPage('intro');
  }
  function onAuthenticatorConfirmPageBack() {
    setPage('authenticator-qr');
  }

  async function onAuthenticatorConfirmPageNext(code) {
    const ac = new AbortController();
    abortControllers.add(ac);
    setBusy(1);
    try {
      let valid;
      if (!recoveryCodesEnabled) {
        valid = (await ajax.post('/2fa/confirm-totp-method', {
          id: newTotpMethod.id,
          code,
        }, ac.signal)).data.valid;

        if (valid) {
          setNewRecoveryCodesMethod((await ajax.post('/2fa/new-recovery-codes', ac.signal)).data);
          setPage('codes');
          setSuccess(intl.formatMessage(messages.TotpCodeConfirmed));
        }
      } else {
        valid = await props.enable2FAMethods([{
          id: newTotpMethod.id,
          type: 'totp',
          code,
        }], ac.signal);

        if (valid) {
          setPage('list');
          setSuccess(intl.formatMessage(messages.TotpEnrollSuccess));
          setNewTotpMethod(null);
        }
      }
      if (!valid)
        setError(intl.formatMessage(messages.TotpCodeConfirmFail));
      return valid;
    } catch (e) {
      if (e.name !== 'AbortError') {
        setError(intl.formatMessage(messages.TotpCodeConfirmError));
        console.error(e);
      }
    } finally {
      abortControllers.delete(ac);
      setBusy(-1);
    }
  }

  async function onIntroPageCancel() {
    const ac = new AbortController();
    abortControllers.add(ac);
    setBusy(1);
    try {
      await ajax.post('/2fa/cancel', {
        methods: [newTotpMethod.id],
      }, ac.signal);
      setPage('list');
      setError('');
      setNewTotpMethod(null);
    } catch (e) {
      if (e.name !== 'AbortError') {
        setError(intl.formatMessage(messages.TotpCodeCancelError));
        console.error(e);
      }
    } finally {
      abortControllers.delete(ac);
      setBusy(-1);
    }
  }

  async function onCompletePageNext() {
    await props.authenticateUser();
  }

  let actualPage = page;
  if (page === 'intro' || page === 'list') {
    if (!totpEnabled) {
      actualPage = 'intro';
    } else if (!recoveryCodesEnabled) {
      actualPage = 'codes';
    }
  }

  const toasts = <>
    <ToastInline className={classes.Toast} variant="error" show={error} message={error}/>
    <ToastInline className={classes.Toast} variant="success" show={success} message={success}/>
  </>;

  let pageComponent = null;
  switch (actualPage) {
    case 'intro':
      pageComponent = <IntroPage intl={intl}
                                 toasts={toasts}
                                 totpEnabled={!!totpEnabled}
                                 newTotpMethod={newTotpMethod}
                                 preSignIn={preSignIn}
                                 onNext={onIntroPageNext}
                                 onBack={onIntroPageBack}
                                 onCancel={onIntroPageCancel}
                                 {...props}/>;
      break;
    case 'list':
      pageComponent = <ListPage intl={intl}
                                toasts={toasts}
                                onAuthenticatorClick={onAuthenticatorClick}
                                recoveryCodesEnabled={!!recoveryCodesEnabled}
                                onRecoveryCodesClick={onRecoveryCodesClick}
                                {...props}/>;
      break;
    case 'codes':
      pageComponent = <CodesPage intl={intl}
                                 toasts={toasts}
                                 onNext={onCodesPageNext}
                                 onBack={onCodesPageBack}
                                 onNewCodes={onNewCodes}
                                 onCancel={onCodesPageCancel}
                                 preSignIn={preSignIn}
                                 totpEnabled={!!totpEnabled}
                                 recoveryCodesEnabled={!!recoveryCodesEnabled}
                                 newRecoveryCodesMethod={newRecoveryCodesMethod}
                                 existingRecoveryCodesMethod={existingRecoveryCodesMethod}
                                 {...props}/>;
      break;
    case 'authenticator-qr':
      pageComponent = <AuthenticatorQrPage intl={intl}
                                           toasts={toasts}
                                           preSignIn={preSignIn}
                                           method={newTotpMethod}
                                           recoveryCodesEnabled={!!recoveryCodesEnabled}
                                           totpEnabled={!!totpEnabled}
                                           onNext={onAuthenticatorQrPageNext}
                                           onBack={onAuthenticatorQrPageBack}
                                           {...props}/>
      break;
    case 'authenticator-confirm':
      pageComponent = <AuthenticatorConfirmPage intl={intl}
                                                toasts={toasts}
                                                preSignIn={preSignIn}
                                                recoveryCodesEnabled={!!recoveryCodesEnabled}
                                                totpEnabled={!!totpEnabled}
                                                onNext={onAuthenticatorConfirmPageNext}
                                                onBack={onAuthenticatorConfirmPageBack}
                                                {...props}/>
      break;
    case 'complete':
      pageComponent = <CompletePage intl={intl}
                                    toasts={toasts}
                                    preSignIn={preSignIn}
                                    onNext={onCompletePageNext}
                                    {...props}/>
      break;
  }

  return <>
    <Spinner active={!!busy}/>
    {pageComponent}
  </>;
}));

export const TwoFASetupModal = connect(
  state => ({
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    twoFAMethods: state.auth.twoFAMethods,
  }),
  {}
)(injectIntl(function TwoFASetupModal(props) {
  const { show = true, onCancel, authenticated, token, twoFAMethods,
          onForcePrompt, intl, ...otherProps } = props;
  const [title, setTitle] = useState(null);
  const [canClose, setCanClose] = useState(true);

  function onModalClose(e) {
    onCancel && onCancel(e);
  }

  useEffect(() => {
    if (authenticated && token) {
      // If the user just ran out of recovery codes, prompt them right away to
      // create more
      if (twoFAMethods?.includes?.('totp') && !twoFAMethods?.includes?.('recovery-codes')) {
        onForcePrompt && onForcePrompt();
      }
    }
  }, [authenticated]);

  return <Modal title={title ?? intl.formatMessage(messages.TwoStepAuthentication)}
                show={show}
                canClose={canClose}
                onClose={onModalClose}
                footer={<div/>}>
    <TwoFASetup setCanClose={setCanClose} setTitle={setTitle} {...otherProps}/>
  </Modal>;
}));
