import { defineMessages } from "react-intl";
import Button from "../components/widgets/Button/Button";
import React from "react";

export default defineMessages({
  "TwoStepAuthentication": {
    id: "TwoFASetup.TwoStepAuthentication",
    defaultMessage: "Two-Step Authentication",
  },
  "ResetAuthenticatorApp": {
    id: "TwoFASetup.ResetAuthenticatorApp",
    defaultMessage: "Reset Authenticator App",
  },
  "EnableAuthenticatorApp": {
    id: "TwoFASetup.EnableAuthenticatorApp",
    defaultMessage: "Enable Authenticator App",
  },
  "Step1Title": {
    id: "TwoFASetup.Step1Title",
    defaultMessage: "1. Install an authenticator app",
  },
  "TOTPCreateInstr1": {
    id: "TwoFASetup.TOTPCreateInstr1",
    defaultMessage: "To provide an extra layer of security to your account, when you sign in, you need to provide a verification code, which is generated by an app on your phone.",
  },
  "TOTPCreateInstr2": {
    id: "TwoFASetup.TOTPCreateInstr2",
    defaultMessage: "To begin, go to the <appStore>App Store</appStore> (for iPhone) or <playStore>Play Store</playStore> (for Android) on your phone and install the <strong>Google Authenticator</strong> app.",
  },
  "TOTPCreateInstr3": {
    id: "TwoFASetup.TOTPCreateInstr3",
    defaultMessage: "We also recommend Microsoft Authenticator or FreeOTP.",
  },
  "TOTPResetInstr1": {
    id: "TwoFASetup.TOTPResetInstr1",
    defaultMessage: "Are you sure you want to enrol a new authenticator app? Completing this process will invalidate the codes generated by your old app.",
  },
  "TOTPResetInstr2": {
    id: "TwoFASetup.TOTPResetInstr2",
    defaultMessage: "If you are using a new device, go to the <appStore>App Store</appStore> (for iPhone) or <playStore>Play Store</playStore> (for Android) and install the <strong>Google Authenticator</strong> app.",
  },
  "Back": {
    id: "TwoFASetup.Back",
    defaultMessage: "Back",
  },
  "Cancel": {
    id: "TwoFASetup.Cancel",
    defaultMessage: "Cancel",
  },
  "Next": {
    id: "TwoFASetup.Next",
    defaultMessage: "Next",
  },
  "Step2Title": {
    id: "TwoFASetup.Step2Title",
    defaultMessage: "2. Register with authenticator app",
  },
  "TOTPRegisterMethod1": {
    id: "TwoFASetup.TOTPRegisterMethod1",
    defaultMessage: "Method 1: Scan QR code",
  },
  "TOTPRegisterMethod2": {
    id: "TwoFASetup.TOTPRegisterMethod2",
    defaultMessage: "Method 2: Manually enter key",
  },
  "TOTPScanInstr": {
    id: "TwoFASetup.TOTPScanInstr",
    defaultMessage: "Using the authenticator app of your choice, scan the QR Code below.",
  },
  "TOTPManualInstr": {
    id: "TwoFASetup.TOTPManualInstr",
    defaultMessage: "Using the authenticator app of your choice, enter the details below.",
  },
  "ICantScanThisQrCode": {
    id: "TwoFASetup.ICantScanThisQrCode",
    defaultMessage: "I can't scan this QR Code",
  },
  "SetupKey": {
    id: "TwoFASetup.SetupKey",
    defaultMessage: "Setup Key",
  },
  "ScanQrCodeInstead": {
    id: "TwoFASetup.ScanQrCodeInstead",
    defaultMessage: "Scan QR code instead",
  },
  "Step3Title": {
    id: "TwoFASetup.Step3Title",
    defaultMessage: "3. Confirm app registration",
  },
  "TOTPConfirmInstr": {
    id: "TwoFASetup.TOTPConfirmInstr",
    defaultMessage: "Enter the code generated by your authentication app.",
  },
  "Step4Title": {
    id: "TwoFASetup.Step4Title",
    defaultMessage: "4. Save recovery codes",
  },
  "CodesTxtHeading": {
    id: "TwoFASetup.CodesTxtHeading",
    defaultMessage: "{appName} Two-Step Authentication Recovery Codes",
  },
  "CodesTxtInstruction": {
    id: "TwoFASetup.CodesTxtInstruction",
    defaultMessage: "If you lose access to your authentication app, you can sign in to the {appName} admin portal with one of these recovery codes. Each code may only be used once.",
  },
  "Step5Title": {
    id: "TwoFASetup.Step5Title",
    defaultMessage: "5. Complete",
  },
  "GenerateNewCodes": {
    id: "TwoFASetup.GenerateNewCodes",
    defaultMessage: "Generate New Codes",
  },
  "RecoveryCodes": {
    id: "TwoFASetup.RecoveryCodes",
    defaultMessage: "Recovery Codes",
  },
  "RecoveryCodesDesc": {
    id: "TwoFASetup.RecoveryCodesDesc",
    defaultMessage: "If you don't have access to your phone, you can sign into this website with a single-use recovery code. This will allow you to access the website when you are unable to use your phone.",
  },
  "RecoveryCodesNoneLeftDesc1": {
    id: "TwoFASetup.RecoveryCodesNoneLeftDesc1",
    defaultMessage: "Recovery codes are single-use codes you can use to sign in to the website when you don't have access to your phone.",
  },
  "RecoveryCodesNoneLeftDesc2": {
    id: "TwoFASetup.RecoveryCodesNoneLeftDesc2",
    defaultMessage: "You have no recovery codes left.",
  },
  "ViewOrEditRecoveryCodes": {
    id: "TwoFASetup.ViewOrEditRecoveryCodes",
    defaultMessage: "View or Edit Recovery Codes",
  },
  "AuthenticatorApp": {
    id: "TwoFASetup.AuthenticatorApp",
    defaultMessage: "Authenticator App",
  },
  "AuthenticatorAppDesc": {
    id: "TwoFASetup.AuthenticatorAppDesc",
    defaultMessage: "You currently sign in to this website using a verification code which is generated by an app on your phone. If you lose or replace your phone, you can enrol a new phone to generate the verification code. Doing this will invalidate the codes generated by your old phone.",
  },
  "RecoveryCodesInstr1": {
    id: "TwoFASetup.RecoveryCodesInstr1",
    defaultMessage: "If you don't have access to your phone, you can sign in with a single-use recovery code. This will allow you to configure the authenticator app on a new phone if your old phone is lost or broken.",
  },
  "RecoveryCodesInstr2": {
    id: "TwoFASetup.RecoveryCodesInstr2",
    defaultMessage: "Download, print or copy these codes and store them in a safe location.",
  },
  "RecoveryCodesRecreateInstr": {
    id: "TwoFASetup.RecoveryCodesRecreateInstr",
    defaultMessage: "Your new single-use recovery codes are below. Once the new codes are activated, the old codes will become invalid.",
  },
  "RecoveryCodesExpendedInstr": {
    id: "TwoFASetup.RecoveryCodesExpendedInstr",
    defaultMessage: "Your old single-use recovery codes have been expended. In order to keep access to your account in the event that you lose access to your authenticator app, activate the new codes below.",
  },
  "Download": {
    id: "TwoFASetup.Download",
    defaultMessage: "Download",
  },
  "Print": {
    id: "TwoFASetup.Print",
    defaultMessage: "Print",
  },
  "Copy": {
    id: "TwoFASetup.Copy",
    defaultMessage: "Copy",
  },
  "ActivateNewCodes": {
    id: "TwoFASetup.ActivateNewCodes",
    defaultMessage: "Activate New Codes",
  },
  "SuccessMessage1": {
    id: "TwoFASetup.SuccessMessage1",
    defaultMessage: "You have successfully set up two-step authentication for your account.",
  },
  "SuccessMessage2": {
    id: "TwoFASetup.SuccessMessage2",
    defaultMessage: "Ensure that you have your authentication app or recovery codes available the next time you sign in.",
  },
  "Continue": {
    id: "TwoFASetup.Continue",
    defaultMessage: "Continue",
  },
  "MethodsFetchError": {
    id: "TwoFASetup.MethodsFetchError",
    defaultMessage: "Could not get two-step authentication methods",
  },
  "TotpCreateError": {
    id: "TwoFASetup.TotpCreateError",
    defaultMessage: "Could not reset two-step authentication method",
  },
  "RecoveryCodesFetchError": {
    id: "TwoFASetup.RecoveryCodesFetchCreateError",
    defaultMessage: "Could not get recovery codes",
  },
  "RecoveryCodesCreateError": {
    id: "TwoFASetup.RecoveryCodesCreateError",
    defaultMessage: "Could not reset recovery codes",
  },
  "TotpEnrollSuccess": {
    id: "TwoFASetup.TotpEnrollSuccess",
    defaultMessage: "Authentication app enrolled successfully",
  },
  "RecoveryCodesActivateSuccess": {
    id: "TwoFASetup.RecoveryCodesActivateSuccess",
    defaultMessage: "Recovery codes created successfully",
  },
  "TotpEnrollError": {
    id: "TwoFASetup.TotpEnrollError",
    defaultMessage: "Could not enable two-step authentication",
  },
  "RecoveryCodesActivateError": {
    id: "TwoFASetup.RecoveryCodesActivateError",
    defaultMessage: "Could not enable recovery codes",
  },
  "RecoveryCodesCancelError": {
    id: "TwoFASetup.RecoveryCodesCancelError",
    defaultMessage: "Could not cancel recovery code creation",
  },
  "TotpCodeConfirmed": {
    id: "TwoFASetup.TotpCodeConfirmed",
    defaultMessage: "Authentication app code confirmed",
  },
  "TotpCodeConfirmFail": {
    id: "TwoFASetup.TotpCodeConfirmFail",
    defaultMessage: "Incorrect verification code. Please try again.",
  },
  "TotpCodeConfirmError": {
    id: "TwoFASetup.TotpCodeConfirmError",
    defaultMessage: "Could not verify authentication code",
  },
  "TotpCodeCancelError": {
    id: "TwoFASetup.TotpCodeCancelError",
    defaultMessage: "Could not cancel authentication app enrolment",
  },
});
