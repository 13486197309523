import { defineMessages } from "react-intl";

export default defineMessages({
  "ClientName": {
    id: "ClientManagement.ClientName",
    defaultMessage: "Client Name"
  },
  "ClientID": {
    id: "ClientManagement.ClientID",
    defaultMessage: "Client ID"
  },
  "CompanyNo": {
    id: "ClientManagement.CompanyNo",
    defaultMessage: "Company No."
  },
  "Country": {
    id: "ClientManagement.Country",
    defaultMessage: "Country"
  },
  "Street": {
    id: "ClientManagement.Street",
    defaultMessage: "Street"
  },
  "City": {
    id: "ClientManagement.City",
    defaultMessage: "City"
  },
  "State": {
    id: "ClientManagement.State",
    defaultMessage: "State"
  },
  "Phone": {
    id: "ClientManagement.Phone",
    defaultMessage: "Phone"
  },
  "PostCode": {
    id: "ClientManagement.PostCode",
    defaultMessage: "Postcode"
  },
  "LastModified": {
    id: "ClientManagement.LastModified",
    defaultMessage: "Last Modified"
  },
  "Enabled": {
    id: "ClientManagement.Enabled",
    defaultMessage: "Enabled"
  },
  "NewClient": {
    id: "ClientManagement.NewClient",
    defaultMessage: "New Client"
  },
  "AddClient": {
    id: "ClientManagement.AddClient",
    defaultMessage: "Add Client"
  },
  "EditClient": {
    id: "ClientManagement.EditClient",
    defaultMessage: "Edit Client"
  },
  "UpdateClient": {
    id: "ClientManagement.UpdateClient",
    defaultMessage: "Update Client"
  }
});
