import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Route } from 'react-router-dom'
import './index.css';
import './assets/css/grid.css';
import App from './container/App';
import Spinner from './components/widgets/Spinner/Spinner';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store, {persistor} from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Spinner active/>} persistor={persistor}>
        <BrowserRouter>
          <Route path="/" component={App} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
