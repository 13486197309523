import { defineMessages } from "react-intl";

export default defineMessages({
  "Home": {
    id: "SideBar.Home",
    defaultMessage: "Home"
  },
  "ClientManagement": {
    id: "SideBar.ClientManagement",
    defaultMessage: "Client Management"
  },
  "UserManagement": {
    id: "SideBar.UserManagement",
    defaultMessage: "User Management"
  },
  "SiteManagement": {
    id: "SideBar.SiteManagement",
    defaultMessage: "Site Management"
  },
  "QuestionManagement": {
    id: "SideBar.QuestionManagement",
    defaultMessage: "Question Management"
  },
  "NotificationManagement": {
    id: "SideBar.NotificationManagement",
    defaultMessage: "Notification Management"
  },
  "MachineManagement": {
    id: "SideBar.MachineManagement",
    defaultMessage: "Sign-In Methods"

    // defaultMessage: "Batbooth Management"
  },
  "AccessLogs": {
    id: "SideBar.AccessLogs",
    defaultMessage: "Access Logs"
  },
  "Configuration": {
    id: "SideBar.Configuration",
    defaultMessage: "Configuration"
  },
  "GetHelp": {
    id: "SideBar.GetHelp",
    defaultMessage: "Get Help",
  },
})
