import React, {useEffect, useMemo, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {FormattedDate, FormattedTime, useIntl} from 'react-intl';
import classes from './CheckInBoard.module.css';
import messages from './CheckInBoard.messages';
import {useAsyncEffect, useLocation} from "../utils/react";
import ajax from "../utils/ajax";
import batStationLogo from "../assets/images/bat-station.svg";
import coolGardLogo from "../assets/images/coolgard-checkin.svg";
import pulseLogo from "../assets/images/pulsepresence-shoes-blue.svg";
import Icon from "../components/widgets/Icon/Icon";
import {timeout} from "../utils/helpers";
import {Redirect} from "react-router-dom";
import Select from "../components/widgets/Select/Select";
import SpinnerIcon from "../components/widgets/Spinner/SpinnerIcon";
import {boardSetSites, boardSetSiteId, logoutUser} from "../redux/actions";

function Header() {
  const intl = useIntl();
  const isBatbooth = useSelector(s => s.locale.isBatbooth);
  const isPulse = useSelector(s => s.locale.isPulse);
  const dispatch = useDispatch();

  function onLogoutClick() {
    dispatch(logoutUser());
  }

  return <header className={`${classes.Header}`}>
    <img className={`${classes.HeaderLogo} ${isPulse ? classes.HeaderLogoPulse : ''}`}
         src={isBatbooth ? batStationLogo : (isPulse ? pulseLogo : coolGardLogo)}
         alt={isBatbooth ? 'Bat Station' : (isPulse ? 'Pulse Presence' : 'CoolGard')}/>
    <button className={classes.HeaderLogout}
            onClick={onLogoutClick}>
      <Icon className={classes.HeaderLogoutIcon} type="regular" icon="sign-out"/>
      {intl.formatMessage(messages.LogOut)}
    </button>
  </header>;
}

const User = React.memo(function User(props) {
  const intl = useIntl();
  const {user, checked, onChecked} = props;
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (!image)
      return;
    const url = URL.createObjectURL(image);
    setImageUrl(url);
    return () => URL.revokeObjectURL(url);
  }, [image]);

  useAsyncEffect(async signal => {
    try {
      const image = await ajax.get(`/board/photo/${user.id}`, signal);
      setImage(image);
    } catch (e) {
      console.error(e);
    }
  }, [user.id]);

  function onCheckboxChange(e) {
    onChecked(user.id, e.target.checked);
  }

  function toggleChecked () {
    if (user.checkedIn)
      onChecked(user.id, !checked);
  }

  const userExtra = [user.role, user.empNo].filter(e => e).join(' \u2013 ');
  return <div className={`${classes.User} ${checked ? classes.UserChecked : ''}`}>
    {user.checkedIn && <label className={classes.UserCheckbox}>
      <input type="checkbox" checked={checked} onChange={onCheckboxChange}/>
    </label>}
    {image && <img onClick={() => toggleChecked()} alt={intl.formatMessage(messages.UserPhoto)}
         className={classes.UserImage}
         src={imageUrl}/>}
    {!image && <Icon onClick={() => toggleChecked()} className={classes.Icon} icon="user-slash" />}
    <div onClick={() => toggleChecked()} className={classes.UserDetails}>
      <div className={classes.UserName}>{user.firstName} {user.lastName}</div>
      {userExtra && <div>{userExtra}</div>}
      <div>{intl.formatMessage(user.checkedIn ? messages.CheckedInAt : (+new Date(user.logDate) > 0 ? messages.LastSeen : messages.NeverSeen), {
        logDate: new Date(user.logDate),
      })}</div>
    </div>
    <div className={classes.Phone}>
      <a className={`${classes.UserPhone}`} href={user.tel}><Icon type="solid" icon="phone" size="lg"/></a>
      {user.phoneExt && <div>{intl.formatMessage(messages.PhoneExt, {
        phoneExt: user.phoneExt
      })}</div>}
    </div>
  </div>;
});

export default function CheckInBoard() {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasToken = useSelector(s => !!s.auth.token);
  const sites = useSelector(s => s.board.sites);
  const siteId = useSelector(s => s.board.siteId);
  const [siteUsers, setSiteUsers] = useState({});
  const [tab, setTab] = useState('checked_in');
  const [siteLastUpdated, setSiteLastUpdated] = useState({});
  const [siteCheckedUsers, setSiteCheckedUsers] = useState({});
  const [refresh, setRefresh] = useState(0);
  const users = siteUsers?.[siteId];
  const lastUpdated = siteLastUpdated?.[siteId];
  const checkedUsers = siteCheckedUsers?.[siteId] ?? {};
  const site = sites?.find?.(s => s.id === siteId);
  const showOfflineUsers = site?.offline_users || false;

  const checkedCount = useMemo(() => {
    return users?.filter(u => checkedUsers[u.id]).length ?? 0;
  }, [users, checkedUsers]);

  useAsyncEffect(async signal => {
    if (!hasToken)
      return;

    try {
      const {data: sites} = await ajax.get("/board/sites", signal);
      dispatch(boardSetSites(sites));
    } catch (e) {
      console.error(e);
    }
  }, [hasToken]);

  useEffect(() =>{
    setSiteUsers([]);
  },[tab])

  useAsyncEffect(async signal => {
    if (!sites?.length || !hasToken)
      return;

    // Ensure the selected site ID is in the list of sites
    if (!sites.some(s => s.id === siteId)) {
      dispatch(boardSetSiteId(sites[0].id));
      return;
    }

    try {
      const { data: users } = await ajax.get(`/board/${tab === "checked_out" ? 'out/' : ''}${siteId}`, signal);
      setSiteUsers(u => ({ ...u, [siteId]: users }));
      setSiteLastUpdated(l => ({ ...l, [siteId]: new Date() }));
      setSiteCheckedUsers(c => ({
        ...c,
        // Uncheck users who have left, so they aren't checked if they come
        // back in
        [siteId]: Object.fromEntries(
          Object.entries(c?.[siteId] ?? {})
            .filter(([userId]) => users.some(u => u.id === userId))),
      }));
    } catch (e) {
      console.error(e);
    }

    await timeout(10000, signal);
    setRefresh(r => r + 1);
  }, [hasToken, sites, siteId, refresh, tab]);

  useEffect(() => {
    document.body.classList.add('board');
    return () => document.body.classList.remove('board');
  }, []);

  function setSiteId(id) {
    dispatch(boardSetSiteId(id));
  }

  function onResetClick() {
    setSiteCheckedUsers(c => ({ ...c, [siteId]: {} }));
  }

  const onUserChecked = useMemo(() => function onUserChecked(id, checked) {
    setSiteCheckedUsers(c => ({
      ...c,
      [siteId]: {
        ...(c?.[siteId] ?? {}),
        [id]: checked,
      },
    }));
  }, [siteId]);

  if (!hasToken) {
    return <Redirect to={{ pathname: '/login', state: { from: location, no2FA: true } }} />;
  }

  // selection = 'Checked x of y' ... reset
  // no selection
  //   show offline users = Checked in / Checked out tabs
  //   no show offline users = 'x people on site'
  return <div className={classes.CheckInBoard}>
    <Header/>
    <Select className={classes.SiteSelector}
            label={intl.formatMessage(messages.ChooseASite)}
            selected={siteId}
            onChange={setSiteId}>
      {sites?.map(site => <option key={site.id} value={site.id}>{site.name}</option>)}
    </Select>
    {(!checkedCount && showOfflineUsers) && <div className={classes.TabBar}>
      <div className={`${classes.Tab} ${tab === "checked_in" && classes.Selected}`} onClick={() => setTab("checked_in")}>{`${intl.formatMessage(messages.CheckedIn)} ${users && users.some(x => x.checkedIn) ? ` (${users.length})` : ''}`}</div>
      <div className={`${classes.Tab} ${tab === "checked_out" && classes.Selected}`}onClick={() => setTab("checked_out")} >Checked Out</div>
    </div>}
    {site && users && <>
      {!!checkedCount &&
        <div className={classes.Status}>{intl.formatMessage(messages.CheckedPeople, {
          checked: checkedCount,
          people: users.length,
          site: site.name,
        })}<button className={classes.CheckReset} onClick={onResetClick}>{intl.formatMessage(messages.Reset)}</button></div> }
      {!checkedCount && !showOfflineUsers && <div className={classes.Status}>{intl.formatMessage(messages.PeopleOnSite, {
        people: users.length,
        site: site.name,
      })}</div>}
      <div className={classes.UserGrid}>
        {users.map(user => <User key={user.id}
                                 user={user}
                                 checked={checkedUsers[user.id] ?? false}
                                 onChecked={onUserChecked}/>)}
      </div>
      <div className={classes.LastUpdated}>
        {intl.formatMessage(messages.LastUpdatedAt, { lastUpdated })}
      </div>
    </>}
    {(!sites || (siteId && !users)) && <SpinnerIcon className={classes.Spinner}/>}
  </div>;
}
