import { defineMessages } from "react-intl";

export default defineMessages({
  "UsersSelected": {
    id: "UserManagement.UsersSelected",
    defaultMessage: "Users Selected"
  },
  "ChangeAccountType": {
    id: "UserManagement.ChangeAccountType",
    defaultMessage: "Change Account Type"
  },
  "ResetAccessPins": {
    id: "UserManagement.ResetAccessPins",
    defaultMessage: "Reset Access Pins"
  },
  "ArchiveUsers": {
    id: "UserManagement.ArchiveUsers",
    defaultMessage: "Archive Users"
  },
});
