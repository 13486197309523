import Icon from "../Icon/Icon";
import classes from "./Toast.module.css";
import React from "react";

function ToastInline(props) {
  let style = props.style || {};
  let icon;
  style.backgroundColor = props.backgroundColor;

  if (!props.show) {
    return null;
  }

  if (props.variant === "success") {
    style.color = "white";
    style.backgroundColor = "#1DC471";
    icon = "check";
  } else if (props.variant === "info") {
    style.color = "white";
    icon = "info";
    style.backgroundColor = "#2F96C3";
  } else if (props.variant === "error") {
    style.color = "white";
    icon = "exit";
    style.backgroundColor = "#EF5350";
  }

  if (props.icon) {
    icon = props.icon;
  }

  if (props.color) {
    style.color = props.color;
  }

  if (props.backgroundColor) {
    style.backgroundColor = props.backgroundColor;
  }

  return (
    <div className={`${classes.ToastInline} ${props.className || ''}`} style={style}>
      <div className={classes.InlineIconContainer}>
        <Icon style={{marginRight: ".75rem", color: style.color}} icon={icon} />
      </div>
      <span>{props.message}</span>
    </div>
  )
}

export default ToastInline;
