import { CHANGE_LANG, RESET_STATE } from "../types";
import en from "../../locales/en.json";
import en_au from "../../locales/en-au.json";

import es from "../../locales/es.json";

const localeData = {en, es, "en-au": en_au};
const initLanguage =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

const languageWithoutRegionCode = initLanguage.toLowerCase().split(/[_-]+/)[0];
const messages =
  localeData[initLanguage] ||
  localeData[languageWithoutRegionCode] ||
  localeData["en-au"];

const initialState = {
  browserLanguage: initLanguage,
  language: initLanguage,
  languageWithRegionCode: initLanguage.toLowerCase().split(/[_-]+/)[0],
  messages: messages,
  isBatbooth: !/coolgard/.test(window.location.hostname) && !/local/.test(window.location.hostname) && !/pulsemining/.test(window.location.hostname) && !/pulsepresence/.test(window.location.hostname),
  isPulse: /pulsepresence/.test(window.location.hostname) || /coolgard/.test(window.location.hostname) || (/local/.test(window.location.hostname) || /pulsemining/.test(window.location.hostname)),
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANG: {
      return changeLanguage(state, action);
    }
    case RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

function changeLanguage(state, action) {
  let newState = Object.assign({}, state);
  newState.language = action.payload || "";
  newState.languageWithoutRegionCode = newState.language.toLowerCase().split(/[_-]+/)[0]
  if (!newState.language) {
    newState.language = initialState.browserLanguage || "en-au";
  }
  newState.messages = localeData[newState.language] || localeData[newState.languageWithoutRegionCode] || localeData["en-au"];
  newState.language = newState.language.toLowerCase();
  return newState;
}
