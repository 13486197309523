import {
  RESET_STATE,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_INIT,
  SOCKET_MESSAGE
} from "../types";

const initialState = {
  connected: false,
  socket: null,
  devices: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SOCKET_CONNECT: return connect(state, action);
    case SOCKET_DISCONNECT: return disconnect(state, action);
    case SOCKET_INIT: return init(state, action);
    case SOCKET_MESSAGE: return message(state, action);
    case RESET_STATE: return initialState;
    default: return state;
  }
}

function init(state, action) {
  let newState = {...state};
  newState.socket = action.payload;
  return newState;
}

function connect(state, action) {
  let newState = {...state};
  newState.connected = true;
  if (action.payload) {
    newState.socket = action.payload;
  }
  return newState;
}

function disconnect(state, action) {
  let newState = Object.assign({}, state);
  newState.connected = false;
  newState.devices = [];
  return newState;
}

function message(state, action) {
  let newState = {...state};
  if (action.payload.event === "connected_devices_changed") {
    newState.devices = action.payload.data || [];
  }
  return newState;
}