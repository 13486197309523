import { combineReducers } from "redux";
import toastReducer from "./reducers/toast"
import authReducer from "./reducers/auth";
import notificationReducer from "./reducers/notification";
import localeReducer from "./reducers/locale";
import socketReducer from "./reducers/socket";
import homeReducer from "./reducers/home";
import checkinReducer from "./reducers/checkin";
import boardReducer from "./reducers/board";

const appReducer = combineReducers({
  toasts: toastReducer,
  auth: authReducer,
  notifications: notificationReducer,
  locale: localeReducer,
  socket: socketReducer,
  home: homeReducer,
  checkin: checkinReducer,
  board: boardReducer,
})

const rootReducer = (state, action) => {
  return appReducer(state, action);
}

export default rootReducer;
