import React, {useEffect} from 'react';
import classes from './App.module.css';
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import PrivateRoute from "../components/PrivateRoute";
import Main from './Main';
import Login from './Login';
import ToastContainer from "../components/widgets/Toast/Toast"
import {clearToasts, addNotification, connectSocket, changeLanguage, logoutUser, authenticateUser} from "../redux/actions";
import WidgetView from "./WidgetView";
import PasswordReset from "./PasswordReset";
import CheckIn from "./CheckIn";
import CheckInBoard from "./CheckInBoard";
import { IntlProvider } from "react-intl";
import ErrorBoundary from "../components/ErrorBoundary";
import Error from "./Error";
import ajax from "../utils/ajax";

function _Content(props) {
  const protectedPages = "/(home|client_management|user_management|checked_in_users|settings|machine_management|access_log|example|site_management|question_management|notification_management|qr_test)";
  const {authenticated, token} = props;

  function checkToken() {
    ajax.get("/validate_token").then(res => {
      if (res.data.validated) {
        props.authenticateUser();
        window._config = res.data.config || { region: "AU" };
      } else {
        props.logoutUser();
      }
    }).catch(e => {
      console.error(e);
    })
  }

  useEffect(() => {
    if (props.authenticated && !props.socket) {
      props.connectSocket();
    }
    props.clearToasts();
  }, [props])

  if (typeof authenticated === "undefined" && !token) {
    props.logoutUser();
    return null;
  } else if (typeof authenticated === "undefined" && token) {
    checkToken();
    return null;
  } else if (authenticated && !props.user) {
    return null;
  }

  const RedirectPage = (props) => ((props.location?.state?.no2FA ? token : authenticated)
    ? <Redirect to={props.location?.state?.from ?? { pathname: "/" }} />
    : <Login {...props} />
  );

  return (
    <div className={classes.App}>
      <Switch>
        <Route path="/login" exact {...props} render={RedirectPage} />
        <Route path="/reset_password" exact component={PasswordReset} />
        <Route path="/:methodKey([A-HJ-NP-TV-Z2-9]{4})" component={CheckIn} />
        <Route path="/board" component={CheckInBoard} />
        <PrivateRoute path="/" exact component={props => (<Redirect to="/home" {...props} />)} {...props} />
        <PrivateRoute path={protectedPages} component={Main} />
        <PrivateRoute path="/widgetview" exact component={WidgetView} {...props} />
        <Redirect to="/" />
      </Switch>
    </div>
  )
}

function App(props) {
  return (
    <IntlProvider locale={props.language} messages={props.messages}>
      <ErrorBoundary fallback={<Error />}>
        <Content />
      </ErrorBoundary>
      <ToastContainer />
    </IntlProvider>
  )
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    user: state.auth.user,
    language: state.locale.language,
    browserLanguage: state.locale.browserLanguage,
    socket: state.socket.socket
  }
}

function mapAppStateToProps(state) {
  return {
    language: state.locale.language || state.auth.user.language,
    messages: state.locale.messages
  }
}

const Content = connect(
  mapStateToProps,
  { clearToasts, addNotification, connectSocket, changeLanguage, logoutUser, authenticateUser }
)(_Content);

export default connect(
  mapAppStateToProps,
  null
)(App);
