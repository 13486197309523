import {
  HOME_SET_PERIOD,
  HOME_SET_SITE,
} from "../types";
import {persistReducer} from "redux-persist";
import storage from "localforage";

const initialState = {
  period: 'day',
  site: 'all',
};

export default persistReducer({
  key: 'home',
  storage,
}, function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME_SET_PERIOD:
      return setPeriod(state, action);
    case HOME_SET_SITE:
      return setSite(state, action);
    default:
      return state;
  }
});

function setPeriod(state, { payload: period }) {
  return {
    ...state,
    period,
  };
}

function setSite(state, { payload: site }) {
  return {
    ...state,
    site,
  };
}
