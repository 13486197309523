import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import rootReducer from "./index";
import socketMiddleware from "./middleware/socket";
import loggerMiddleware from "./middleware/logger";
import thunkMiddleware from "redux-thunk";

const store = createStore(rootReducer,
  applyMiddleware(loggerMiddleware, socketMiddleware, thunkMiddleware)
);

export const persistor = persistStore(store);
export default store;
