import React from 'react';
import classes from './Icon.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTemperatureHigh,
  faThermometerHalf,
  faThermometerFull,
  faHomeAlt,
  faTabletAndroidAlt,
  faUserHardHat,
  faServer,
  faList,
  faSitemap,
  faQuestion,
  faUserCircle,
  faCog,
  faFileUser,
  faKey,
  faUserTimes,
  faSortDown,
  faChevronRight,
  faMapMarked,
  faMapMarkerEdit,
  faBuilding,
  faBell,
  faUserEdit,
  faKeyboard,
  faLockAlt,
  faPowerOff,
  faSignOut as faSignOutDuo,
  faSignIn as faSignInDuo,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faBoxAlt,
  faMobile,
  faUserSlash,
  faQuestionCircle,
  faUserClock,
  faArrowAltCircleRight,
  faIdCardAlt,
  faFileMinus,
  faPlaneArrival,
  faViruses,
  faHospitalUser,
  faHotel,
  faSyringe
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faCheck as faCheckSolid,
  faExclamationTriangle,
  faExclamationCircle as faExclamationCircleSolid,
  faInfoCircle,
  faFilter,
  faSignOut,
  faSave,
  faKey as faKeySolid,
  faEnvelope,
  faFileExport,
  faEdit as faEditSolid,
  faTrash as faTrashSolid,
  faPhone,
} from "@fortawesome/pro-solid-svg-icons"

import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight as chevronRights,
  faSearch,
  faTimes,
  faArrowLeft,
  faQrcode,
  faSignIn,
  faArrowToBottom,
  faGripLines,
  faMinusCircle,
  faSignOut as faSignOutRegular,
  faExternalLink,
  faFileImport
} from "@fortawesome/pro-regular-svg-icons"

import {
  faEllipsisV,
  faPlus
} from "@fortawesome/pro-light-svg-icons"

const Icon = React.forwardRef(function Icon( props, ref ) {
  let regularIcons = {
    "thermometer-full": faThermometerFull,
    exit:faTimes,
    "left-arrow": faArrowLeft,
    search: faSearch,
    check: faCheck,
    chevronDown: faChevronDown,
    chevronUp: faChevronUp,
    chevronRight: chevronRights,
    chevronLeft: faChevronLeft,
    times: faTimes,
    qrcode: faQrcode,
    arrowToBottom: faArrowToBottom,
    grip:faGripLines,
    minusCircle: faMinusCircle,
    "sign-out": faSignOutRegular,
    "external-link": faExternalLink,
    "file-import": faFileImport
  };

  let duotoneIcons = {
    thermometer: faThermometerHalf,
    thermometerFull: faThermometerFull,
    temperature: faTemperatureHigh,
    tabletAndroid: faTabletAndroidAlt,
    home: faHomeAlt,
    site: faSitemap,
    users: faUserHardHat,
    machines: faServer,
    logs: faList,
    user: faUserCircle,
    "user-edit": faUserEdit,
    config: faCog,
    userDocument: faFileUser,
    archive: faUserTimes,
    "keyboard": faKeyboard,
    sort: faSortDown,
    map: faMapMarked,
    "assigned-site": faMapMarkerEdit,
    "chevron-right": faChevronRight,
    "sign-out": faSignOutDuo,
    "sign-in": faSignInDuo,
    "building": faBuilding,
    "bell": faBell,
    "power-off": faPowerOff,
    "lock-alt": faLockAlt,
    "exclamation-circle": faExclamationCircle,
    "check-circle": faCheckCircle,
    "times-circle": faTimesCircle,
    "box-alt": faBoxAlt,
    "mobile": faMobile,
    "user-slash": faUserSlash,
    "question-circle": faQuestionCircle,
    "user-clock": faUserClock,
    "arrow-alt-circle-right": faArrowAltCircleRight,
    "id-card-alt": faIdCardAlt,
    "file-minus": faFileMinus,
    "plane-arrival": faPlaneArrival,
    "viruses": faViruses,
    "hospital-user": faHospitalUser,
    "hotel": faHotel,
    "syringe": faSyringe
  }

  let solidIcons = {
    filter: faFilter,
    question: faQuestion,
    "envelope": faEnvelope,
    "export": faFileExport,
    "save": faSave,
    "key-solid": faKeySolid,
    check: faCheckSolid,
    error: faExclamationCircleSolid,
    warning: faExclamationTriangle,
    info: faInfoCircle,
    key: faKey,
    "sign-out": faSignOut,
    edit: faEditSolid,
    "delete": faTrashSolid,
    phone: faPhone,
  }

  let lightIcons = {
    menu: faEllipsisV,
    plus: faPlus
  }

  let icon;
  switch (props.type) {
    case "regular": icon = regularIcons[props.icon]; break;
    case "duo": icon = duotoneIcons[props.icon]; break;
    case "solid": icon = solidIcons[props.icon]; break;
    case "light": icon = lightIcons[props.icon]; break;
    default: icon = duotoneIcons[props.icon]; break;
  }

  if (!icon) {
    icon = regularIcons[props.icon];
  }

  if (!icon) {
    icon = solidIcons[props.icon];
  }

  if (!icon) {
    icon = lightIcons[props.icon];
  }

  const style = props.style || {};
  if (props.color) {
    style.color = props.color;
  }

  return (
    <FontAwesomeIcon title={props.title} className={`${props.className} ${classes.Image}`} icon={icon} style={style} size={props.size} onClick={props.onClick ? props.onClick : null} />
  );
})

export default Icon;
