export const LOGOUT = "LOGOUT";
export const SET_TOKEN = "SET_TOKEN";
export const SET_2FA_TOKEN = "SET_2FA_TOKEN";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_2FA_METHODS = "SET_2FA_METHODS";
export const ADD_2FA_METHODS = "ADD_2FA_METHODS";
export const UPDATE_ME = "UPDATE_ME";

export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const CLEAR_TOASTS = "CLEAR_TOASTS";

export const SOCKET_INIT = "SOCKET_INIT";
export const SOCKET_CONNECT = "SOCKET_CONNECT";
export const SOCKET_DISCONNECT = "SOCKET_DISCONNECT";
export const SOCKET_MESSAGE = "SOCKET_MESSAGE";
export const SOCKET_GET_CONNECTED_DEVICES = "SOCKET_GET_CONNECTED_DEVICES";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

export const CHANGE_LANG = "CHANGE_LANG";

export const RESET_STATE = "RESET_STATE";

export const HOME_SET_PERIOD = "HOME_SET_PERIOD";
export const HOME_SET_SITE = "HOME_SET_SITE";

export const BOARD_SET_SITES = "BOARD_SET_SITES";
export const BOARD_SET_SITE_ID = "BOARD_SET_SITE_ID";

export const CHECKIN_INVALID_METHOD = "CHECKIN_INVALID_METHOD";
export const CHECKIN_UPDATE_MACHINE_DATA = "CHECKIN_UPDATE_MACHINE_DATA";
export const CHECKIN_FETCH_USER_DATA_STARTED = "CHECKIN_FETCH_USER_DATA_STARTED";
export const CHECKIN_FETCH_USER_DATA_FAILED = "CHECKIN_FETCH_USER_DATA_FAILED";
export const CHECKIN_FETCH_USER_DATA_COMPLETE = "CHECKIN_FETCH_USER_DATA_COMPLETE";
export const CHECKIN_UPDATE_FIRST_NAME = "CHECKIN_UPDATE_FIRST_NAME";
export const CHECKIN_UPDATE_LAST_NAME = "CHECKIN_UPDATE_LAST_NAME";
export const CHECKIN_UPDATE_MOBILE = "CHECKIN_UPDATE_MOBILE";
export const CHECKIN_UPDATE_DEPENDENTS = "CHECKIN_UPDATE_DEPENDENTS";
export const CHECKIN_COMPLETE_DETAILS = "CHECKIN_COMPLETE_DETAILS";
export const CHECKIN_UPDATE_ROLE = "CHECKIN_UPDATE_ROLE";
export const CHECKIN_ANSWER_QUESTION = "CHECKIN_ANSWER_QUESTION";
export const CHECKIN_SUBMISSION_STARTED = "CHECKIN_SUBMISSION_STARTED";
export const CHECKIN_SUBMISSION_COMPLETE = "CHECKIN_SUBMISSION_COMPLETE";
export const CHECKIN_SUBMISSION_BAD_CODE = "CHECKIN_SUBMISSION_BAD_CODE";
export const CHECKIN_SUBMISSION_FAILED = "CHECKIN_SUBMISSION_FAILED";
export const CHECKIN_CHECKOUT_STARTED = "CHECKIN_CHECKOUT_STARTED";
export const CHECKIN_CHECKOUT_COMPLETE = "CHECKIN_CHECKOUT_COMPLETE";
export const CHECKIN_CHECKOUT_FAILED = "CHECKIN_CHECKOUT_FAILED";
export const CHECKIN_START_AGAIN = "CHECKIN_START_AGAIN";
