import React from 'react';
import classes from './Filter.module.css';


const filter = (props) => {
  // Need to grab sites from outside
  const sites = ['CK1', 'CK2', 'CK3',];

  const siteItems = sites.map((site) =>
      <label className={classes.container}>
        {site}
        <input type='checkbox' />
        <span className={classes.checkmark}></span>
      </label>
  );

  function cancel(e) {
    console.log(e);
  }

  function filter(e) {
    console.log(e);
  }
  return (
      <div className={classes.Panel}>
        <div className={classes.Sites}>
          {siteItems}
        </div>
        <div className={classes.midBorder}> </div>
        <div className={classes.Buttons}>
          <button className={classes.CancelButton} onClick={cancel}>Cancel</button>
          <button className={classes.SaveButton} onClick={filter}>Save</button>
        </div>
      </div>
  );
};

export default filter;
