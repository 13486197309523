import React from "react";
import classes from "./Notifications.module.css";
import Button from "./widgets/Button/Button";
import Icon from "./widgets/Icon/Icon";
import { Popup, PopupHeader } from "./widgets/SideBar/Popup";
import moment from "moment";
import { guid } from "../utils/helpers";
import messages from "./Notifications.messages";
import buttonMessages from "../components/widgets/Button/Button.messages";

function Notifications(props) {
  const notifications = props.notifications.map(n => {
    let timeSince = getTimeSince(n.date);
    let unit = getUnit(timeSince);
    timeSince = -convertTimeSince(timeSince);
    return <Notification text={n.message} name={n.name} time={props.intl.formatRelativeTime(timeSince, unit)} key={guid()} {...props} />
  })

  return (
    <Popup>
      <PopupHeader>
        <span>{props.intl.formatMessage(messages.Notifications)}</span>
        <div className={classes.Flex}></div>
        <Button link onClick={props.clearNotifications}>{props.intl.formatMessage(buttonMessages.ClearAll)}</Button>
      </PopupHeader>
      <div className={classes.Notifications}>
        {!notifications.length ? <div style={{padding: "1rem"}}>{props.intl.formatMessage(messages.None)}</div> : notifications}
      </div>
    </Popup>
  )
}

export function getUnit(time) {
  if (time < 60) {
    return "second"
  }
  if (time >= 60 && time < 3600) {
    return "minute";
  }
  if (time >= 3600 && time < 86400) {
    return "hour"
  }
  if (time >= 86400) {
    return "day"
  }
}

export function convertTimeSince(time) {
  if (time < 60) {
    return time;
  }
  if (time >= 60 && time < 3600) {
    return Math.round(time / 60);
  }
  if (time >= 3600 && time < 86400) {
    return Math.round(time / 3600);
  }
  if (time >= 86400) {
    return Math.round(time / 86400);
  }
}

export function getTimeSince(date) {
  var now = moment(new Date()); //todays date
  var start = moment(date); // another date
  var duration = moment.duration(now.diff(start));
  return Math.round(duration.asSeconds());
}

function Notification(props) {
  let iconColor, backgroundColor;

  if (props.level === "danger") {
    backgroundColor = "#F8282831";
    iconColor = "#E31F1F";
  } else if (props.level === "warning") {
    backgroundColor = "#FA9B1830";
    iconColor = "#FAA530";
  } else {
    backgroundColor = "#FA9B1830";
    iconColor = "#FAA530";
  }

  const breakText = (text) => {
    return `${(text||'').split('\n').join('</br>')}`;
  }

  return (
    <div className={classes.Notification}>
      <div className={classes.NotificationIconContainer} style={{backgroundColor: backgroundColor}}>
        <Icon icon={props.icon || "info"} size="lg" color={iconColor} />
      </div>
      <div className={classes.NotificationText}>
        <div>{props.name && <span className={classes.NotificationName}>{props.name}&nbsp;</span>}<span dangerouslySetInnerHTML={{ __html: breakText(props.text) }}></span></div>
      </div>
      <div className={classes.Flex}></div>
      <span className={classes.NotificationTime}>{props.time}</span>
    </div>
  )
}

export default Notifications;