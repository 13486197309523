import React from "react";
import ReactDOM from "react-dom";
import SpinnerIcon from "./SpinnerIcon";
import classes from "./Spinner.module.css";

function Spinner(props) {
  if (!props.active) {
    return null;
  }

  const content = (
    <Overlay>
      <div className={classes.SpinnerContainer}>
        <SpinnerIcon/>
      </div>
    </Overlay>
  );

  return props.fixed ? ReactDOM.createPortal(content, document.querySelector("body"))
    : content;
}

function Overlay(props) {
  return (
    <div className={classes.Overlay}>
      {props.children}
    </div>
  )
}

export default Spinner;
