import React, { useState, useRef, forwardRef, useEffect, useLayoutEffect } from 'react';
import Input from './Input';
import PhoneNumber from 'awesome-phonenumber';
import {useSelector} from "react-redux";

export function checkPhoneValidity(value, regionCode) {
  if (!value)
    return true;
  //console.log('checkPhoneValidity -> Region 1:', window._config?.region, regionCode);
  if(!regionCode)
    regionCode = window._config?.region || "AU";
  //console.log('checkPhoneValidity -> Region 2:', regionCode);
  const pn = new PhoneNumber(value, regionCode);
  return pn.isValid();
}

export default forwardRef(function PhoneInput(props, ref) {
  let regionCode = useSelector(s => s.checkin.machineData?.config?.region);
  const { value, isValid: isValidProp,
          onIsValidChange = () => {}, onChange = () => {}, ...otherProps } = props;
  const [isValidState, setIsValid] = useState(true);
  const useValidProp = 'isValid' in props;
  const isValid = useValidProp ? isValidProp : isValidState;

  const inputRef = useRef();
  if (!ref)
    ref = inputRef;

  useEffect(() => {
    if (!useValidProp)
      updateValidity(value);
  }, [useValidProp, value]);

  useLayoutEffect(() => {
    inputRef.current.setCustomValidity(isValid ? '' : 'Please enter a valid phone number');
    inputRef.current.checkValidity();
  }, [isValid]);

  useEffect(() => {
    if (!useValidProp)
      onIsValidChange(isValid);
  }, [useValidProp, isValid]);

  function updateValidity(value) {
    let isValid = checkPhoneValidity(value, regionCode);
    setIsValid(isValid);
    return isValid;
  }

  function onInputChange(e) {
    onChange(e, ref.current.value, updateValidity(ref.current.value));
  }

  return <Input ref={ref} type="tel" value={value} onChange={onInputChange} {...otherProps}/>
});
