import io from "socket.io-client";
import store from "../redux/store";
import { getNodeHost } from "./ajax";

function connectSocket() {
  let host = getNodeHost();
  let options = {
    query: { token: store.getState().auth.token },
    transports: ['websocket']
  }
  if (process.env.NODE_ENV === "production") {
    return io.connect("/web", options);
  }
  return io.connect(host + "/web", options);
}

const ClientSocket = {
  socket: null,
  connected() {
    return this.socket && this.socket.connected;
  },
  disconnected() {
    return this.socket && !this.socket.connected;
  },
  connect() {
    if (this.socket) {
      return Promise.resolve();
    }
    this.socket = connectSocket();
    return new Promise((resolve, reject) => {
      this.socket.on("connect", () => resolve(this.socket));
      this.socket.on("connect_error", reject);
      this.socket.on("error", (err) => console.error(err));
    })
	},
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  },
  send(message) {
    if (!this.socket) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.socket.send(message, (data) => data.error ? reject(data.error) : resolve(data))
    })
  },
  emit(event, message) {
    if (!this.socket) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.socket.emit(event, message, (data) => data && data.error ? reject(data.error) : resolve(data));
    })
  },
  join(room) {
    if (!this.socket) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.socket.emit("join", room, (data) => data && data.error ? reject(data.error) : resolve(data))
    })
	},
  leave(room) {
    if (!this.socket) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.socket.emit("leave", room, (data) => data && data.error ? reject(data.error) : resolve(data))
    })
  },
  off(event, fn) {
    if (!this.socket) {
      return;
    }
    this.socket.off(event, fn);
  },
	on(event, cb) {
		if (!this.socket) {
			return;
		}
		this.socket.on(event, cb);
	}
}

export default ClientSocket;
