import store from "../redux/store";
import { addToast } from "../redux/actions";

const toast = {};

function dispatch(message, type) {
  store.dispatch(addToast(message, type));
}

toast.error = function(message) {
  dispatch(message, "error");
}

toast.success = function(message) {
  dispatch(message, "success");
}

toast.warning = function(message) {
  dispatch(message, "warning");
}

toast.info = function(message) {
  dispatch(message, "info");
}

export default toast;