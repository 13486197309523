import React from "react";
import classes from "./Error.module.css";

function Error(props) {
  return (
    <div className={classes.Error}>
      <div className={classes.ErrorMessage}>
        <div>Oops! Something went wrong.</div>
        <div>Please try refreshing the browser or contact support.</div>
      </div>
    </div>
  )
}

export default Error;