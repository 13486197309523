import React, {useState} from "react";
import {useIntl} from "react-intl";
import classes from "./Input.module.css";
import {guid} from "../../../utils/helpers";
import Icon from "../Icon/Icon";
import buttonMessages from "../Button/Button.messages";

const Input = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const [uniqueId] = useState(() => "input_" + guid());
  const { id = uniqueId, className, style = {}, password, fill, search, searchName,
          ...otherProps } = props;
  const inputStyle = {};

  let searchIcon = (
    <div className={classes.SearchIcon} onClick={props.onSearch ? props.onSearch : null}>
      <Icon icon="search" />
    </div>
  );

  if (search) {
    otherProps.placeholder = intl.formatMessage(buttonMessages.Search);
    inputStyle.paddingLeft = "1rem";
  }  else if (searchName) {
    otherProps.placeholder = intl.formatMessage(buttonMessages.SearchName);
    inputStyle.paddingLeft = "1rem";
  }

  const input = (
    <input
      { ...otherProps }
      ref={ref}
      id={id}
      style={inputStyle}
    />
  )
  return (<>
    <div className={`${classes.Input} ${className} ${fill ? classes.Fill : ''}  ${password ? classes.PasswordInput : ''}`} style={style}>
      {props.label && <label htmlFor={id} className={classes.Label}>{props.label} {(props.required ? <span style={{color: "red"}}>*</span> : '')}</label>}
      <div className="layout horizontal center">
        {input}
        {search && searchIcon}
      </div>
    </div>
        <div className={`${className} ${password ? '' : classes.NoPassword}`}>
          This is a common password that has been found {(+password).toLocaleString()} times in public security breaches.
        </div>
    </>
  )
})

export default Input;
