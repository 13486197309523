import React, {useEffect, useRef} from "react";
import classes from "./Toast.module.css";
import Icon from "../Icon/Icon";
import { connect } from "react-redux";
import { removeToast } from "../../../redux/actions";

function _Toast(props) {
  const timeoutId = useRef(0);
  const {timeout, id, removeToast } = props;

  function closeToast(e) {
    props.removeToast(props.id);
  }

  useEffect(() => {
    if (!timeout) return;
    timeoutId.current = window.setTimeout(() => removeToast(id), timeout);
    return () => {
      window.clearTimeout(timeoutId.current);
    }
  }, [timeout, id, removeToast])

  let duration, fadeIn;
  if (!props.timeout) {
    duration = "3s";
    fadeIn = true;
  } else {
    duration = (props.timeout / 1000) + "s";
  }

  let type;
  switch (props.type) {
    case "error": type = classes.ToastError; break;
    case "warning": type = classes.ToastWarning; break;
    case "success": type = classes.ToastSuccess; break;
    case "info": type = classes.ToastInfo; break;
    default: type = classes.ToastInfo;
  }

  return (
    <div style={{ animationDuration: duration }} className={`${classes.Toast} ${fadeIn ? classes.ToastFadeIn : null} ${type}`} onClick={closeToast}>
      <div className={`${classes.ToastContent}`}>
        <ToastIcon {...props}></ToastIcon>
        <ToastBody {...props}></ToastBody>
        <div className={classes.ToastClose}>
          <Icon icon="exit"></Icon>
        </div>
      </div>
      {props.timeout ? <ToastProgress duration={duration} {...props} /> : null}
    </div>
  )
}

function ToastProgress(props) {
  return (
    <div style={{ animationDuration: props.duration }} className={classes.ToastProgress}></div>
  )
}

function ToastIcon(props) {
  let icon;
  switch (props.type) {
    case "info": {
      icon = "info";
      break;
    }
    case "success": {
      icon = "check";
      break;
    }
    case "error": {
      icon = "error";
      break;
    }
    case "warning": {
      icon = "warning";
      break;
    }
    default: {
      icon = "info";
      break;
    }
  }

  return (
    <div className={classes.ToastIcon}>
      <Icon icon={icon} size="lg" />
    </div>
  )
}

function ToastBody(props) {
  return (
    <div className={`${classes.ToastBody}`}>
      {props.message || ""}
    </div>
  )
}

function mapStateToProps(state) {
  return { toasts: state.toasts };
}

function ToastContainer(props) {
  const toasts = [];

  props.toasts.forEach(toast => {
    toasts.push(
      <Toast key={toast.id} message={toast.message} type={toast.type} id={toast.id} timeout={toast.timeout} />
    )
  })

  if (!props.toasts || !props.toasts.length) {
    return null;
  }

  return (
    <div className={classes.ToastContainer}>
      {toasts}
    </div>
  )
}

const Toast = connect(
  null,
  { removeToast }
)(_Toast) ;
export default connect(
  mapStateToProps,
  null
)(ToastContainer);