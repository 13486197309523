import React from "react";
import classes from "./Footer.module.css";
import Icon from "../components/widgets/Icon/Icon";

function Footer(props) {
  if (!props.count) {
    return null;
  }

  return (
    <div className={classes.Footer}>
      <div className={classes.Count}>
        <div style={{backgroundColor: "#004867", color: "white", borderRadius: "2px"}}>{props.count}</div>
        <div className={classes.Records}>Records on page are selected.</div>
      </div>
      <div className="flex"/>
      {props.children}
    </div>
  )
}

export function FooterAction(props) {
  return (
    <div className={classes.FooterAction} onClick={props.onClick ? props.onClick : null}>
      <Icon icon={props.icon} type={props.iconType} style={props.iconStyle} />
      <div>{props.children}</div>
    </div>
  )
}

export {classes};
export default Footer;
