import Icon from "../Icon/Icon";
import React from "react";
import classes from "./Popup.module.css";

export function Popup(props) {
  return (
    <div className={classes.Popup}>
      {props.children}
    </div>
  )
}

export function PopupHeader(props) {
  return (
    <div className={classes.PopupHeader}>
      {props.children}
    </div>
  )
}

export function PopupItem(props) {

  function onClick() {
    props.onClick(props.page);
  }

  return (
    <div className={classes.PopupItem} onClick={props.onClick ? onClick : null}>
      <div className={classes.IconContainer}>
        <Icon icon={props.icon} size="lg" />
      </div>
      <span style={props.labelStyle}>{props.text}</span>
    </div>
  )
}
