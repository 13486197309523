import React, { useRef, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useParams, useHistory, useLocation, matchPath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHtmlId } from "../utils/react";
import ajax from "../utils/ajax";
import Button from "../components/widgets/Button/Button";
import DateTime from "../components/widgets/DateTime/DateTime";
import Icon from "../components/widgets/Icon/Icon";
import Input from "../components/widgets/Input/Input";
import PhoneInput, { checkPhoneValidity } from "../components/widgets/Input/PhoneInput";
import PinField from 'react-pin-field';
import Radio from "../components/widgets/Radio/Radio";
import Select from "../components/widgets/Select/Select";
import Spinner from "../components/widgets/Spinner/Spinner";
import SpinnerIcon from "../components/widgets/Spinner/SpinnerIcon";
import coolGardLogo from "../assets/images/coolgard-checkin.svg";
import batStationLogo from "../assets/images/bat-station.svg";
import pulseLogo from "../assets/images/pulsepresence-shoes-blue.svg";
import classes from './CheckIn.module.css';
import messages from './CheckIn.messages';
import {
  fetchMachineData,
  fetchUserData,
  updateFirstName,
  updateLastName,
  updateMobile,
  updateDependents,
  answerQuestion,
  submitDetails,
  startAgain,
  completeDetails,
  updateRole,
  checkOut,
} from '../redux/checkinActions';

function CheckInHeader(props) {
  const {backButton} = props;
  const pastBackText = useRef('');
  const isBatbooth = useSelector(s => s.locale.isBatbooth);
  const isPulse = useSelector(s => s.locale.isPulse);

  function onBack(e) {
    if (props.backButton)
      props.onBack && props.onBack(e);
  }

  // Keep a copy of the old back button text, so it's still visible while it
  // fades out
  if (backButton)
    pastBackText.current = backButton;

  return <header className={`${classes.CheckInHeader} ${isPulse ? classes.CheckInHeaderPulse : ''} ${backButton ? classes.back : ''}`}>
    <button className={classes.CheckInHeaderBack}
            onClick={onBack}
            disabled={!backButton}
            aria-hidden={!backButton}>
      <Icon className={classes.CheckInHeaderBackIcon} type="regular" icon="chevronLeft"/>
      {backButton || pastBackText.current}
    </button>
    <img className={`${classes.CheckInHeaderLogo} ${isPulse ? classes.CheckInHeaderPulseLogo : ''}`}
         src={isBatbooth ? batStationLogo : (isPulse ? pulseLogo : coolGardLogo)}
         alt={isBatbooth ? 'Bat Station' : (isPulse ? 'PulsePresence' : 'CoolGard')}/>
  </header>;
}

function CheckInButtonBox(props) {
  return <div className={classes.CheckInButtonBox}>
    {props.children}
  </div>;
}

function CheckInBanner(props) {
  const { siteName } = props;
  const intl = useIntl();

  return <section className={classes.CheckInBanner}>
    <h1>{intl.formatMessage(messages.GuestSignIn)}</h1>
    <h2>{siteName === null ? <>&nbsp;</> : siteName}</h2>
  </section>;
}

function CheckInMenu(props) {
  const { pages, currentPage } = props;
  const history = useHistory();

  function isDisabled(pageNum) {
    for (let i = 0; i < pageNum; i++) {
      // A page is disabled if the previous pages are incomplete
      if (!pages[i].complete)
        return true;
    }
    return false;
  }

  function onClick(page, pageNum) {
    if (isDisabled(pageNum))
      return;
    history.push(page.link);
  }

  return <nav className={classes.CheckInMenu}>
    <ol>
      {pages.map((page, i) =>
        <li key={page.name} className={page.name === currentPage ? classes.selected : ''}>
          <button disabled={isDisabled(i)} onClick={() => onClick(page, i)}>
            {i + 1}. {page.text}
            {page.complete && <Icon className={classes.Check} type="regular" icon="check"/>}
          </button>
        </li>
      )}
    </ol>
  </nav>;
}

function CheckInForm(props) {
  const { mobileValid, detailsValid, onComplete } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const firstName = useSelector(s => s.checkin.userDetails?.firstName);
  const lastName = useSelector(s => s.checkin.userDetails?.lastName);
  const mobile = useSelector(s => s.checkin.userDetails?.mobile);
  const dependents = useSelector(s => s.checkin.dependents);
  const dependentsChanged = useSelector(s => s.checkin.dependentsChanged);

  function onSubmit(e) {
    e.preventDefault();
    if (detailsValid)
      onComplete();
  }

  return <form className={classes.CheckInForm}
               onSubmit={onSubmit}>
    <Input className={classes.CheckInInput}
           type="text"
           autoFocus
           required
           name="fname"
           autoComplete="given-name"
           maxLength="40"
           placeholder={intl.formatMessage(messages.FirstName)}
           label={intl.formatMessage(messages.FirstName)}
           value={firstName || ''}
           onChange={({target}) => dispatch(updateFirstName({ firstName: target.value }))}/>
    <Input className={classes.CheckInInput}
           type="text"
           required
           name="lname"
           autoComplete="family-name"
           maxLength="40"
           placeholder={intl.formatMessage(messages.LastName)}
           label={intl.formatMessage(messages.LastName)}
           value={lastName || ''}
           onChange={({target}) => dispatch(updateLastName({ lastName: target.value }))}/>
    <PhoneInput className={classes.CheckInInput}
                required
                name="phone"
                autoComplete="tel"
                maxLength="30"
                placeholder={intl.formatMessage(messages.Phone)}
                label={intl.formatMessage(messages.Phone)}
                value={mobile || ''}
                isValid={mobileValid}
                onChange={(e, mobile) => dispatch(updateMobile({ mobile }))}/>
    <Select className={classes.CheckInInput}
            name="dependents"
            selected={dependentsChanged ? dependents : ''}
            label={intl.formatMessage(messages.DependantsLabel)}
            onChange={val => dispatch(updateDependents({ dependents: +val }))}>
      <option value="" disabled hidden>{intl.formatMessage(messages.Dependants)}</option>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => <option key={n} value={n}>{n === 10 ? '10+' : n}</option>)}
    </Select>
    <CheckInButtonBox>
      <Button greyOnInvalid type="submit">{intl.formatMessage(messages.Continue)}</Button>
    </CheckInButtonBox>
  </form>;
}

function YesNoQuestion(props) {
  const name = useHtmlId('free-text');
  const labelId = useHtmlId('label');
  const {question, answer} = props;

  function onChange(checked) {
    props.onAnswer && props.onAnswer(checked ? 'yes' : 'no');
  }
  function onYesChange(e, checked) {
    onChange(checked);
  }
  function onNoChange(e, checked) {
    onChange(!checked);
  }

  const yesChecked = answer === 'yes';
  const yesFailed = yesChecked && /^fail$/i.test(question.answers.yes.action);
  const yesPassed = yesChecked && !yesFailed;
  const noChecked = answer === 'no';
  const noFailed = noChecked && /^fail$/i.test(question.answers.no.action);
  const noPassed = noChecked && !noFailed;

  return <section className={classes.CheckInQuestion}>
    <p id={labelId}>{question.questionText} <span style={{color: 'red'}}>*</span></p>
    <div role="radiogroup"
         aria-labelledby={labelId}
         className={classes.RadioButtons}>
      <Radio className={classes.CheckInAnswer}
             name={name}
             required
             value="yes"
             checked={yesChecked}
             variant={yesFailed ? 'danger' : undefined}
             onChange={onYesChange}
             buttonMode>
        {question.answers.yes.text}
        {yesPassed && <Icon className={classes.Passed} type="regular" icon="check"/>}
        {yesFailed && <Icon className={classes.Failed} type="regular" icon="times"/>}
      </Radio>
      <Radio className={classes.CheckInAnswer}
             name={name}
             required
             value="no"
             checked={noChecked}
             variant={noFailed ? 'danger' : undefined}
             onChange={onNoChange}
             buttonMode>
        {question.answers.no.text}
        {noPassed && <Icon className={classes.Passed} type="regular" icon="check"/>}
        {noFailed && <Icon className={classes.Failed} type="regular" icon="times"/>}
      </Radio>
    </div>
  </section>;
}

function FreeTextQuestion(props) {
  const {question, answer} = props;
  const id = useHtmlId('free-text');

  function onChange({ target }) {
    props.onAnswer && props.onAnswer(target.value);
  }

  return <section className={classes.CheckInQuestion}>
    <label htmlFor={id}
           className={classes.QuestionText}>{question.questionText} <span style={{color: 'red'}}>*</span></label>
    <Input id={id}
           className={`${classes.CheckInInput} ${classes.FreeTextQuestionInput}`}
           type="text"
           required
           placeholder={'Answer'}
           value={answer || ''}
           onChange={onChange}/>
  </section>;
}

function SelectQuestion(props) {
  const {question, answer} = props;
  const id = useHtmlId('select');
  const failed = /^fail$/i.test(answer?.action ?? '');

  function onChange(value) {
    props.onAnswer && props.onAnswer(question.answers[+value]);
  }

  return <section className={classes.CheckInQuestion}>
    <label htmlFor={id}
           className={classes.QuestionText}>{question.questionText} <span style={{color: 'red'}}>*</span></label>
    <Select id={id}
            className={`${classes.CheckInInput} ${classes.SelectQuestionInput}`}
            required
            selected={answer ? question.answers.indexOf(answer) : ''}
            badge={failed && 'times'}
            badgeColor="#bd2130"
            onChange={onChange}>
      <option value="" disabled hidden>Answer</option>
      {question.answers?.map?.((a, i) => <option key={i} value={i}>{a?.desc ?? i}</option>)}
    </Select>
  </section>;
}

function MultiSelectAnswer(props) {
  const uniqueId = useHtmlId('answer');
  const {id = uniqueId, checked, children, style = {}, className, onChange,
         ...otherProps} = props;

  function onInnerChange(e) {
    onChange && onChange(e, e.target.checked);
  }

  return <>
    <input type="checkbox"
           id={id}
           className={classes.MultiSelectAnswerInput}
           checked={!!checked}
           onChange={onInnerChange}
           {...otherProps}/>
    <label htmlFor={id}
           className={`${classes.MultiSelectAnswer} ${className || ''}`}
           style={style}>
      {children}
    </label>
  </>;
}

function MultiSelectQuestion(props) {
  const {question, answer} = props;
  const labelId = useHtmlId('label');

  function onChange(newAnswer, checked) {
    if (checked) {
      props.onAnswer && props.onAnswer([...(answer ?? []), newAnswer]);
    } else {
      const newAnswers = answer?.filter(a => a !== newAnswer) ?? [];
      props.onAnswer && props.onAnswer(newAnswers.length ? newAnswers : null);
    }
  }

  return <section className={classes.CheckInQuestion}>
    <p id={labelId}>{question.questionText} <span style={{color: 'red'}}>*</span></p>
    <div role="group"
         aria-labelledby={labelId}
         className={classes.CheckBoxes}>
      {question.answers?.map?.((a, i) =>
        <MultiSelectAnswer key={i}
                           checked={answer?.includes?.(a) ?? false}
                           className={/^fail$/i.test(a.action) && classes.Error}
                           onChange={(e, checked) => onChange(a, checked)}>
          {a?.desc ?? i}
        </MultiSelectAnswer>)}
    </div>
  </section>;
}

function useRole() {
  return useSelector(state => {
    const numRoles = state.checkin.roles?.length ?? 0;
    if (numRoles === 0)
      return null;
    if (numRoles === 1)
      return state.checkin.roles[0].id;
    return state.checkin.role;
  });
}

function useQuestions() {
  const role = useRole();
  const questions = useSelector(state => {
    if (state.checkin.machineData?.questionsByUser)
      return state.checkin.questions;
    return state.checkin.machineData?.questions;
  });
  return useMemo(() =>
    questions?.filter?.(q => !q.roles?.length || (role && q.roles.includes(role))),
    [role, questions]);
}

function CheckInQuestions(props) {
  const { questionsFailed, questionsComplete, onComplete } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const questions = useQuestions();
  const answers = useSelector(s => s.checkin.answers);
  const roles = useSelector(s => s.checkin.roles ?? []);
  const role = useRole();
  const hasMultipleRoles = roles.length >= 2;

  function onSubmit(e) {
    e.preventDefault();
    if (questionsComplete) {
      onComplete();
    }
  }

  function onRolesQuestionAnswer(answer) {
    dispatch(updateRole({ role: answer?.id }));
  }

  const roleQuestion = useMemo(() => ({
    questionText: intl.formatMessage(messages.RoleQuestionText),
    answers: roles.map(({id, name}) => ({ id, desc: name, action: 'pass' })),
  }), [roles]);
  const roleAnswer = roleQuestion.answers.find(a => a.id === role);

  return <form className={classes.QuestionsForm}
               onSubmit={onSubmit}>
    {hasMultipleRoles &&
      <SelectQuestion question={roleQuestion}
                      answer={roleAnswer}
                      onAnswer={onRolesQuestionAnswer}/>}

    {questions.map(question => {
      function onAnswer(answer) {
        dispatch(answerQuestion({ id: question.id, answer }));
      }

      switch (question.type) {
        case 'yes,no':
          return <YesNoQuestion key={question.id}
                                question={question}
                                answer={answers[question.id]?.answer}
                                onAnswer={onAnswer}/>;
        case 'free text':
          return <FreeTextQuestion key={question.id}
                                   question={question}
                                   answer={answers[question.id]?.answer}
                                   onAnswer={onAnswer}/>;
        case 'select':
          return <SelectQuestion key={question.id}
                                 question={question}
                                 answer={answers[question.id]?.answer}
                                 onAnswer={onAnswer}/>;
        case 'multi-select':
          return <MultiSelectQuestion key={question.id}
                                      question={question}
                                      answer={answers[question.id]?.answer}
                                      onAnswer={onAnswer}/>;
        default:
          return null;
      }
    })}

    <CheckInButtonBox>
      <Button type="submit"
              disabled={questionsFailed}
              greyOnInvalid>
        {intl.formatMessage(messages.Continue)}
      </Button>
    </CheckInButtonBox>
  </form>;
}

function RequestSpinner(props) {
  const { state, onRetry, retryIsSubmit } = props;
  const intl = useIntl();

  switch (state) {
    case 'started':
      return <p className={classes.CodeStatus}>
        <SpinnerIcon className={classes.CodeStatusSpinner}/>
        <br/>
        {intl.formatMessage(messages.WaitingForAResponse)}
      </p>;
    case 'bad-code':
      return <p className={classes.CodeStatus}>
        {intl.formatMessage(messages.PleaseTryAgain)}
      </p>;
    case 'failed':
      return <>
        <p className={classes.CodeStatus}>
          {intl.formatMessage(messages.SubmissionFailed)}
        </p>
        {(onRetry || retryIsSubmit) &&
          <Button className={classes.RetryButton}
                  type={retryIsSubmit ? 'submit' : 'button'}
                  greyOnInvalid={!!retryIsSubmit}
                  onClick={onRetry}>
            {intl.formatMessage(messages.Retry)}
          </Button>}
      </>
    default:
      return <></>;
  }
}

function CodeEntry() {
  const { methodKey } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const machineId = useSelector(s => s.checkin.machineData?.machineId);
  const firstName = useSelector(s => s.checkin.userDetails?.firstName);
  const lastName = useSelector(s => s.checkin.userDetails?.lastName);
  const mobile = useSelector(s => s.checkin.userDetails?.mobile);
  const dependents = useSelector(s => s.checkin.dependents);
  const role = useRole();
  const questions = useQuestions();
  const answers = useSelector(s => s.checkin.answers);
  const submissionState = useSelector(s => s.checkin.submissionState);
  const useTemperature = useSelector(s => s.checkin.machineData?.useTemperature);
  const [verificationCode, setVerificationCode] = useState();
  const wrapperRef = useRef();
  const inputsRef = useRef();

  const verificationCodeComplete = verificationCode?.length === 6;

  // If the user went back and changed their details such that they received a
  // different set of questions, the answers object might contain answers to
  // outdated questions, so filter it before sending it
  const filteredAnswers = useMemo(() => {
    const filteredAnswers = {};
    for (const { id } of questions)
      filteredAnswers[id] = answers?.[id];
    return filteredAnswers;
  }, [questions, answers]);

  function onChange(verificationCode) {
    setVerificationCode(verificationCode);
  }

  function onComplete(verificationCode) {
    dispatch(submitDetails({
      methodKey,
      machineId, firstName, lastName, mobile, dependents, answers: filteredAnswers, role,
      ...(verificationCode ? {verificationCode} : {}),
    }));
  }

  function onRetry() {
    onComplete(verificationCode);
  }

  function onAnimationEnd() {
    wrapperRef.current.style.animation = '';
  }

  useEffect(() => {
    if (!useTemperature)
      onComplete(null);
  }, [useTemperature]);

  useLayoutEffect(() => {
    if (!useTemperature)
      return;

    if (submissionState === 'bad-code') {
      // Clear input on bad code
      for (const input of inputsRef.current)
        input.value = '';

      // Show shake animation
      wrapperRef.current.style.animation = `${classes['invalid-code']} .5s linear`;
    }

    if (submissionState === 'bad-code' || submissionState === 'not-submitted')
      inputsRef.current[0].focus();
  }, [useTemperature, submissionState]);

  return <>
    {useTemperature && <>
      <p className={classes.MainInstruction}>
        {intl.formatMessage(messages.ScanTemperature)}
        <br/>
        {intl.formatMessage(messages.EnterTheCodeDisplayed)}
      </p>

      <div ref={wrapperRef}
           className={classes.CheckInCodeInput}
           onAnimationEnd={onAnimationEnd}>
        <PinField ref={inputsRef}
                  autoComplete="off"
                  type="tel"
                  pattern="[0-9]*"
                  validate="0123456789"
                  length={6}
                  disabled={submissionState === 'started'}
                  onChange={onChange}
                  onComplete={onComplete}/>
      </div>
    </>}

    <RequestSpinner state={submissionState} onRetry={verificationCodeComplete && onRetry}/>

    <PulseBanner/>
  </>;
}

function InvalidMethod() {
  const intl = useIntl();

  return <>
    <Icon className={classes.StatusIcon} type="duo" icon="exclamation-circle"/>

    <section className={classes.StatusBanner}>
      <h1>{intl.formatMessage(messages.Error)}</h1>
      <h2>{intl.formatMessage(messages.SignInPageIsUnavailable)}</h2>
    </section>

    <section className={classes.StatusHint}>
      <h1>{intl.formatMessage(messages.HowDoISignIn)}</h1>
      <p>{intl.formatMessage(messages.HowDoISignInMessage)}</p>
    </section>

    <section className={`${classes.StatusHint} ${classes.StatusHintSmall}`}>
      <h1>{intl.formatMessage(messages.UnavailableVenueStaff)}</h1>
      <p>{intl.formatMessage(messages.UnavailableVenueStaffMessage)}</p>
    </section>
  </>;
}

function Complete(props) {
  const { onCheckOut } = props;
  const { methodKey } = useParams();
  const intl = useIntl();
  const firstName = useSelector(s => s.checkin.checkIns?.[methodKey]?.firstName);
  const lastName = useSelector(s => s.checkin.checkIns?.[methodKey]?.lastName);
  const mobile = useSelector(s => s.checkin.checkIns?.[methodKey]?.mobile);
  const siteName = useSelector(s => s.checkin.machineData?.siteName);
  const successMessage = useSelector(s => s.checkin.machineData?.successMessage);
  const submissionTime = useSelector(s => s.checkin.checkIns?.[methodKey]?.submissionTime);
  const banned = useSelector(s => s.checkin.checkIns?.[methodKey]?.banned);
  const temperature = useSelector(s => s.checkin.checkIns?.[methodKey]?.temperature);
  const highTemperature = useSelector(s => s.checkin.checkIns?.[methodKey]?.highTemperature);
  const lowTemperature = useSelector(s => s.checkin.checkIns?.[methodKey]?.lowTemperature);
  const checkOutToken = useSelector(s => s.checkin.checkIns?.[methodKey]?.checkOutToken);

  const detailProps = {
    p: c => <p>{c}</p>,
  };

  let message = intl.formatMessage(messages.Success);
  let messageDetail = intl.formatMessage(messages.SuccessDetail, detailProps);
  if (successMessage)
    messageDetail = <>{successMessage.split(/(?:\r?\n){2,}/g).map((m, i) => <p key={i}>{m}</p>)}</>;
  let success = true;

  if (lowTemperature) {
    success = false;
    message = intl.formatMessage(messages.LowTemperatureMessage);
    messageDetail = intl.formatMessage(messages.LowTemperatureDetail, detailProps);
  }

  if (highTemperature) {
    success = false;
    message = intl.formatMessage(messages.HighTemperatureMessage);
    messageDetail = intl.formatMessage(messages.HighTemperatureDetail, detailProps);
  }

  if (banned) {
    success = false;
    message = intl.formatMessage(messages.BannedMessage);
    messageDetail = intl.formatMessage(messages.BannedDetail, detailProps);
  }

  return <>
    {success
      ? <Icon className={`${classes.StatusIcon} ${classes.CompleteIcon}`}
              type="duo" icon="check-circle"/>
      : <Icon className={`${classes.StatusIcon} ${classes.FailureIcon}`}
              type="duo" icon="times-circle"/>}

    <section className={classes.StatusBanner}>
      <h1>{message}</h1>
      {messageDetail}
    </section>

    <dl className={classes.CompleteInfo}>
      <dt>{intl.formatMessage(messages.NameInfo)}</dt>
      <dd>{firstName} {lastName}</dd>
      <dt>{intl.formatMessage(messages.MobileInfo)}</dt>
      <dd>{mobile}</dd>
      <dt>{intl.formatMessage(messages.VenueInfo)}</dt>
      <dd>{siteName}</dd>
      <dt>{intl.formatMessage(messages.CheckedInInfo)}</dt>
      <dd>{intl.formatDate(submissionTime, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}</dd>
      {temperature !== null &&
        <>
          <dt>{intl.formatMessage(messages.TemperatureInfo)}</dt>
          <dd>{temperature}°</dd>
        </>}
    </dl>

    {success && checkOutToken ?
      <CheckInButtonBox>
        <Button variant="danger" onClick={onCheckOut} icon="sign-out" iconType="regular" iconInline>
          {intl.formatMessage(messages.CheckOut)}
        </Button>
      </CheckInButtonBox> :
      <PulseBanner/>}
  </>;
}

function PulseBanner() {
  const intl = useIntl();
  const isBatbooth = useSelector(s => s.locale.isBatbooth);
  const isPulse = useSelector(s => s.locale.isPulse);

  if (isBatbooth)
    return <></>;

  return <footer className={classes.PulseBanner}>
    <div className={classes.Copyright}>{intl.formatMessage( isPulse ? messages.PulseMiningSystems : messages.CoolGardPtyLtd)}</div>
    <div className={classes.PoweredBy}>
      {intl.formatMessage(messages.PoweredBy)}
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="39">
        <path d="M 27.538 20.868 C 26.427 20.87 25.451 21.618 25.141 22.705 L 17.626 22.319 C 17.572 21.118 17.141 19.965 16.398 19.031 L 18.106 17.32 C 19.507 18.36 21.457 18.13 22.589 16.793 C 23.721 15.455 23.658 13.456 22.443 12.196 C 21.229 10.936 19.268 10.835 17.936 11.965 C 16.603 13.094 16.343 15.076 17.337 16.523 L 15.63 18.234 C 14.865 17.581 13.942 17.151 12.957 16.988 L 13.531 7.36 C 15.413 7.175 16.838 5.545 16.8 3.618 C 16.763 1.692 15.275 0.12 13.387 0.012 C 11.5 -0.097 9.848 1.295 9.599 3.205 C 9.349 5.114 10.585 6.898 12.435 7.297 L 11.86 16.921 C 10.722 16.965 9.624 17.365 8.716 18.067 L 6.413 15.528 C 7.348 14.24 7.164 12.437 5.99 11.372 C 4.815 10.306 3.037 10.33 1.89 11.426 C 0.742 12.522 0.605 14.329 1.572 15.592 C 2.54 16.855 4.293 17.158 5.615 16.29 L 7.917 18.823 C 6.736 20.175 6.261 22.021 6.64 23.789 L 4.164 24.601 C 3.568 23.637 2.366 23.266 1.345 23.732 C 0.324 24.198 -0.204 25.359 0.106 26.454 C 0.416 27.549 1.47 28.245 2.577 28.086 C 3.685 27.926 4.509 26.96 4.509 25.82 C 4.509 25.769 4.509 25.719 4.502 25.67 L 6.978 24.857 C 7.46 25.98 8.284 26.915 9.328 27.523 L 7.66 31.352 C 5.727 30.79 3.696 31.845 3.008 33.769 C 2.319 35.694 3.207 37.834 5.041 38.671 C 6.876 39.509 9.034 38.759 9.984 36.954 C 10.935 35.149 10.356 32.903 8.657 31.807 L 10.326 27.973 C 12.344 28.652 14.565 28.098 16.047 26.545 L 17.415 27.663 C 16.322 29.503 16.815 31.891 18.541 33.127 C 20.268 34.364 22.64 34.027 23.969 32.356 C 25.298 30.685 25.13 28.251 23.585 26.786 C 22.041 25.32 19.646 25.324 18.106 26.794 L 16.732 25.677 C 17.165 25.002 17.451 24.24 17.569 23.443 L 25.075 23.829 C 25.291 25.158 26.489 26.085 27.804 25.938 C 29.119 25.792 30.093 24.624 30.022 23.278 C 29.951 21.932 28.86 20.877 27.537 20.875 Z" fill="#1e7bd2"/>
      </svg>
    </div>
  </footer>
}

// Get the offset of the client-side clock compared to the server such that
// server + offset = client.
async function getTimeOffset(signal) {
  const trials = 5;
  const keep = 3;
  const offsets = [];

  for (let i = 0; i < trials; i++) {
    const start = Date.now();
    const { data: server } = await ajax.get(`/checkin/time?t=${start}&i=${i}`, signal);
    const end = Date.now();
    offsets.push({ offset: (start + end) / 2 - Date.parse(server), duration: end - start });
  }

  offsets.sort((a, b) => a.duration - b.duration);
  const top = offsets.slice(0, keep);
  return top.reduce((a, { offset }) => a + offset, 0) / keep;
}

function CheckOut(props) {
  const { onCheckOut } = props;
  const { methodKey } = useParams();
  const intl = useIntl();
  const [currentDate, setCurrentDate] = useState(null);
  const [timeOffset, setTimeOffset] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [timeZoneOffset] = useState(new Date().getTimezoneOffset());
  const [timeZone] = useState((typeof window.Intl?.DateTimeFormat === 'function' && new window.Intl.DateTimeFormat()?.resolvedOptions?.()?.timeZone) || '');
  const submissionTime = useSelector(s => s.checkin.checkIns?.[methodKey]?.submissionTime);
  const checkOutState = useSelector(s => s.checkin.checkOutState);
  const checkOutDateValid = checkOutDate && !Number.isNaN(+checkOutDate);
  const busy = checkOutState === 'started';

  function onSubmit(e) {
    e.preventDefault();
    if (checkOutDateValid)
      onCheckOut({ checkOutDate, timeOffset, timeZoneOffset, timeZone });
  }

  useEffect(() => {
    // Remember, AbortController doesn't exist in all our target browsers
    const ac = typeof window.AbortController === 'function' ? new window.AbortController() : null;
    (async () => {
      const now = new Date((await ajax.get(`/checkin/time?t=${Date.now()}`, ac?.signal)).data);
      setCurrentDate(now);
      setCheckOutDate(now);
      setTimeOffset(await getTimeOffset(ac?.signal));
    })().catch(e => console.error(e));
    return () => ac?.abort?.();
  }, []);

  if (!currentDate)
    return <Spinner active/>

  return <>
    <section className={classes.MainInstruction}>
      <h1>Enter check out time</h1>
      <p>
        {intl.formatMessage(messages.CheckOutSignInTime, {
          time: submissionTime,
        })}
      </p>
    </section>

    <form className={classes.CheckOutForm}
               onSubmit={onSubmit}>
      <DateTime className={classes.CheckInInput}
                label={intl.formatMessage(messages.CheckOutLabel)}
                value={checkOutDate}
                min={submissionTime}
                max={currentDate}
                onChange={setCheckOutDate}
                disabled={busy}/>

      <RequestSpinner state={checkOutState} retryIsSubmit/>

      <CheckInButtonBox>
        <Button greyOnInvalid
                type="submit"
                variant="danger"
                icon="sign-out"
                iconType="regular"
                iconInline
                disabled={busy}>
          {intl.formatMessage(messages.CheckOut)}
        </Button>
      </CheckInButtonBox>
    </form>
  </>;
}

function CheckOutComplete() {
  const { methodKey } = useParams();
  const intl = useIntl();
  const firstName = useSelector(s => s.checkin.checkIns?.[methodKey]?.firstName);
  const lastName = useSelector(s => s.checkin.checkIns?.[methodKey]?.lastName);
  const mobile = useSelector(s => s.checkin.checkIns?.[methodKey]?.mobile);
  const siteName = useSelector(s => s.checkin.machineData?.siteName);
  const checkOutTime = useSelector(s => s.checkin.checkOutTime);

  return <>
    <Icon className={`${classes.StatusIcon} ${classes.CompleteIcon}`}
          type="duo" icon="check-circle"/>

    <section className={classes.StatusBanner}>
      <h1>Checked Out</h1>
      <p>You may need to show this confirmation to venue staff on exit.</p>
    </section>

    <dl className={classes.CompleteInfo}>
      <dt>{intl.formatMessage(messages.NameInfo)}</dt>
      <dd>{firstName} {lastName}</dd>
      <dt>{intl.formatMessage(messages.MobileInfo)}</dt>
      <dd>{mobile}</dd>
      <dt>{intl.formatMessage(messages.VenueInfo)}</dt>
      <dd>{siteName}</dd>
      <dt>{intl.formatMessage(messages.CheckedOutInfo)}</dt>
      <dd>{intl.formatDate(checkOutTime, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}</dd>
    </dl>

    <PulseBanner/>
  </>;
}

function isFailed(question, answer) {
  switch (question.type) {
    case 'yes,no':
      return /^fail$/i.test(question.answers?.[answer?.answer]?.action);
    case 'free text':
      return false;
    case 'select':
      return /^fail$/i.test(answer?.answer?.action);
    case 'multi-select':
      return answer?.answer?.some?.(a => /^fail$/i.test(a?.action));
    default:
      return false;
  }
}

export default function CheckIn() {
  const { methodKey } = useParams();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const isOnDetailsPage = !!matchPath(location.pathname, { path, exact: true });
  const detailsWereCompleted = useSelector(s => s.checkin.detailsWereCompleted);
  const machineId = useSelector(s => s.checkin.machineData?.machineId);
  const siteName = useSelector(s => s.checkin.machineData?.siteName);
  const useTemperature = useSelector(s => s.checkin.machineData?.useTemperature);
  const firstName = useSelector(s => s.checkin.userDetails?.firstName);
  const lastName = useSelector(s => s.checkin.userDetails?.lastName);
  const mobile = useSelector(s => s.checkin.userDetails?.mobile);
  const usersHaveRoles = useSelector(s => s.checkin.machineData?.usersHaveRoles);
  const questionsByUser = useSelector(s => s.checkin.machineData?.questionsByUser);
  const userDataState = useSelector(s => s.checkin.userDataState);
  const hasMultipleRoles = useSelector(s => s.checkin.roles?.length >= 2);
  const role = useRole();
  const questions = useQuestions();
  const answers = useSelector(s => s.checkin.answers);
  const submissionTime = useSelector(s => s.checkin.checkIns?.[methodKey]?.submissionTime);
  const invalidMethod = useSelector(s => s.checkin.invalidMethod);
  const checkOutToken = useSelector(s => s.checkin.checkIns?.[methodKey]?.checkOutToken);
  const checkOutState = useSelector(s => s.checkin.checkOutState);
  const checkOutTime = useSelector(s => s.checkin.checkOutTime);

  const mobileValid = useMemo(() => checkPhoneValidity(mobile), [mobile]);
  const detailsValid = !!(firstName && lastName && mobile && mobileValid)
  const detailsComplete = detailsValid && detailsWereCompleted;
  const questionsLoaded = !!questions && userDataState !== 'loading';
  const anyQuestions = !!questions?.length || hasMultipleRoles;
  const questionsFailed = questionsLoaded &&
                          questions.some(q => isFailed(q, answers[q.id]));
  const needsRole = hasMultipleRoles && !role;
  const questionsComplete = questionsLoaded && !questionsFailed &&
                            !questions.some(q => !answers[q.id]?.answer) &&
                            !needsRole;
  const submissionComplete = !!submissionTime;
  const checkOutComplete = !!checkOutTime;

  const pages = [
    {
      name: 'details',
      text: intl.formatMessage(messages.Details),
      link: url,
      complete: detailsComplete,
    },
    ...(anyQuestions ? [{
      name: 'questions',
      text: intl.formatMessage(messages.Questions),
      link: `${url}/questions`,
      complete: questionsComplete,
    }] : []),
    ...(useTemperature ? [{
      name: 'code',
      link: `${url}/code`,
      text: intl.formatMessage(messages.EnterCode),
    }] : []),
  ];

  useEffect(() => {
    document.body.classList.add('checkin');
    return () => document.body.classList.remove('checkin');
  }, []);

  useEffect(() => {
    dispatch(fetchMachineData({ methodKey }));
  }, [methodKey]);

  const needUserData = usersHaveRoles || questionsByUser;
  useEffect(() => {
    if (isOnDetailsPage || !machineId || !needUserData || !detailsComplete)
      return;
    dispatch(fetchUserData({ machineId, firstName, lastName, mobile }));
  }, [isOnDetailsPage, machineId, needUserData, firstName, lastName, mobile, detailsComplete]);

  function onCheckInFormComplete() {
    dispatch(completeDetails());
    history.push(`${url}/questions`);
  }

  function onQuestionsComplete() {
    history.push(`${url}/code`);
  }

  function onQuestionsBack() {
    history.push(url);
  }

  function onCodeBack() {
    if (anyQuestions) {
      history.push(`${url}/questions`);
    } else {
      history.push(url);
    }
  }

  function onStartAgain() {
    dispatch(startAgain({ methodKey }));
  }

  function onCompleteCheckOut() {
    history.push(`${url}/checkout`);
  }

  function onCheckOut({ checkOutDate: time, timeOffset, timeZoneOffset, timeZone }) {
    dispatch(checkOut({ methodKey, checkOutToken, time, timeOffset, timeZoneOffset, timeZone }));
  }

  function onCheckOutBack() {
    if (checkOutState !== 'started')
      history.push(`${url}/complete`);
  }

  let nav;
  if (detailsWereCompleted && pages.length > 1) {
    nav = page => <CheckInMenu pages={pages} currentPage={page}/>;
  } else {
    nav = () => <CheckInBanner siteName={siteName}/>;
  }

  const invalidRedirect = invalidMethod && <Redirect to={`${url}/invalid`}/>;
  const validRedirect = !invalidMethod && <Redirect to={url}/>;
  const completeRedirect = submissionComplete && <Redirect to={`${url}/complete`}/>;
  const incompleteRedirect = !submissionComplete && <Redirect to={url}/>;
  const detailsIncompleteRedirect = !detailsComplete && <Redirect to={url}/>;
  const questionsSpinner = !questionsLoaded && <Spinner active/>;
  const questionsRedirect = questionsLoaded && !anyQuestions && <Redirect to={`${url}/code`}/>;
  const questionsIncompleteRedirect = !questionsComplete && <Redirect to={`${url}/questions`}/>;
  const noCheckOutRedirect = !checkOutToken && <Redirect to={`${url}/complete`}/>;
  const checkedOutRedirect = checkOutComplete && <Redirect to={`${url}/checkedout`}/>;
  const notCheckedOutRedirect = !checkOutComplete && <Redirect to={`${url}/complete`}/>;

  return <div className={classes.CheckIn}>
    <Switch>
      <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)}/>
      <Route exact path={path}>
        {invalidRedirect || completeRedirect || <>
          <CheckInHeader/>
          {nav('details')}
          <CheckInForm detailsValid={detailsValid}
                       mobileValid={mobileValid}
                       onComplete={onCheckInFormComplete}/>
        </>}
      </Route>
      <Route path={`${path}/questions`}>
        {invalidRedirect || completeRedirect || detailsIncompleteRedirect || questionsSpinner || questionsRedirect || <>
          <CheckInHeader onBack={onQuestionsBack} backButton={intl.formatMessage(messages.Back)}/>
          {nav('questions')}
          <CheckInQuestions questionsFailed={questionsFailed}
                            questionsComplete={questionsComplete}
                            onComplete={onQuestionsComplete}/>
        </>}
      </Route>
      <Route path={`${path}/code`}>
        {invalidRedirect || completeRedirect || detailsIncompleteRedirect || questionsSpinner || questionsIncompleteRedirect || <>
          <CheckInHeader onBack={onCodeBack} backButton={intl.formatMessage(messages.Back)}/>
          {nav('code')}
          <CodeEntry/>
        </>}
      </Route>
      <Route path={`${path}/complete`}>
        {invalidRedirect || incompleteRedirect || checkedOutRedirect || <>
          <CheckInHeader onBack={onStartAgain} backButton={intl.formatMessage(messages.StartAgain)}/>
          <Complete onCheckOut={onCompleteCheckOut}/>
        </>}
      </Route>
      <Route path={`${path}/checkout`}>
        {invalidRedirect || checkedOutRedirect || noCheckOutRedirect || incompleteRedirect || <>
          <CheckInHeader onBack={onCheckOutBack} backButton={intl.formatMessage(messages.Back)}/>
          <CheckOut onCheckOut={onCheckOut}/>
        </>}
      </Route>
      <Route path={`${path}/checkedout`}>
        {invalidRedirect || notCheckedOutRedirect || <>
          <CheckInHeader onBack={onStartAgain} backButton={intl.formatMessage(messages.StartAgain)}/>
          <CheckOutComplete/>
        </>}
      </Route>
      <Route path={`${path}/invalid`}>
        {validRedirect || <>
          <CheckInHeader/>
          <InvalidMethod/>
        </>}
      </Route>
    </Switch>
  </div>;
}
