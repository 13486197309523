import React from "react";
import { connect } from "react-redux";

function Admin(props) {
  if (!props.admin) {
    return null;
  }

  return (
    <>
      {props.children}
    </>
  )
}

function mapStateToProps(state) {
  return { admin: state.auth.admin };
}

export default connect(
  mapStateToProps,
  null
)(Admin);