import React, {useState,useEffect} from 'react';
import classes from './BottomBar.module.css';
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUser, faKey, faUserTimes} from '@fortawesome/pro-duotone-svg-icons'
import {faTimes} from '@fortawesome/pro-regular-svg-icons'
import messages from "./BottomBar.messages";


function BottomBar(props) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(props.count);
  }, [props.count]);

  if (!props.count) {
    return null;
  }



  return (
      <div className={classes.BottomBar}>
        <div className={classes.Inside}>
          <div data-testid="bottomBar" className={classes.UserSelected}>
            {count}&nbsp;
            {props.intl.formatMessage(messages.UsersSelected)}
          </div>

          <div className={classes.block}>
            <FontAwesomeIcon className={classes.Icon} icon={faFileUser}/>
            {props.intl.formatMessage(messages.ChangeAccountType)}
          </div>

          <div className={classes.block}>
            <FontAwesomeIcon className={classes.Icon} icon={faKey}/>
            {props.intl.formatMessage(messages.ResetAccessPins)}
          </div>

          <div className={classes.block}>
            <FontAwesomeIcon className={classes.Icon} icon={faUserTimes}/>
            {props.intl.formatMessage(messages.ArchiveUsers)}
          </div>

          <div className={classes.Exit}>
            <FontAwesomeIcon className={classes.IconExit} icon={faTimes}/>
          </div>
        </div>
      </div>
  );
};
//[todo] npm run build
export default BottomBar;
