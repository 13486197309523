import { defineMessages } from "react-intl";

const messages = defineMessages({
  "Submit": {
    id: "Button.Submit",
    defaultMessage: "Submit"
  },
  "Cancel": {
    id: "Button.Cancel",
    defaultMessage: "Cancel"
  },
  "SaveChanges": {
    id: "Button.SaveChanges",
    defaultMessage: "Save Changes"
  },
  "ClearAll": {
    id: "Button.ClearAll",
    defaultMessage: "Clear All"
  },
  "Search": {
    id: "Button.Search",
    defaultMessage: "Search"
  },
  "SearchName": {
    id: "Button.SearchName",
    defaultMessage: "Search Name"
  },
  "Export": {
    id: "Button.Export",
    defaultMessage: "Export Selected"
  },
  "ExportAll": {
    id: "Button.ExportAll",
    defaultMessage: "Export All"
  },
  "NewUser": {
    id: "Button.NewUser",
    defaultMessage: "New User"
  },
  "Next": {
    id: "Button.Next",
    defaultMessage: "Next"
  },
  "ResetDefault": {
    id: "Button.ResetDefault",
    defaultMessage: "Reset to Default "
  },
  "OpenEAPIImage": {
    id: "Button.OpenEAPIImage",
    defaultMessage: "Open Larger Image "
  },
  "ResubmitRequest": {
    id: "Button.ResubmitRequest",
    defaultMessage: "Resubmit Request "
  },
})

export default messages;
