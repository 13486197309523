import React from "react";
import classes from "./Page.module.css";
import { useLocation } from "../utils/react";

export function Pages(props) {
  const location = useLocation();
  const children = React.Children.toArray(props.children);
  const currentPageIndex = location.state?.currentPageIndex ?? 0;
  const refreshCount = location.state?.refreshCount ?? 0;
  const currentChild = children[currentPageIndex];

  return (
    <div className={classes.Pages}>
      {currentChild && React.cloneElement(currentChild, {
        ...(location.state?.props ?? {}),
        key: refreshCount,
      })}
    </div>
  )
}

export default function Page(props) {
  return (
    <div className={classes.Page}>
      {props.header}
      <div className={classes.Content}>{props.content}</div>
      {props.footer ? props.footer : null}
    </div>
  )
}
