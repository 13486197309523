import {downloadCSV} from "../components/widgets/TableComponent/TableComponent";

function guid() {
  const u8 = new Uint8Array(16);
  crypto.getRandomValues(u8);
  u8[6] = (u8[6] & 0x0f) | 0x40;
  u8[8] = (u8[8] & 0x3f) | 0x80;
  return Array.from(u8).map(a => a.toString(16).padStart(2, '0')).join('')
    .replace(/(.{8})(.{4})(.{4})(.{4})/, '$1-$2-$3-$4-');
}

function createAbortError() {
  try {
    return new DOMException('Aborted', 'AbortError');
  } catch {
    const error = new Error('Aborted');
    error.name = 'AbortError';
    return error;
  }
}

function downloadCsv(data, args) {
  data.forEach(x => {
    delete x.total;
    delete x.user_id;
    delete x.image;
    delete x.image_t;
    delete x.log_id;
    delete x.external_api_results
    if (x.log_date) {
      let options = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
        timeZone: 'Australia/Sydney',
        timeZoneName: 'short'
      };
      let date = new Date(x.log_date);
      try {
        x.log_date = new Intl.DateTimeFormat('default', options).format(date);
      } catch(e) {
        console.error(e.message);
      }
    }
    x.question = x.question ? JSON.stringify(x.question) : "";
  })
  downloadCSV(data, args);
}

// Abortable setTimeout which returns a promise
function timeout(delay = 0, signal = null) {
  return new Promise((res, rej) => {
    let timeoutId = -1;

    function onAbort() {
      if (timeoutId >= 0) {
        clearTimeout(timeoutId);
        timeoutId = -1;
      }
      if (signal)
        signal.removeEventListener('abort', onAbort);
      rej(createAbortError());
    }

    if (signal && signal.aborted) {
      rej(createAbortError());
      return;
    }

    timeoutId = setTimeout(() => {
      timeoutId = -1;
      if (signal)
        signal.removeEventListener('abort', onAbort);

      if (signal && signal.aborted) {
        rej(createAbortError());
      } else {
        res();
      }
    }, delay);

    if (signal)
      signal.addEventListener('abort', onAbort);
  });
}

export { guid, timeout, downloadCsv };
