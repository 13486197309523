import { persistReducer, createTransform } from 'redux-persist';
import storage from 'localforage';

import {
  CHECKIN_UPDATE_FIRST_NAME,
  CHECKIN_UPDATE_LAST_NAME,
  CHECKIN_UPDATE_MOBILE,
  CHECKIN_UPDATE_DEPENDENTS,
  CHECKIN_COMPLETE_DETAILS,
  CHECKIN_UPDATE_ROLE,
  CHECKIN_INVALID_METHOD,
  CHECKIN_UPDATE_MACHINE_DATA,
  CHECKIN_FETCH_USER_DATA_STARTED,
  CHECKIN_FETCH_USER_DATA_FAILED,
  CHECKIN_FETCH_USER_DATA_COMPLETE,
  CHECKIN_ANSWER_QUESTION,
  CHECKIN_SUBMISSION_STARTED,
  CHECKIN_SUBMISSION_COMPLETE,
  CHECKIN_SUBMISSION_BAD_CODE,
  CHECKIN_SUBMISSION_FAILED,
  CHECKIN_CHECKOUT_STARTED,
  CHECKIN_CHECKOUT_COMPLETE,
  CHECKIN_CHECKOUT_FAILED,
  CHECKIN_START_AGAIN,
} from "../types";

const initialState = {
  userDetails: {
    firstName: null,
    lastName: null,
    mobile: null,
  },
  machineData: {
    machineId: null,
    siteName: null,
    useTemperature: false,
    usersHaveRoles: false,
    questionsByUser: false,
    questions: null,
    successMessage: '',
  },
  invalidMethod: false,
  roles: [],
  role: null,
  questions: null,
  userDataDetails: null,
  userDataState: 'not-loaded',
  detailsWereCompleted: false,
  dependentsChanged: false,
  dependents: 0,
  answers: {},
  submissionState: 'not-submitted',
  checkOutState: 'not-submitted',
  checkOutTime: null,
  checkIns: {},
};

function expireCheckIns(checkIns) {
  if (typeof checkIns !== 'object' || checkIns === null)
    return checkIns;

  const now = Date.now();
  return Object.fromEntries(Object.entries(checkIns).filter(([, checkIn]) => {
    if (checkIn.checkedOut)
      return false;
    if (!checkIn.submissionTime || +checkIn.submissionTime < now - 13 * 60 * 60 * 1000)
      return false;
    return true;
  }));
}

const ExpireCheckInsTransform = createTransform(
  (inboundState, key) => {
    if (key !== 'checkIns')
      return inboundState;
    return expireCheckIns(inboundState);
  },
  (outboundState, key) => {
    if (key !== 'checkIns')
      return outboundState;

    // Convert submissionTime to date
    if (typeof outboundState === 'object' || outboundState === null) {
      outboundState = Object.fromEntries(Object.entries(outboundState).map(([methodKey, checkIn]) => {
        if (typeof checkIn === 'object' && typeof checkIn?.submissionTime === 'string')
          checkIn = { ...checkIn, submissionTime: new Date(checkIn.submissionTime) };
        return [methodKey, checkIn];
      }));
    }

    return expireCheckIns(outboundState);
  });

export default persistReducer({
  key: 'checkInUser',
  storage,
  whitelist: ['userDetails', 'checkIns'],
  transforms: [ExpireCheckInsTransform],
}, function checkinReducer(state = initialState, action) {
  switch (action.type) {
    case CHECKIN_UPDATE_FIRST_NAME:
      state = updateFirstName(state, action);
      break;
    case CHECKIN_UPDATE_LAST_NAME:
      state = updateLastName(state, action);
      break;
    case CHECKIN_UPDATE_MOBILE:
      state = updateMobile(state, action);
      break;
    case CHECKIN_UPDATE_DEPENDENTS:
      state = updateDependents(state, action);
      break;
    case CHECKIN_COMPLETE_DETAILS:
      state = completeDetails(state, action);
      break;
    case CHECKIN_UPDATE_ROLE:
      state = updateRole(state, action);
      break;
    case CHECKIN_INVALID_METHOD:
      state = invalidMethod(state, action);
      break;
    case CHECKIN_UPDATE_MACHINE_DATA:
      state = updateMachineData(state, action);
      break;
    case CHECKIN_FETCH_USER_DATA_STARTED:
      state = fetchUserDataStarted(state, action);
      break;
    case CHECKIN_FETCH_USER_DATA_FAILED:
      state = fetchUserDataFailed(state, action);
      break;
    case CHECKIN_FETCH_USER_DATA_COMPLETE:
      state = fetchUserDataComplete(state, action);
      break;
    case CHECKIN_ANSWER_QUESTION:
      state = answerQuestion(state, action);
      break;
    case CHECKIN_SUBMISSION_STARTED:
      state = submissionStarted(state, action);
      break;
    case CHECKIN_SUBMISSION_COMPLETE:
      state = submissionComplete(state, action);
      break;
    case CHECKIN_SUBMISSION_BAD_CODE:
      state = submissionBadCode(state, action);
      break;
    case CHECKIN_SUBMISSION_FAILED:
      state = submissionFailed(state, action);
      break;
    case CHECKIN_CHECKOUT_STARTED:
      state = checkOutStarted(state, action);
      break;
    case CHECKIN_CHECKOUT_COMPLETE:
      state = checkOutComplete(state, action);
      break;
    case CHECKIN_CHECKOUT_FAILED:
      state = checkOutFailed(state, action);
      break;
    case CHECKIN_START_AGAIN:
      state = startAgain(state, action);
      break;
    default:
      break;
  }
  return state;
});

function updateFirstName(state, { payload: { firstName } }) {
  return {
    ...state,
    userDetails: {
      ...(state.userDetails ?? {}),
      firstName,
    }
  };
}

function updateLastName(state, { payload: { lastName } }) {
  return {
    ...state,
    userDetails: {
      ...(state.userDetails ?? {}),
      lastName,
    }
  };
}

function updateMobile(state, { payload: { mobile } }) {
  return {
    ...state,
    userDetails: {
      ...(state.userDetails ?? {}),
      mobile,
    }
  };
}

function updateDependents(state, { payload: { dependents } }) {
  return {
    ...state,
    dependents,
    dependentsChanged: true,
  };
}

function completeDetails(state) {
  return {
    ...state,
    detailsWereCompleted: true,
  };
}

function updateRole(state, { payload: { role } }) {
  return {
    ...state,
    role: role ?? null,
  };
}

function invalidMethod(state) {
  return {
    ...state,
    invalidMethod: true,
  };
}

function updateMachineData(state, { payload: { machineId, siteName, useTemperature, usersHaveRoles, questionsByUser, questions, successMessage, config } }) {
  return {
    ...state,
    questions: questionsByUser ? state.questions : null,
    machineData: {
      machineId,
      siteName,
      useTemperature,
      usersHaveRoles,
      questionsByUser,
      questions: questionsByUser ? null : questions,
      successMessage,
      config
    }
  };
}

function fetchUserDataStarted(state, { payload: { machineId, firstName, lastName, mobile } }) {
  return {
    ...state,
    userDataState: 'loading',
    userDataDetails: { machineId, firstName, lastName, mobile },
  };
}

function fetchUserDataFailed(state, { payload: { machineId, firstName, lastName, mobile } }) {
  // Ignore outdated responses
  if (state.userDataDetails?.machineId !== machineId
    || state.userDataDetails?.firstName !== firstName
    || state.userDataDetails?.lastName !== lastName
    || state.userDataDetails?.mobile !== mobile)
    return state;

  return {
    ...state,
    roles: [],
    questions: state.machineData?.questionsByUser ? [] : null,
    userDataDetails: null,
    userDataState: 'failed',
  };
}

function fetchUserDataComplete(state, { payload: { machineId, firstName, lastName, mobile, roles, questions } }) {
  // Ignore outdated responses
  if (state.userDataDetails?.machineId !== machineId
    || state.userDataDetails?.firstName !== firstName
    || state.userDataDetails?.lastName !== lastName
    || state.userDataDetails?.mobile !== mobile)
    return state;

  let role = state.role;
  if (role !== null && !roles?.some?.(r => r.id === role))
    role = null;

  return {
    ...state,
    role,
    roles,
    questions: state.machineData?.questionsByUser ? questions : null,
    userDataState: 'complete',
  };
}

function answerQuestion(state, { payload: { id, answer } }) {
  const questions = state.machineData?.questionsByUser ? state.questions : state.machineData.questions;

  return {
    ...state,
    answers: {
      ...state.answers,
      [id]: {
        ...(state.answers[id] ?? {}),
        question_text: questions?.find?.(q => q.id === id)?.questionText,
        answer,
      },
    },
  };
}

function submissionStarted(state) {
  return { ...state, submissionState: 'started' };
}

function submissionComplete(state, { payload: { firstName, lastName, mobile, methodKey, submissionTime, banned, highTemperature, lowTemperature, temperature, checkOutToken } }) {
  return {
    ...state,
    submissionState: 'complete',
    checkIns: {
      ...(state.checkIns ?? {}),
      [methodKey]: {
        firstName,
        lastName,
        mobile,
        submissionTime,
        banned,
        highTemperature,
        lowTemperature,
        temperature,
        checkOutToken,
      },
    },
  };
}

function submissionBadCode(state) {
  return { ...state, submissionState: 'bad-code' };
}

function submissionFailed(state) {
  return { ...state, submissionState: 'failed' };
}

function checkOutStarted(state) {
  return { ...state, checkOutState: 'started' };
}

function checkOutComplete(state, { payload: { methodKey, checkOutTime } }) {
  return {
    ...state,
    checkOutState: 'complete',
    checkOutTime,
    checkIns: {
      ...(state.checkIns ?? {}),
      [methodKey]: {
       ...(state.checkIns?.[methodKey] ?? {}),
        checkedOut: true,
      },
    },
  };
}

function checkOutFailed(state) {
  return { ...state, checkOutState: 'failed' };
}

function startAgain(state, { payload: { methodKey } }) {
  const checkIns = { ...(state.checkIns ?? {}) };
  delete checkIns[methodKey];

  return {
    ...initialState,
    checkIns, // Keep checkins from other QR codes
    machineData: state.machineData, // Keep machine data
  };
}
