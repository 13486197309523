import React from 'react';
import classes from './TableComponent.module.css';
import DataTable from 'react-data-table-component';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortDown} from '@fortawesome/pro-duotone-svg-icons'
import {faEllipsisV} from '@fortawesome/pro-light-svg-icons'
// import '../../../grid.css'

const data = [{
  id: 1,
  FullName: 'Andreanne Metz',
  Username: 'ametz',
  Sites: 'CK1',
  UserType: 'User',
  LastLogin: '04/12/2019 07:30AM',
  Status: 'Active',
}, {
  id: 2,
  FullName: 'Arnold Kihn',
  Username: 'akihn',
  Sites: 'CK1',
  UserType: 'User',
  LastLogin: '04/12/2019 07:30AM',
  Status: 'Active',
}, {
  id: 3,
  FullName: 'Astrid Feil',
  Username: 'afeil',
  Sites: 'CK1',
  UserType: 'User',
  LastLogin: '04/12/2019 07:30AM',
  Status: 'Active',
}, {
  id: 4,
  FullName: 'Darryl Crist',
  Username: 'dcrist',
  Sites: 'CK1',
  UserType: 'User',
  LastLogin: '04/12/2019 07:30AM',
  Status: 'Warning',
}, {
  id: 5,
  FullName: 'Eula Kutch',
  Username: 'ekutch',
  Sites: 'CK1',
  UserType: 'User',
  LastLogin: '04/12/2019 07:30AM',
  Status: 'Active',
}, {
  id: 6,
  FullName: 'Griffin Considine',
  Username: 'gconsidine',
  Sites: 'CK1',
  UserType: 'User',
  LastLogin: '04/12/2019 07:30AM',
  Status: 'Failure',
}, {
  id: 7,
  FullName: 'Jackson Salt',
  Username: 'JSALT',
  Sites: 'CK1',
  UserType: 'User',
  LastLogin: '04/12/2019 07:30AM',
  Status: 'Active',
}];

const customStyles = {
  title: {
    style: {
      defaultSortField: {
        opacity: 1,
      }
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#0D668D24',
       borderColor: '#DDDDDD',
      outline: '1px solid #FFFFFF',
    },
  },

};

const columns = [
  {
    cell: () =>
    <label className={classes.container}>
      <input type='checkbox'/>
      <span className={classes.checkmark}/>
    </label>,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
  {
    name: 'Full Name',
    selector: 'FullName',
    sortable: true,
    style: {
      rdt_TableBody: 'blue',
    },
  },
  {
    name: 'Username',
    selector: 'Username',
    sortable: true,
  },
  {
    name: 'Sites',
    selector: 'Sites',
    sortable: true,
  },
  {
    name: 'User Type',
    selector: 'UserType',
    sortable: true,
  },
  {
    name: 'Last Login',
    selector: 'LastLogin',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    cell: status =>
      <>
        <span className={status.Status}/>{status.Status}
      </>,
  },
  {
    cell: () => <FontAwesomeIcon className={classes.IconExit} icon={faEllipsisV}/>,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];

const tablecomponent = (props) => {

  return (
      <>
        <DataTable
            sortIcon={<FontAwesomeIcon className={classes.IconSolid} icon={faSortDown}/>}
            columns={columns}
            data={data}
            highlightOnHover={true}
            noHeader={true}
            customStyles={customStyles}
        />
      </>
  );
};

function convertArrayOfObjectsToCSV(args) {
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = Object.keys(data[0]);
  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  data.forEach(function(item) {
    ctr = 0;
    keys.forEach(function(key) {
      if (ctr > 0) result += columnDelimiter;
      if(item[key]) result += '"' + item[key].toString().replace(/"/g, '""') + '"';
      else result += '"' + item[key] + '"';
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(data, args) {
  // datas will change to data after backend is in
  let datas, filename, link;
  //Below will change to the currently filtered set.
  let csv = convertArrayOfObjectsToCSV({
    data: data
  });
  if (csv == null) return;

  filename = args.filename || 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  datas = encodeURI(csv);

  link = document.createElement('a');
  link.setAttribute('href', datas);
  link.setAttribute('download', filename);
  link.click();
}

export default tablecomponent;
