import React, {useState} from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

function Modal(props) {
  const [opened, setOpened] = useState(false);

  function onClose() {
    setOpened(false);
    props.onClose()
  }

  if (!props.show) {
    return null;
  } else {
    setTimeout(() => setOpened(true));
  }

  return ReactDOM.createPortal(
    <Overlay>
      <ModalContent {...props} opened={opened} onClose={onClose} />
    </Overlay>,
    document.querySelector("body")
  );
}

function ModalContent(props) {
  return (
    <div className={`${classes.Modal} ${props.opened ? classes.Opened : null} ${props.className ? props.className : null}`} style={props.style}>
      {props.header ? props.header : <ModalHeader {...props} />}
      {props.children ? props.children : null}
      {props.footer ? props.footer : <ModalFooter {...props} />}
    </div>
  )
}

function ModalHeader(props) {
  const {canClose = true} = props;

  return (
    <div className={classes.Header}>
      <h3 className={classes.HeaderTitle}>{props.title}</h3>
      <div className="flex"/>
      {canClose && <Icon icon="exit" size="lg" onClick={props.onClose} className={classes.CloseButton}/>}
    </div>
  )
}

function ModalFooter(props) {
  const {canClose = true} = props;

  return (
    <div className={classes.Footer}>
      <div className="flex"/>
      {canClose && <Button onClick={props.onClose}>Close</Button>}
    </div>
  )
}

function Overlay(props) {
  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999,
    backgroundColor: "rgba(0,0,0,0.4)"
  }

  return (
    <div style={style}>{props.children}</div>
  )
}

export function Section(props) {
  let style = {};
  style = Object.assign(style, props.style);

  let layout = "layout ";
  layout += (props.layout || "vertical");

  return (
    <section className={`${classes.Section} ${props.className ?? ''} ${props.flex ? '' : 'flex-none'} ${layout}`} style={style}>
      {props.children}
    </section>
  )
}

export function Body(props) {
  let style = {};
  style = Object.assign(style, props.style);

  return (
    <div className={`${classes.Body} ${props.className ?? ''}`} style={style}>
      {props.children}
    </div>
  )
}

export default Modal;
