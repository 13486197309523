import { defineMessages } from "react-intl";

const messages = defineMessages({
  "ChangePassword": {
    id: "UserAccount.ChangePassword",
    defaultMessage: "Change Password",
  },
  "TwoStepAuthentication": {
    id: "UserAccount.TwoStepAuthentication",
    defaultMessage: "Two-Step Authentication",
  },
  "LogOut": {
    id: "UserAccount.LogOut",
    defaultMessage: "Log Out"
  },
  "UpdateDetails": {
    id: "UserAccount.UpdateDetails",
    defaultMessage: "Update Profile"
  },
  "UpdateClient": {
    id: "UserAccount.UpdateClient",
    defaultMessage: "Client Details"
  },
  "SaveChanges": {
    id: "UserAccount.SaveChanges",
    defaultMessage: "Update Details"
  },
  "FirstName": {
    id: "UserAccount.FirstName",
    defaultMessage: "First Name"
  },
  "LastName": {
    id: "UserAccount.LastName",
    defaultMessage: "Last Name"
  },
  "PasswordMatch": {
    id: "UserAccount.PasswordMatch",
    defaultMessage: "Password does not match."
  },
  "PasswordCurrent": {
    id: "UserAccount.PasswordCurrent",
    defaultMessage: "Current Password"
  },
  "PasswordNew": {
    id: "UserAccount.PasswordNew",
    defaultMessage: "New Password"
  },
  "PasswordConfirm": {
    id: "UserAccount.PasswordConfirm",
    defaultMessage: "Confirm New Password"
  },
  "MessageVerification": {
    id: "UserAccount.MessageVerification",
    defaultMessage: "A verification code has been sent to the provided number."
  },
  "VerificationCode": {
    id: "UserAccount.VerificationCode",
    defaultMessage: "Verification Code"
  },
  "SendVerificationCode": {
    id: "UserAccount.SendVerificationCode",
    defaultMessage: "Send Verification Code"
  },
  "SuccessfullyUpdated": {
    id: "UserAccount.SuccessfullyUpdated",
    defaultMessage: "Successfully updated details"
  },
  "VerifyEmail": {
    id: "UserAccount.VerifyEmail",
    defaultMessage: "Please verify your email"
  },
  "PasswordUpdateError": {
    id: "UserAccount.PasswordUpdateError",
    defaultMessage: "There was a problem updating your password. Check that your current password is correct."
  },
  "PasswordUpdateSuccess": {
    id: "UserAccount.PasswordUpdateSuccess",
    defaultMessage: "Your password has been updated."
  },
  "Mobile": {
    id: "UserAccount.Mobile",
    defaultMessage: "Mobile Number"
  }
})

export default messages;
