import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

function PrivateRoute({ component: Component, ...rest}) {
  const authenticated = rest.authenticated;

  return <Route {...rest} render={rest.render || (props => (
      authenticated
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  ))} />
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated }
}

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);