import React from "react";
import classes from "./Button.module.css";
import Icon from "../Icon/Icon";
import {connect} from "react-redux";

function Button(props) {
  let style = {}

  if (props.block) {
    style.width = "100%";
  }

  let variantClass;

  switch (props.variant) {
    case "primary": variantClass = classes.Primary; break;
    case "success": variantClass = classes.Success; break;
    case "danger": variantClass = classes.Danger; break;
    case "warning": variantClass = classes.Warning; break;
    default: variantClass = "Primary"; break;
  }

  if (props.link) {
    style.backgroundColor = "transparent";
    style.color = "#002E41"
    style.textDecoration = "underline";
    style.padding = 0;
	}

	style = Object.assign(style, props.style);

  let className = classes.Button;
  if(!props.isBatbooth && !props.isPulse) className = classes.ButtonCoolgard;

  if (props.className) {
    className += " " + props.className;
  }

  function onClick() {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  }

  return (
    <button disabled={props.disabled} type={props.type || "button"} style={style} className={`${className} ${props.link ? classes.ButtonLink : ''} ${props.greyOnInvalid ? classes.GreyOnInvalid : ''} ${variantClass}`} title={props.title || props.children} onClick={onClick}>
      {props.children}
      {props.icon && !props.iconInline && <div className={classes.Flex}></div>}
      {props.icon && <Icon icon={props.icon} type={props.iconType} size="lg" />}
    </button>
  )
}
function mapStateToProps(state) {
  return {
    isBatbooth: state.locale.isBatbooth,
    isPulse: state.locale.isPulse
  }
}

const _button = connect(
    mapStateToProps,
    null)(Button);

export default _button;
