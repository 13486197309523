import { defineMessages } from "react-intl";

const messages = defineMessages({
  "Notifications": {
    id: "Notifications.Notifications",
    defaultMessage: "Notifications"
  },
  "None": {
    id: "Notifications.None",
    defaultMessage: "You have no notifications"
  }
})

export default messages;