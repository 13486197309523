import React from "react";
import { default as BootstrapDropdown } from "react-bootstrap/Dropdown";
import classes from "./Dropdown.module.css";
import {guid} from "../../../utils/helpers";
import Icon from "../Icon/Icon";

function Dropdown(props) {
  const items = (props.items || []).map(x => {
    return <DropdownItem key={guid()} {...x} onClick={() => {
      document.dispatchEvent(new MouseEvent("click"));
      x.onClick(props.value);
    }} />
  })

  return (
    <BootstrapDropdown className={classes.Dropdown}>
      <BootstrapDropdown.Toggle className={`${props.left ? classes.DropdownToggleLeft : classes.DropdownToggle}`} as={Trigger}>{props.toggle}</BootstrapDropdown.Toggle>
      <BootstrapDropdown.Menu className={classes.DropdownMenu}>
        {items}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  )
}

function DropdownItem(props) {
  return (
    <div className={classes.DropdownItem} onClick={props.onClick}>
      {props.icon && <div className={classes.DropdownItemIcon}>
        <Icon icon={props.icon} />
      </div>}
      <span>
        {props.text}
      </span>
    </div>
  )
}

const Trigger = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} {...props}>
      {props.children}
    </div>
  )
})

export default Dropdown;
