import React, {Suspense, useEffect, useState, useCallback} from 'react';
import classes from './Main.module.css';
import SideBar from '../components/widgets/SideBar/SideBar';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Spinner from "../components/widgets/Spinner/Spinner";
import ErrorBoundary from "../components/ErrorBoundary";
import { client as elev } from 'elevio';
import Elevio from 'elevio/lib/react';
import { Redirect } from "react-router-dom";

const Home = React.lazy(() => import('../page/Home/Home'));
const ClientManagement = React.lazy(() => import('../page/ClientManagement/ClientManagement'));
const UserManagement = React.lazy(() => import('../page/UserManagement/UserManagement'));
const CheckedInUsers = React.lazy(() => import('../page/UserManagement/CheckedInUsers'));
const MachineManagement = React.lazy(() => import('../page/MachineManagement/MachineManagement'));
const AccessLogs = React.lazy(() => import('../page/AccessLogs/AccessLogs'));
const SiteManagement = React.lazy(() => import('../page/SiteManagement/SiteManagement'));
const QuestionManagement = React.lazy(() => import('../page/QuestionManagement/QuestionManagement'));
const NotificationManagement = React.lazy(() => import('../page/NotificationManagement/NotificationManagement'));
const Example = React.lazy(() => import('../page/Example/Example'));
const QRTest = React.lazy(() => import('../page/QRTest/QRTest'));

const _Main = (props) => {
  const {isBatbooth} = props;
  const [hasElevio, setHasElevio] = useState(false);
  const [elevioOpen, setElevioOpen] = useState(false);

  function onElevioReady() {
    setHasElevio(true);
  }

  function onElevioWidgetOpened() {
    setElevioOpen(true);
  }

  function onElevioWidgetClosed() {
    setElevioOpen(false);
  }

  function onElevioClick() {
    if (elevioOpen) {
      elev.close();
    } else {
      elev.open();
    }
  }

  const onElevioLoad = useCallback(function onElevioLoad() {
    elev.on('ready', onElevioReady);
    elev.on('widget:opened', onElevioWidgetOpened);
    elev.on('widget:closed', onElevioWidgetClosed);
    elev.setSettings({
      hideLauncher: true,
    });
  });

  // Device users and Guest users should not have access to any portal pages.
  if (props.access.every(x => x.user_type === "Device" || x.user_type === "Guest"))
  return (
      <Redirect to="/board" />
  )

  let Page = null;
  switch (props.match.url) {
    case "/home":
      Page = Home;
      break;
    case "/client_management":
      Page = ClientManagement;
      break;
    case "/user_management":
      Page = UserManagement;
      break;
    case "/checked_in_users":
      Page = CheckedInUsers;
      break;
    case "/machine_management":
      Page = MachineManagement;
      break;
    case "/access_log":
      Page = AccessLogs;
      break;
    case "/site_management":
      Page = SiteManagement;
      break;
    case "/question_management":
      Page = QuestionManagement;
      break;
    case "/notification_management":
      Page = NotificationManagement;
      break;
    case "/example":
      Page = Example;
      break;
    case "/qr_test":
      Page = QRTest;
      break;
  }

  return (
    <div className={classes.Main}>
      <SideBar hasElevio={hasElevio}
               elevioOpen={elevioOpen}
               onElevioClick={onElevioClick}
               {...props}/>
      <Suspense fallback={<Spinner active />}>
        <ErrorBoundary fallback={<div/>}>
          <Page className={classes.Page} {...props} />
        </ErrorBoundary>
      </Suspense>
      {!isBatbooth && <Elevio accountId="60205fe34037b" onLoad={onElevioLoad}/>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isBatbooth: state.locale.isBatbooth,
    isPulse: state.locale.isPulse,
    access: state.auth.access,
  }
}

const Main = connect(
  mapStateToProps,
  null
)(_Main);

export default injectIntl(Main);
