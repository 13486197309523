import React, {useEffect, useState} from 'react';
import classes from './SideBar.module.css';
import MenuItem from './MenuItem'
import MidecoLogo from '../../../assets/images/Mideco.svg'
import CGLogo from '../../../assets/images/cg.svg'
import PresenceLogo from '../../../assets/images/Presence.svg'

import Tooltip from "../Tooltip/Tooltip";
import Notifications from "../../Notifications";
import UserAccount from "../../UserAccount";
import {connect} from "react-redux";
import {clearNotifications} from "../../../redux/actions";
import {guid} from "../../../utils/helpers";
import messages from "./SideBar.messages";
import Admin from "../../Admin";
import {Popup, PopupHeader, PopupItem} from "./Popup";
import SiteAdmin from "../../SiteAdmin";

const _sideBar = connect(
    mapStateToProps,
    null)(SideBar);

function SideBar(props) {
  const [currentPage, setCurrentPage] = useState(props.match.url);
  let img = <div></div>
  let sidebarBackground = {background: "#004867 0% 0% no-repeat padding-box"}
  let sidebarLogo = {background: "#003D58"}
  let sidebarMenu = {background: "#004867"}

  useEffect(() => {
    setCurrentPage(props.match.url);
  }, [props.location]);

  if (props.isBatbooth) {
    img = <img className={classes.Icon} src={MidecoLogo} alt="Mideco Logo"/>
    sidebarBackground = {background: "#004867 0% 0% no-repeat padding-box"}
    sidebarLogo = {background: "#003D58"}
    sidebarMenu = {background: "#004867"}

  } else if (props.isPulse) {
      img = <img className={classes.PulseIcon} src={PresenceLogo} alt="Pulse Presence Logo" style={{}}/>;
      sidebarBackground = {background: "#004867 0% 0% no-repeat padding-box"}
      sidebarLogo = {background: "#003D58"}
      sidebarMenu = {background: "#004867"}
  } else {
    img = <img className={classes.Icon} src={CGLogo} alt="CoolGard Logo" style={{}}/>;
    sidebarBackground = {background: "#00686B 0% 0% no-repeat padding-box"}
    sidebarLogo = {background: "#005457"}
    sidebarMenu = {background: "#00686B"}
  }

  function onClick(e) {
    let page = "/" + e;
    setCurrentPage(page);
    props.history.push(page);
  }

  return (
      <div className={classes.SideBar} style={sidebarBackground}>
        <div className={classes.Logo} style={sidebarLogo}>
          {img}
        </div>
        <div className={classes.Menu} style={sidebarMenu}>
          <MenuItems {...props} onClick={onClick} currentPage={currentPage}/>
        </div>
        <div className={classes.Config} style={sidebarLogo}>
          <ConfigItems {...props} onClick={onClick} currentPage={currentPage}/>
        </div>
      </div>
  );
}

function MenuItems(props) {
  const {intl, onClick, currentPage} = props;
  return (
      <>
        <MenuItem
            icon="home"
            selected={currentPage === "/home"}
            page="home"
            onClick={onClick}
            text={intl.formatMessage(messages.Home)}
        />
        <div className={classes.Separator}/>
        <MenuItem
            icon="site"
            page="site_management"
            selected={currentPage === "/site_management"}
            onClick={onClick}
            text={intl.formatMessage(messages.SiteManagement)}
        />
        <div className={classes.Separator}/>
        <MenuItem
            icon="machines"
            page="machine_management"
            selected={currentPage === "/machine_management"}
            onClick={onClick}
            text={intl.formatMessage(messages.MachineManagement)}
        />
        <div className={classes.Separator}/>
        <MenuItem
            icon="users"
            page="user_management"
            selected={currentPage === "/user_management"}
            onClick={onClick}
            text={intl.formatMessage(messages.UserManagement)}
        />
        <div className={classes.Separator}/>
        {!props.isBatbooth &&
          <>
            <MenuItem
            icon="user-clock"
            page="checked_in_users"
            selected={currentPage === "/checked_in_users"}
            onClick={onClick}
            text="Checked In Users" />
            <div className={classes.Separator}/>
          </>
        }

        <MenuItem
            icon="logs"
            page="access_log"
            selected={currentPage === "/access_log"}
            onClick={onClick}
            text={intl.formatMessage(messages.AccessLogs)}
        />
        <div className={classes.Separator}/>
      </>
  )
}

function _ConfigItems(props) {
  const { intl, hasElevio, elevioOpen, onElevioClick = () => {} } = props;
  const [key, setKey] = useState(guid());

  function refresh() {
    setKey(guid());
  }

  return (
      <>
        <Tooltip content={<Notifications key={key} {...props} />} placement="right" interactive onShow={refresh}>
          <MenuItem className={classes.MenuItemContainer} icon="bell" badge="yes" badgeCount={props.notifications.length}/>
        </Tooltip>

        <SiteAdmin>
          <div className={classes.ConfigSeparator}/>
          <Tooltip content={
            <Popup>
              <PopupHeader>
                <span>{intl.formatMessage(messages.Configuration)}</span>
                <div className={classes.Flex}></div>
              </PopupHeader>
              <Admin>
                <PopupItem icon="building"
                           page="client_management"
                           selected={props.currentPage === "/client_management"}
                           onClick={props.onClick}
                           text={intl.formatMessage(messages.ClientManagement)}/>
                <PopupItem icon="question"
                           page="question_management"
                           selected={props.currentPage === "/question_management"}
                           onClick={props.onClick}
                           text={intl.formatMessage(messages.QuestionManagement)}/>
              </Admin>
              <PopupItem icon="tabletAndroid"
                         page="notification_management"
                         selected={props.currentPage === "/notification_management"}
                         onClick={props.onClick}
                         text={intl.formatMessage(messages.NotificationManagement)}/>
            </Popup>

          } placement="right-start" interactive>
            <MenuItem className={classes.MenuItemContainer} icon="config"/>
          </Tooltip>
        </SiteAdmin>
        {hasElevio && <>
          <div className={classes.ConfigSeparator}/>
          <MenuItem
            icon="question-circle"
            page="user_management"
            selected={elevioOpen}
            onClick={onElevioClick}
            text={intl.formatMessage(messages.GetHelp)}
          />
        </>}
        <div className={classes.ConfigSeparator}/>
        <Tooltip content={<UserAccount {...props} />} placement="right-start" interactive>
          <MenuItem className={classes.MenuItemContainer} icon="user"/>
        </Tooltip>
      </>
  )
}

function mapStateToProps(state) {
  return {notifications: state.notifications, isBatbooth: state.locale.isBatbooth, isPulse: state.locale.isPulse}
}

const ConfigItems = connect(
    mapStateToProps,
    {clearNotifications}
)(_ConfigItems);

export default _sideBar
