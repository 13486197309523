import React from 'react';
import classes from './LowPressureWarning.module.css';
import {FormattedMessage} from "react-intl";

const lowPressureWarning = (props) => {

  return (
      <div style={panelStyle}>

        <div className={classes.LowPressureWarning}>
          <span className={classes.Dot}/>
          <FormattedMessage
              id="LowPressureWarning.LowPressureWarning"
              defaultMessage="Low Pressure Warning"/>
        </div>
        <div className={classes.midBorder}></div>
        <div className={classes.Message}>
          <FormattedMessage
              id="LowPressureWarning.Message"
              defaultMessage="- Filter Cartridge may require replacement"/>
        </div>
      </div>
  );
};

const panelStyle = {
  width: "17.5625rem",
  height: "auto",
  fontWeight: "600",
  background: "#FFFFFF 0 0 no-repeat padding-box",
  boxShadow: "0 0.1875rem 0.375rem #00000029",
  border: "1px solid #DDDDDD",
  opacity: "1",
  textAlign: "left",
  margin: "2rem",
};


export default lowPressureWarning;
