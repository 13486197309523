import store from "../redux/store";
import { logoutUser } from "../redux/actions";
const ajax = {};

export function getNodeHost() {
	let protocol = process.env.NODE_ENV === "production" ? "https" : "http";
	let host = (process.env.REACT_APP_NODE_HOST || "localhost") + (
		parseInt(process.env.REACT_APP_NODE_PORT) ? ":" + process.env.REACT_APP_NODE_PORT : ":50000"
	);
	return protocol + "://" + host;
}

export function getApiUrl(route) {
	if (process.env.NODE_ENV === "production") {
		return "/api" + route;
	} else {
		return getNodeHost() + "/api" + route;
	}
}

async function fetch(method, route, body, signal) {
	let headers = {};
	const token = store.getState().auth.token;
	if (token)
		headers.Authorization = `Bearer ${token}`;
	let init = {
    method: method,
    headers: headers,
    credentials: "include",
	}
	if (signal) {
		init.signal = signal;
	}
	if (method !== "GET" && body) {
		headers["Content-Type"] = "application/json; charset=utf-8";
		init.body = typeof body === "string" ? body : JSON.stringify(body);
	}
	const path = getApiUrl(route);
	let req = window.fetch(path, init);
	let response = await req;
	try {
		if (/^application\/json(?:;.*)?$/i.test(response.headers.get('Content-Type'))) {
			let json = await response.json().catch(e => {
				console.error(e);
			});
			return response.ok ? (json || {}) : await Promise.reject(json);
		} else {
			let blob = await response.blob().catch(e => {
				console.error(e);
			});
			return response.ok ? (blob || {}) : await Promise.reject({ blob });
		}
	} catch (e) {
		return handleError(e);
	}
}

function handleError(e) {
	if (e.status === 401) {
		store.dispatch(logoutUser());
	}
	throw e;
}

ajax.post = async function(route, body, signal) {
  return await fetch("POST", route, body, signal);
}

ajax.get = async function(route, signal) {
  return await fetch("GET", route, null, signal);
}

ajax.put = async function(route, body, signal) {
  return await fetch("PUT", route, body, signal);
}

ajax.delete = async function(route, body, signal) {
  return await fetch("DELETE", route, body, signal);
}

ajax.patch = async function(route, body, signal) {
	return await fetch("PATCH", route, body, signal);
}

export default ajax;
