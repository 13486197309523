import React, {useEffect, useState} from 'react';
import classes from './ManagementBar.module.css';
import {FormattedMessage} from "react-intl";
import Select from "../Select/Select"


function ManagementBar(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  let filter1Set = new Set();
  let filter2Set = new Set();
  let filter3Set = new Set();

  filter1Set.add('');
  filter2Set.add('');
  filter3Set.add('');

  for (let i = 0; i < data.length; i++) {
    filter1Set.add(data[i].Sites);
    filter2Set.add(data[i].UserType);
    filter3Set.add(data[i].Status);
  }

  const sitesOption = Array.from(filter1Set).map(site => <option value={site}>{site}</option>);
  const userTypeOption = Array.from(filter2Set).map(userType => <option value={userType}>{userType}</option>);
  const statusOption = Array.from(filter3Set).map(status => <option value={status}>{status}</option>);

  useEffect(() => {
    setData(props.data);
  },);


  function showModal() {
    setShow(true);
  }

  function closeModal() {
    setShow(false);
  }

  let filter1Component, filter2Component, filter3Component = <></>;

  if (props.filter1) {
    filter1Component = (
        <div className={classes.block}>
          <FormattedMessage
              id="ManagementBar.Sites"
              defaultMessage="Sites:  "/>
          <Select clear onChange={e => props.filter1(e)}>
            {sitesOption}
          </Select>
          {/*<FontAwesomeIcon className={classes.Icon} icon={faChevronDown}/>*/}
        </div>);
  } else {
    filter1Component = (<></>);
  }

  if (props.filter2) {
    filter2Component = (
        <div className={classes.block}>
          <FormattedMessage
              id="ManagementBar.UserType"
              defaultMessage="User Type:  "/>
          <Select clear onChange={e => props.filter2(e)}>
            {userTypeOption}
          </Select>
        </div>);
  } else {
    filter2Component = (<></>);
  }

  if (props.filter3) {
    filter3Component = (
        <div className={classes.block}>
          <FormattedMessage
              id="ManagementBar.Status"
              defaultMessage="Status:  "/>
          <Select clear onChange={e => props.filter3(e)}>
            {statusOption}
          </Select>
        </div>);
  } else {
    filter3Component = (<></>);
  }

  return (
      <div>
        <div className={classes.UserManagementBar}>
          <div className={classes.Inside}>
            {filter1Component}
            {filter2Component}
            {filter3Component}
          </div>
        </div>
      </div>
  );
}

export default ManagementBar;
