import React from 'react';
import classes from './Dot.module.css';

function Dot(props) {
  let style = {};
  if (props.variant === "danger") {
    style.backgroundColor = "#D14040";
  } else if (props.variant === "warning") {
    style.backgroundColor = "#F7AC1E";
  } else {
    style.backgroundColor = "#80D835";
  }

  return (
    <div style={style} className={classes.Dot} />
  )
}

export default Dot;
