import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import classes from './DateTime.module.css';
import {useHtmlId} from "../../../utils/react";

function toDateString(date) {
  date = new Date(date);
  const invalidDate = Number.isNaN(date.valueOf());
  return invalidDate ? '' : `${
    date.getFullYear().toString().padStart(4, '0')
  }-${
    (date.getMonth() + 1).toString().padStart(2, '0')
  }-${
    date.getDate().toString().padStart(2, '0')
  }`;
}

function toTimeString(date) {
  date = new Date(date);
  const invalidDate = Number.isNaN(date.valueOf());
  return invalidDate ? '' : `${
    date.getHours().toString().padStart(2, '0')
  }:${
    date.getMinutes().toString().padStart(2, '0')
  }`;
}

function equalDate(date1, date2) {
  date1 = new Date(date1);
  date2 = new Date(date2);
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
}

export default forwardRef(function DateTime(props, ref) {
  const { value, required, label, min, max, onChange = () => {}, className, disabled } = props;
  const dateId = useHtmlId();
  const dateRef = useRef();
  const timeRef = useRef();
  const minDate = new Date(min ?? undefined);
  const minDateValid = !Number.isNaN(minDate.valueOf());
  const maxDate = new Date(max ?? undefined);
  const maxDateValid = !Number.isNaN(maxDate.valueOf());
  const date = new Date(value ?? undefined);
  const dateValid = !Number.isNaN(date.valueOf());
  const [immediateDateValue, setImmediateDateValue] = useState('');
  const validDateValue = toDateString(date);
  const dateValue = validDateValue || immediateDateValue;
  const [immediateTimeValue, setImmediateTimeValue] = useState('');
  const validTimeValue = toTimeString(date);
  const timeValue = validTimeValue || immediateTimeValue;
  const dateConstraints = {};
  const timeConstraints = {};

  useImperativeHandle(ref, () => ({
    focus() {
      dateRef.current.focus();
    },
  }));

  if (minDateValid) {
    dateConstraints.min = toDateString(minDate);
    if ((dateValid && equalDate(date, minDate)) || (maxDateValid && equalDate(minDate, maxDate))) {
      timeConstraints.min = toTimeString(minDate);
      timeConstraints.max = timeConstraints.max || '11:59';
    }
  }
  if (maxDateValid) {
    dateConstraints.max = toDateString(maxDate);
    if ((dateValid && equalDate(date, maxDate)) || (minDateValid && equalDate(minDate, maxDate))) {
      timeConstraints.min = timeConstraints.min || '00:00';
      timeConstraints.max = toTimeString(maxDate);
    }
  }

  function applyConstraints(date) {
    date = new Date(date);
    if (minDateValid && date.valueOf() < minDate.valueOf())
      return minDate;
    if (maxDateValid && date.valueOf() > maxDate.valueOf())
      return maxDate;
    return date;
  }

  function onDateChange({ target: {value: newDateValue} }) {
    // Ignore spurious changes
    if (newDateValue === dateValue)
      return;

    // The HTML5 <input type="date"> should produce valid dates in this format
    if (/^[0-9]{4,}-[0-9]{2}-[0-9]{2}$/.test(newDateValue)) {
      // Try to construct a Date value from the ISO date/time. If the time is
      // invalid, use 12:00 noon.
      const newDate = new Date(`${newDateValue}T${validTimeValue || '12:00'}:00`);
      if (!Number.isNaN(newDate.valueOf())) {
        const constrainedDate = applyConstraints(newDate);
        setImmediateDateValue('');
        setImmediateTimeValue('');
        if (constrainedDate.valueOf() !== date.valueOf())
          onChange(constrainedDate);
        return;
      }
    }

    // If a valid date could not be produced, store the immediate date/time
    // values for editing and notify the parent of the change
    setImmediateDateValue(newDateValue);
    setImmediateTimeValue(timeValue);
    if (value !== null)
      onChange(null);
  }

  function onTimeChange({ target: {value: newTimeValue} }) {
    // Ignore spurious changes
    if (newTimeValue === timeValue)
      return;

    // The HTML5 <input type="time"> should produce valid times in this format
    if (/^[0-9]{2}:[0-9]{2}$/.test(newTimeValue)) {
      // Try to construct a Date value from the ISO date/time. If the date is
      // invalid, use the current date.
      const newDate = new Date(`${validDateValue || toDateString(new Date())}T${newTimeValue}:00`);
      if (!Number.isNaN(newDate.valueOf())) {
        const constrainedDate = applyConstraints(newDate);
        setImmediateDateValue('');
        setImmediateTimeValue('');
        if (constrainedDate.valueOf() !== date.valueOf())
          onChange(constrainedDate);
        return;
      }
    }

    // If a valid date could not be produced, store the immediate date/time
    // values for editing and notify the parent of the change
    setImmediateDateValue(dateValue);
    setImmediateTimeValue(newTimeValue);
    if (value !== null)
      onChange(null);
  }

  return <div className={`${classes.DateTime} ${className || ''}`}>
    {label && <label htmlFor={dateId}>{label}</label>}
    <div className={classes.DateTimeWrapper}>
      <input ref={dateRef}
             id={dateId}
             type="date"
             value={dateValue}
             onChange={onDateChange}
             required={required}
             disabled={disabled}
             {...dateConstraints}/>
      <input ref={timeRef}
             type="time"
             value={timeValue}
             onChange={onTimeChange}
             required={required}
             disabled={disabled}
             {...timeConstraints}/>
    </div>
  </div>;
});
