import {
  CHECKIN_INVALID_METHOD,
  CHECKIN_UPDATE_MACHINE_DATA,
  CHECKIN_FETCH_USER_DATA_STARTED,
  CHECKIN_FETCH_USER_DATA_FAILED,
  CHECKIN_FETCH_USER_DATA_COMPLETE,
  CHECKIN_UPDATE_FIRST_NAME,
  CHECKIN_UPDATE_LAST_NAME,
  CHECKIN_UPDATE_MOBILE,
  CHECKIN_UPDATE_DEPENDENTS,
  CHECKIN_COMPLETE_DETAILS,
  CHECKIN_UPDATE_ROLE,
  CHECKIN_ANSWER_QUESTION,
  CHECKIN_SUBMISSION_STARTED,
  CHECKIN_SUBMISSION_COMPLETE,
  CHECKIN_SUBMISSION_BAD_CODE,
  CHECKIN_SUBMISSION_FAILED,
  CHECKIN_CHECKOUT_STARTED,
  CHECKIN_CHECKOUT_COMPLETE,
  CHECKIN_CHECKOUT_FAILED,
  CHECKIN_START_AGAIN,
} from "./types";

import ajax from "../utils/ajax";

function normalizeUserInput(str) {
  str = str.replace(/^\s|\s$/g, '').replace(/\s+/g, ' ');
  if (str.normalize)
    str = str.normalize('NFC');
  return str;
}

export function fetchMachineData({ methodKey }) {
  return async function(dispatch) {
    try {
      const { data: {
        id,
        siteName,
        useTemperature,
        usersHaveRoles,
        questionsByUser,
        questions,
        successMessage,
        config,
      } } =
        await ajax.get("/checkin/machine-data?method=" + encodeURIComponent(methodKey));
      dispatch(updateMachineData({
        machineId: id,
        siteName,
        useTemperature,
        usersHaveRoles,
        questionsByUser,
        questions,
        successMessage,
        config,
      }));
      console.log('machine-data -> region:', config);
      window._config = config || { region: "AU" };
      
    } catch (e) {
      console.error(e);
      dispatch(invalidMethod());
    }
  };
}

export function fetchUserData({ machineId, firstName, lastName, mobile }) {
  return async function(dispatch, getState) {
    // Don't fetch user data if the user details haven't changed
    const state = getState();
    if (state.checkin.userDataDetails
      && (state.questions || !state.machineData?.questionsByUser)
      && state.checkin.userDataDetails?.machineId === machineId
      && state.checkin.userDataDetails?.firstName === firstName
      && state.checkin.userDataDetails?.lastName === lastName
      && state.checkin.userDataDetails?.mobile === mobile)
      return;

    dispatch(fetchUserDataStarted({ machineId, firstName, lastName, mobile }));
    try {
      const { data: {
        roles,
        questions,
      } } =
        await ajax.get(
          `/checkin/user-data?id=${encodeURIComponent(machineId)
          }&first-name=${encodeURIComponent(normalizeUserInput(firstName))
          }&last-name=${encodeURIComponent(normalizeUserInput(lastName))
          }&mobile=${encodeURIComponent(normalizeUserInput(mobile))}`);
      dispatch(fetchUserDataComplete({ machineId, firstName, lastName, mobile, roles, questions }));
    } catch (e) {
      console.error(e);
      dispatch(fetchUserDataFailed({ machineId, firstName, lastName, mobile }));
    }
  };
}

export function invalidMethod() {
  return {
    type: CHECKIN_INVALID_METHOD,
    payload: {},
  };
}

export function updateMachineData({ machineId, siteName, useTemperature, usersHaveRoles, questionsByUser, questions, successMessage, config }) {
  return {
    type: CHECKIN_UPDATE_MACHINE_DATA,
    payload: { machineId, siteName, useTemperature, usersHaveRoles, questionsByUser, questions, successMessage, config },
  };
}

export function fetchUserDataStarted({ machineId, firstName, lastName, mobile }) {
  return {
    type: CHECKIN_FETCH_USER_DATA_STARTED,
    payload: { machineId, firstName, lastName, mobile },
  };
}

export function fetchUserDataFailed({ machineId, firstName, lastName, mobile }) {
  return {
    type: CHECKIN_FETCH_USER_DATA_FAILED,
    payload: { machineId, firstName, lastName, mobile },
  };
}

export function fetchUserDataComplete({ machineId, firstName, lastName, mobile, roles, questions }) {
  return {
    type: CHECKIN_FETCH_USER_DATA_COMPLETE,
    payload: { machineId, firstName, lastName, mobile, roles, questions },
  };
}

export function updateFirstName({ firstName }) {
  return {
    type: CHECKIN_UPDATE_FIRST_NAME,
    payload: { firstName },
  };
}

export function updateLastName({ lastName }) {
  return {
    type: CHECKIN_UPDATE_LAST_NAME,
    payload: { lastName },
  };
}

export function updateMobile({ mobile }) {
  return {
    type: CHECKIN_UPDATE_MOBILE,
    payload: { mobile },
  };
}

export function updateDependents({ dependents }) {
  return {
    type: CHECKIN_UPDATE_DEPENDENTS,
    payload: { dependents },
  };
}

export function completeDetails() {
  return {
    type: CHECKIN_COMPLETE_DETAILS,
    payload: {},
  };
}

export function updateRole({ role }) {
  return {
    type: CHECKIN_UPDATE_ROLE,
    payload: { role },
  };
}

export function answerQuestion({ id, answer }) {
  return {
    type: CHECKIN_ANSWER_QUESTION,
    payload: { id, answer },
  };
}

export function submitDetails({ methodKey, machineId, firstName, lastName, mobile, dependents, answers, role, verificationCode }) {
  return async function(dispatch) {
    try {
      dispatch(submissionStarted());
      let { data: {
        invalidCode,
        submissionTime,
        banned,
        highTemperature,
        lowTemperature,
        temperature,
        checkOutToken,
      } } = await ajax.post("/checkin", {
        firstName: normalizeUserInput(firstName),
        lastName: normalizeUserInput(lastName),
        mobile: normalizeUserInput(mobile),
        machineId,
        dependents,
        questions: answers,
        role,
        verificationCode,
      });
      if (invalidCode) {
        dispatch(submissionBadCode());
      } else {
        dispatch(submissionComplete({
          firstName,
          lastName,
          mobile,
          methodKey,
          submissionTime: new Date(submissionTime),
          banned,
          highTemperature,
          lowTemperature,
          temperature,
          checkOutToken,
        }));
      }
    } catch (e) {
      dispatch(submissionFailed());
    }
  };
}

export function submissionStarted() {
  return {
    type: CHECKIN_SUBMISSION_STARTED,
    payload: {},
  };
}

export function submissionComplete({ firstName, lastName, mobile, methodKey, submissionTime, banned, highTemperature, lowTemperature, temperature, checkOutToken }) {
  return {
    type: CHECKIN_SUBMISSION_COMPLETE,
    payload: { firstName, lastName, mobile, methodKey, submissionTime, banned, highTemperature, lowTemperature, temperature, checkOutToken },
  };
}

export function submissionBadCode() {
  return {
    type: CHECKIN_SUBMISSION_BAD_CODE,
    payload: {},
  };
}

export function submissionFailed() {
  return {
    type: CHECKIN_SUBMISSION_FAILED,
    payload: {},
  };
}

export function checkOut({ methodKey, checkOutToken, time, timeOffset, timeZoneOffset, timeZone }) {
  return async function(dispatch) {
    try {
      dispatch(checkOutStarted());
      let { data: { time: realTime } } = await ajax.post("/checkin/checkout", {
        checkOutToken,
        time,
        timeOffset,
        timeZoneOffset,
        timeZone,
      });
      dispatch(checkOutComplete({ methodKey, checkOutTime: new Date(realTime) }));
    } catch (e) {
      dispatch(checkOutFailed());
    }
  };
}

export function checkOutStarted() {
  return {
    type: CHECKIN_CHECKOUT_STARTED,
    payload: {},
  };
}

export function checkOutComplete({ methodKey, checkOutTime }) {
  return {
    type: CHECKIN_CHECKOUT_COMPLETE,
    payload: { methodKey, checkOutTime },
  };
}

export function checkOutFailed() {
  return {
    type: CHECKIN_CHECKOUT_FAILED,
    payload: {},
  };
}

export function startAgain({ methodKey }) {
  return {
    type: CHECKIN_START_AGAIN,
    payload: { methodKey },
  };
}
