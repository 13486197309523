import React from 'react';
import classes from './MenuItem.module.css';
import Icon from '../Icon/Icon';
import {connect} from "react-redux";

const MenuItem = React.forwardRef( (props, ref) => {
  let menuHover = { background: "#1A7196" }

  if(props.isBatbooth || props.isPulse){
    menuHover = { background: "#1A7196" }
  }
  else {
    menuHover = { background: "#038F93" }
  }

  function onClick() {
    props.onClick(props.page);
  }

  return (
    <div className={props.isBatbooth || props.isPulse ? `${classes.MenuItemBatbooth} ${props.selected ? classes.SelectedBatbooth : null}` :
        `${classes.MenuItemCoolgard} ${props.selected ? classes.SelectedCoolgard : null}`} onClick={props.onClick ? onClick : null} >
      <MenuIcon {...props} />
      {props.text && <div className={classes.TextContent} style={menuHover}>
        <span style={menuHover}>{props.text}</span>
      </div>}
    </div>
  );
})

function MenuIcon(props) {
  return (
    <div className={classes.Icon} >
      <div className={classes.Container}>
        {props.badge && <div className={classes.Badge}>{props.badgeCount || 0}</div>}
        <Icon icon={props.icon} badge={props.badge} style={{fontSize: "1.8em", color: "white"}} />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {isBatbooth: state.locale.isBatbooth, isPulse: state.locale.isPulse }
}

const _menuItem = connect(
    mapStateToProps,
    null)(MenuItem);

export default _menuItem;
