import {
  LOGOUT,
  SET_TOKEN,
  SET_2FA_TOKEN,
  SET_AUTHENTICATED,
  SET_2FA_METHODS,
  ADD_2FA_METHODS,
  ADD_TOAST,
  CLEAR_TOASTS,
  REMOVE_TOAST,
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CHANGE_LANG,
  UPDATE_ME,
  RESET_STATE,
  SOCKET_INIT,
  ADD_NOTIFICATIONS,
  HOME_SET_PERIOD,
  HOME_SET_SITE,
  SOCKET_GET_CONNECTED_DEVICES,
  BOARD_SET_SITES,
  BOARD_SET_SITE_ID,
} from "./types";

import { guid } from "../utils/helpers";
import ajax from "../utils/ajax";

export function logoutUser() {
  return function(dispatch) {
    dispatch({ type: LOGOUT });
    dispatch({ type: RESET_STATE });
  }
}

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token
});

export const set2FAToken = twoFAToken => ({
  type: SET_2FA_TOKEN,
  payload: twoFAToken,
});

export const setAuthenticated = () => ({
  type: SET_AUTHENTICATED,
  payload: {},
});

export const set2FAMethods = twoFAMethods => ({
  type: SET_2FA_METHODS,
  payload: twoFAMethods,
});

export function updateMe(me) {
  return function(dispatch, getState) {
    dispatch({ type: UPDATE_ME, payload: me });
    if (me.language !== getState().locale.language) {
      dispatch(changeLanguage(me.language));
    }
  }
}

export function authenticateUser() {
  return async function(dispatch) {
    dispatch(setAuthenticated());
    await dispatch(fetchMe());
    await dispatch(fetchNotifications());
  }
}

export function login(email, password) {
  return async function(dispatch, getState) {
    const { data: { token, twoFAMethods, authenticated, config } } =
      await ajax.post("/login", {
        email,
        password,
        twoFARememberToken: getState().auth.twoFAToken ?? undefined,
      });
    dispatch(setToken(token));
    dispatch(set2FAMethods(twoFAMethods));
    if (authenticated)
      await dispatch(authenticateUser());
  
    window._config = config || { region: "AU" };
  }
}

export function update2FAMethods(signal) {
  return async function(dispatch) {
    const { data: twoFAMethods } = await ajax.get("/2fa/methods", signal);
    dispatch(set2FAMethods(twoFAMethods));
    return twoFAMethods;
  }
}

export function verify2FAMethod(method) {
  return async dispatch => {
    const { data: { valid, newMethods, rememberToken } } =
      await ajax.post("/2fa/verify", method);
    if (newMethods)
      dispatch(set2FAMethods(newMethods));
    if (rememberToken)
      dispatch(set2FAToken(rememberToken));
    if (valid)
      await dispatch(authenticateUser());
    return valid;
  };
}

export const add2FAMethods = (twoFAMethods) => ({
  type: ADD_2FA_METHODS,
  payload: twoFAMethods,
});

export function enable2FAMethods(methods, signal) {
  return async dispatch => {
    const { data: { valid } } = await ajax.post("/2fa/enable", {
      methods
    }, signal);
    if (valid)
      dispatch(add2FAMethods(methods.map(m => m.type)));
    return valid;
  };
}

function fetchNotifications() {
  return async function(dispatch) {
    let res = await ajax.get("/me/notifications");
    await dispatch(addNotifications(res.data));
  }
}

function fetchMe() {
  return function(dispatch) {
    return ajax.get("/me").then(({data}) => {
      dispatch(updateMe(data));
    })
  }
}

export function clearNotifications() {
  return async function(dispatch, getState) {
    let notifications = getState().notifications;
    if (!notifications.length) return;
    await ajax.delete("/me/notifications");
    dispatch(_clearNotifications());
  }
}

const addNotifications = (notifications = []) => ({
  type: ADD_NOTIFICATIONS,
  payload: notifications
})

export const addToast = (message, type) => ({
  type: ADD_TOAST,
  payload: {
    id: guid(),
    message: message,
    type: type,
    timeout: type === "error" ? 0 : 3000
  }
})

export const removeToast = (id) => ({
  type: REMOVE_TOAST,
  payload: id
})

export const clearToasts = () => ({
  type: CLEAR_TOASTS,
  payload: null
})

export const connectSocket = () => ({
  type: SOCKET_INIT,
  payload: null
})

export const getConnectedDevices = () => ({
  type: SOCKET_GET_CONNECTED_DEVICES,
  payload: null
})

export const addNotification = (message) => ({
  type: ADD_NOTIFICATION,
  payload: message
})

const _clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS
})

export const changeLanguage = (language) => ({
  type: CHANGE_LANG,
  payload: language
})

export const homeSetPeriod = (period) => ({
  type: HOME_SET_PERIOD,
  payload: period,
})

export const homeSetSite = (site) => ({
  type: HOME_SET_SITE,
  payload: site,
})

export const boardSetSites = (sites) => ({
  type: BOARD_SET_SITES,
  payload: { sites },
});

export const boardSetSiteId = (siteId) => ({
  type: BOARD_SET_SITE_ID,
  payload: { siteId },
});
