import React from 'react';
import classes from './WidgetView.module.css';

import SideBar from '../components/widgets/SideBar/SideBar';
import Filter from '../components/widgets/Filter/Filter';
import LowPressureWarning from '../components/widgets/LowPressureWarning/LowPressureWarning';
// import NewUser from '../page/UserManagement/NewUser/NewUserModal';
import BottomBar from '../components/widgets/BottomBar/BottomBar';
import TitleBar from '../components/widgets/TitleBar/TitleBar';
import UserManagement from '../components/widgets/ManagementBar/ManagementBar';
import TableComponent from '../components/widgets/TableComponent/TableComponent';




const WidgetView = (props) => {
  return (
      <div className={classes.WidgetView}>
        <div className={classes.Vertical}>

          <div className={classes.TableComponent}>
            <UserManagement />
          </div>
          <TableComponent/>


          <div className={classes.Horizontal}>
          <Filter/>
          <LowPressureWarning/>
          {/*<NewUser/>*/}
          </div>
          <BottomBar className={classes.BottomBar}/>
        </div>
      </div>
  );
};

export default WidgetView;
