import React, {useEffect, useRef, useState} from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

function Tooltip(props) {
  const [instance, setInstance] = useState();
  const child = useRef();
  const content = useRef();

  useEffect(() => {
    content.current.style.display = "block";
    let options = Object.assign({
      hideOnClick: false,
      theme: 'regular',
    }, props);
    options.content = content.current;
    delete options.children;
    setInstance(tippy(child.current, options));
  }, [])

  return (
    <div>
      <div ref={child}>
        {props.children}
      </div>
      <div style={{display: "none"}} ref={content}>
        {props.content}
      </div>
    </div>
  )
}

export default Tooltip;