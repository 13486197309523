import React, { useState } from "react";
import classes from "./Select.module.css";
import Icon from "../Icon/Icon";
import {guid} from "../../../utils/helpers";

function Select(props) {
  const [uniqueId] = useState(() => "input_" + guid());
  let {id = uniqueId, onChange, style, className, fill, clear, inline, badge,
       label, disabled, children, ...otherProps} = props;

  if (!Array.isArray(children)) {
    children = children ? [children] : [];
  }

  function onInnerChange(e) {
    if (onChange) {
      onChange(e.target.value);
    }
  }

  children = children.flat();

  let options = children.map((option) => {
    if (!option) return null;
    let key = option.id || guid();
    return <option {...option.props} key={key}>{option.props.children}</option>
  });

  if (!children || !children.length) {
    options = <option key={guid()} disabled>No options available.</option>
  }

  return (
    <div style={style} className={`${classes.Dropdown} ${className ? className : ''} ${fill ? classes.Fill : ''} ${clear ? classes.Clear : ''} ${inline ? classes.Inline : ''} ${badge ? classes.HasBadge : ''} ${disabled ? classes.Disabled : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <SelectContainer id={id} onChange={onInnerChange} options={options} badge={badge} disabled={disabled} {...otherProps}/>
    </div>
  )
}

function SelectContainer(props) {
  const {badge, badgeClassName, badgeStyle, badgeType = 'regular', badgeColor,
         id, selected, options, onChange, disabled, ...otherProps} = props;

  return (
    <div className={classes.SelectContainer}>
      <div className={classes.IconContainer}>
        <Icon icon="chevronDown" size="lg" />
      </div>
      {badge && <Icon className={`${classes.Badge} ${badgeClassName || ''}`}
                      style={badgeStyle}
                      type={badgeType}
                      color={badgeColor}
                      icon={badge}/>}
      <select id={id} value={selected} onChange={onChange} disabled={!!disabled} {...otherProps}>
        {options}
      </select>
    </div>
  )
}

export default Select;
