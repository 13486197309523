import Socket from "../../utils/socket";
import {
  LOGOUT,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_INIT,
  SOCKET_MESSAGE,
  SOCKET_GET_CONNECTED_DEVICES
} from "../types";
import { addNotification } from "../actions";

function getConnectedDevices(store) {
  Socket.emit("connected_devices").then(data => {
    store.dispatch({
      type: SOCKET_MESSAGE,
      payload: { event: "connected_devices_changed", data: data }
    })
  })
}

function socketConnected(store, next, action) {
  Socket.on("notification", (data) => {
    store.dispatch(addNotification(data));
  })
  Socket.on("disconnect", () => {
    store.dispatch({ type: SOCKET_DISCONNECT });
  })
  Socket.on("reconnect", () => {
    store.dispatch({ type: SOCKET_CONNECT });
    getConnectedDevices(store);
  })
  Socket.on("connected_devices_changed", (data) => {
    store.dispatch({
      type: SOCKET_MESSAGE,
      payload: { event: "connected_devices_changed", data: data }
    })
  })
  getConnectedDevices(store);
}

function connectSocket(store, next, action) {
  if (Socket.connected()) {
    return Promise.resolve();
  }
  Socket.connect().then((socket) => {
    if (!socket || !socket.connected) return;
    console.log("WebSocket connected: " + socket.id);
    store.dispatch({ type: SOCKET_CONNECT })
    socketConnected(store, next, action);
  }).catch(e => {
    console.error(e);
    store.dispatch({ type: SOCKET_DISCONNECT })
    console.error("Failed to establish websocket connection.");
  })
}

function socketMiddleware() {
  return store => next => action => {
		if (action.type === SOCKET_INIT) {
			connectSocket(store, next, action);
      return next({ type: SOCKET_INIT, payload: Socket });
    }
		if (action.type === LOGOUT) {
      Socket.disconnect();
		}
		if (action.type === SOCKET_GET_CONNECTED_DEVICES) {
      getConnectedDevices(store);
      return;
    }
    return next(action);
  }
}

export default socketMiddleware();