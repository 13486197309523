import { defineMessages } from "react-intl";

export default defineMessages({
  "Welcome": {
    id: "Login.Welcome",
    defaultMessage: "Welcome"
  },
  "Instructions": {
    id: "Login.Instructions",
    defaultMessage: "Enter login details below."
  },
  "TwoFAWelcome": {
    id: "Login.TwoFAWelcome",
    defaultMessage: "Two-Step Authentication"
  },
  "TwoFAInstructions": {
    id: "Login.TwoFAInstructions",
    defaultMessage: "Open the authentication app on your phone to view your authentication code."
  },
  "RecoveryCodeWelcome": {
    id: "Login.RecoveryCodeWelcome",
    defaultMessage: "Recovery Code"
  },
  "RecoveryCodeInstructions": {
    id: "Login.RecoveryCodeInstructions",
    defaultMessage: "In case you lose access to your mobile phone, you can sign in with a recovery code."
  },
  "Email": {
    id: "Login.Email",
    defaultMessage: "Email"
  },
  "Password": {
    id: "Login.Password",
    defaultMessage: "Password"
  },
  "AuthenticationCode": {
    id: "Login.AuthenticationCode",
    defaultMessage: "Authentication Code"
  },
  "RecoveryCode": {
    id: "Login.RecoveryCode",
    defaultMessage: "Recovery Code"
  },
  "UseARecoveryCode1": {
    id: "Login.UseARecoveryCode1",
    defaultMessage: "Lost your phone? Use a ",
  },
  "UseARecoveryCodeLink": {
    id: "Login.UseARecoveryCodeLink",
    defaultMessage: "recovery code",
  },
  "UseARecoveryCode2": {
    id: "Login.UseARecoveryCode2",
    defaultMessage: " instead.",
  },
  "UseAVerificationCode1": {
    id: "Login.UseAVerificationCode1",
    defaultMessage: "Use a ",
  },
  "UseAVerificationCodeLink": {
    id: "Login.UseAVerificationCodeLink",
    defaultMessage: "verification code",
  },
  "UseAVerificationCode2": {
    id: "Login.UseAVerificationCode2",
    defaultMessage: " instead.",
  },
  "Login": {
    id: "Login.Login",
    defaultMessage: "Login"
  },
  "Verify": {
    id: "Login.Verify",
    defaultMessage: "Verify"
  },
  "Remember2FA": {
    id: "Login.Remember2FA",
    defaultMessage: "Remember this device for 30 days"
  },
  "Forgot": {
    id: "Login.ForgotPassword",
    defaultMessage: "Forgot Password"
  },
  "Support": {
    id: "Login.Support",
    defaultMessage: "Support"
  },
  "InvalidEmail": {
    id: "Login.InvalidEmail",
    defaultMessage: "Please enter a valid email address."
  },
  "PasswordRequired": {
    id: "Login.PasswordRequired",
    defaultMessage: "Please enter your password."
  },
  "LoginError": {
    id: "Login.Error",
    defaultMessage: "Login failed. Email or password was incorrect."
  },
  "LoginTotpInvalid": {
    id: "Login.LoginTotpInvalid",
    defaultMessage: "Login failed. Invalid verification code."
  },
  "LoginRecoveryCodeFormInvalid": {
    id: "Login.LoginRecoveryCodeFormInvalid",
    defaultMessage: "Invalid recovery code"
  },
  "LoginRecoveryCodeInvalid": {
    id: "Login.LoginRecoveryCodeInvalid",
    defaultMessage: "Login failed. Invalid recovery code."
  },
  "Login2FAError": {
    id: "Login.Login2FAError",
    defaultMessage: "Error validating Two-Step Authentication. Please try again later."
  },
  "ForgotInstructions": {
    id: "Login.ForgotInstructions",
    defaultMessage: "Please enter the email address associated with your account. You will be sent an email"
      + " containing a link to reset your password."
  },
  "ForgotTitle": {
    id: "Login.ForgotTitle",
    defaultMessage: "Forgot Password?"
  }
});
