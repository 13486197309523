import React, {useRef, useState, Suspense, useEffect} from "react";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import classes from "./PasswordReset.module.css";
import Input from "../components/widgets/Input/Input";
import Spinner from "../components/widgets/Spinner/Spinner";
import Button from "../components/widgets/Button/Button";
import ajax from "../utils/ajax";
import toast from "../utils/toaster";
import messages from "../components/UserAccount.messages";

function PasswordReset(props) {
  const [content, setContent] = useState();

  const checkToken = React.useCallback((token) => {
    ajax.post("/password/validate/" + token).then(({ data }) => {
      data.validated ? setContent(
        <ResetPage {...props} />
      ) : setContent(
        <ResetPage {...props} invalid />
      )
    })
  }, [props])

  useEffect(() => {
    let searchParams = new URLSearchParams(props.location.search);
    let token = searchParams.get("token");
    if (token) {
      checkToken(token);
    } else {
      setContent(
        <Redirect to="/login" />
      )
    }
  }, [props.location.search, checkToken])

  return (
    <Suspense fallback={<div></div>}>
      {content}
    </Suspense>
  )
}

function ResetPage(props) {
  return (
    <div className={classes.PasswordReset}>
      <div className={classes.Panel}>
        <h3>{props.intl.formatMessage({id: "Password.Reset", defaultMessage: "Password Reset"})}</h3>
        <ResetForm {...props} />
      </div>
    </div>
  )
}

function ResetForm(props) {
  const formRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [breached, setBreached] = useState(0);
  let abortController = new AbortController();
  let signal = abortController.signal;

  function passwordConfirmChanged(e) {
    checkPasswords(passwordRef.current.value, passwordConfirmRef.current.value);
  }

  function checkPasswords(password, confirm) {
    if (password !== confirm) {
      passwordConfirmRef.current.setCustomValidity(props.intl.formatMessage({id: "Password.Match", defaultMessage: "Password does not match."}));
    } else {
      passwordConfirmRef.current.setCustomValidity("");
    }
  }

  function passwordChanged() {
    if (passwordRef.current.value.length > 6) {
      const abortController = new AbortController();
      const signal = abortController.signal;
      checkBreach(signal);
      passwordConfirmRef.current.disabled = !passwordRef.current.value;
    }
  }

  function checkBreach(signal) {
    ajax.put("/password/breach", {password: passwordRef.current.value}, signal).then(res => {
      console.log(res.data);
      setBreached(parseInt(JSON.stringify(res.data)));
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      onFinally(signal)
    });
  }

  function onFinally(signal) {
    if (!signal || !signal.aborted) {
      setLoading(false);
    }
  }

  function submit() {
    checkPasswords(passwordRef.current.value, passwordConfirmRef.current.value);
    if (!formRef.current.reportValidity()) {
      return;
    }
    setLoading(true);
    let searchParams = new URLSearchParams(props.location.search);
    let token = searchParams.get("token");
    ajax.post("/password/reset/" + token, { password: passwordRef.current.value }, signal).then(() => {
      setSuccess(true);
    }).catch(e => {
      console.error(e);
      // toast.error("There was a problem updating your password. Please contact support.");
      toast.error(e.message);

    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    return () => abortController.abort();
  }, [])

  const style = {
    backgroundColor: "#43A047",
    borderRadius: "3px",
    color: "white",
    padding: "1rem",
    marginTop: "1.5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }

  if (props.invalid) {
    return (
      <div>
        <div>This password reset link is not valid or has expired. Please request a new password reset email or contact support.</div>
        <div style={{ marginTop: "1rem" }}>Click <a href="login">here</a> to return to log in page.</div>
      </div>
    )
  }

  if (success) {
    return (
      <div style={style}>
        <div>Your password has been updated. Click <a href="login">here</a> to return to log in page.</div>
      </div>
    )
  }

  const passwordInput = (
      <Input
          ref={passwordRef}
          className={classes.PasswordInput}
          required
          password={breached}
          type="password"
          minLength="8"
          label={props.intl.formatMessage({id: "Password", defaultMessage: "Password"})}
          onChange={passwordChanged}
      />
  )
  const confirmPasswordInput = (
    <Input
      ref={passwordConfirmRef}
      className={classes.ConfirmPasswordInput}
      required
      disabled
      type="password"
      label={props.intl.formatMessage({id: "Password.Confirm", defaultMessage: "Confirm Password"})}
      onChange={passwordConfirmChanged}

    />
  )

  return (
    <form ref={formRef} onSubmit={(e) => {e.preventDefault(); submit()}}>
      <Spinner fixed active={loading} />
      {passwordInput}
      {confirmPasswordInput}
      <Button className={classes.SubmitButton} block type="submit">
        {props.intl.formatMessage({id: "Submit", defaultMessage: "Submit" })}
      </Button>
    </form>
  )
}

export default injectIntl(PasswordReset);
