import React, {useEffect, useRef, useState} from "react";
import Modal, {Body as ModalBody, Section as ModalSection} from "./widgets/Modal/Modal";
import ajax from "../utils/ajax";
import toast from "../utils/toaster";
import {timeout} from "../utils/helpers";
import Icon from "./widgets/Icon/Icon";
import Input from "./widgets/Input/Input";
import classes from "./UserAccount.module.css";
import Spinner from "./widgets/Spinner/Spinner";
import Button from "./widgets/Button/Button";
import {hideAll} from "tippy.js";
import {Popup, PopupHeader, PopupItem} from "./widgets/SideBar/Popup";
import {connect} from "react-redux";
import {logoutUser, updateMe} from "../redux/actions";
import messages from "./UserAccount.messages";
import buttonMessages from "../components/widgets/Button/Button.messages";
import loginMessages from "../container/Login.messages";
import ToastInline from "./widgets/Toast/ToastInline";
import Admin from "./Admin";
import {ClientDetails} from "../page/ClientManagement/ClientManagement";
import Select from "./widgets/Select/Select";
import {TwoFASetupModal} from '../container/TwoFASetup';
import {useIntl} from "react-intl";

function UserAccount(props) {
  const [showUpdateUserDetailsModal, setShowDetailsModal] = useState(false);
  const [showClientDetailsModal, setShowClientDetailsModal] = useState(false);
  const [showChangeUserPasswordModal, setChangePasswordModal] = useState(false);
  const [showTwoFASetupModal, setShowTwoFASetupModal] = useState(false);

  function logout() {
    props.logoutUser();
  }

  function openUpdateDetailsModal() {
    setShowDetailsModal(true);
    hideAll();
  }

  function openChangePasswordModal() {
    setChangePasswordModal(true);
    hideAll();
  }

  function openClientDetailsModal() {
    setShowClientDetailsModal(true);
    hideAll();
  }

  function openTwoFASetupModal() {
    setShowTwoFASetupModal(true);
    hideAll();
  }

  function closeUpdateDetailsModal() {
    setShowDetailsModal(false);
  }

  function closeClientDetailsModal() {
    setShowClientDetailsModal(false);
  }

  function closeChangePasswordModal() {
    setChangePasswordModal(false);
  }

  function closeTwoFASetupModal() {
    setShowTwoFASetupModal(false);
  }

  if (!props.user) {
    return null;
  }

  const popupheader = (
      <PopupHeader>
        <div className={classes.UserIconContainer}>
          <Icon icon="user" size="lg"/>
          <div className={`${classes.Badge} ${props.socketConnected ? classes.Connected : classes.Disconnected}`}/>
        </div>
        <div className={classes.UserDetails}>
          <span>{props.user.first_name + " " + props.user.last_name}</span>
          <span>{props.user.client_name}</span>
        </div>
      </PopupHeader>
  )

  return (
      <Popup>
        <Admin>
          <UpdateClientDetailsModal show={showClientDetailsModal} onClose={closeClientDetailsModal} {...props} />
        </Admin>
        <UpdateUserDetailsModal show={showUpdateUserDetailsModal} onClose={closeUpdateDetailsModal} {...props} />
        <ChangeUserPasswordModal show={showChangeUserPasswordModal} onClose={closeChangePasswordModal} {...props} />
        <TwoFASetupModal show={showTwoFASetupModal}
                         onForcePrompt={openTwoFASetupModal}
                         onCancel={closeTwoFASetupModal}
                         onComplete={closeTwoFASetupModal}/>
        {popupheader}
        <PopupItem onClick={openUpdateDetailsModal} icon="user-edit"
                   text={props.intl.formatMessage(messages.UpdateDetails)}/>
        <Admin>
          <PopupItem onClick={openClientDetailsModal} icon="building"
                     text={props.intl.formatMessage(messages.UpdateClient)}/>
        </Admin>
        <PopupItem onClick={openChangePasswordModal} icon="key"
                   text={props.intl.formatMessage(messages.ChangePassword)}/>
        <PopupItem onClick={openTwoFASetupModal} icon="mobile"
                   text={props.intl.formatMessage(messages.TwoStepAuthentication)}/>
        <PopupItem icon="sign-out" text={props.intl.formatMessage(messages.LogOut)} onClick={logout}/>
      </Popup>
  )
}

function UpdateClientDetailsModal(props) {
  return (
      <Modal className={classes.UserDetailsModal} title={props.intl.formatMessage(messages.UpdateClient)}
             show={props.show} style={{width: "40rem"}} onClose={props.onClose} footer={<div></div>}>
        <UpdateClientDetailsForm {...props} />
      </Modal>
  )
}

function UpdateUserDetailsModal(props) {
  return (
      <Modal className={classes.UserDetailsModal} title={props.intl.formatMessage(messages.UpdateDetails)}
             show={props.show} style={{width: "40rem"}} onClose={props.onClose} footer={<div></div>}>
        <UpdateUserDetailsForm {...props} />
      </Modal>
  )
}

function ChangeUserPasswordModal(props) {
  return (
      <Modal title="Change Password" show={props.show} onClose={props.onClose} footer={<div></div>}>
        <ChangePasswordForm {...props} />
      </Modal>
  )
}

function UpdateClientDetailsForm(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [success, setSuccess] = useState("");
  const form = useRef();
  const clientData = useRef({});

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;
    ajax.get("/client/me", signal).then(({data}) => {
      setData(data);
    }).catch(e => {
      console.error(e);
      setError(e.message);
    }).finally(() => {
      onFinally(signal);
    })
    return () => abortController.abort();
  }, [])

  function onFinally(signal) {
    if (!signal || !signal.aborted) {
      setLoading(false);
    }
  }

  function onDataChanged(key, value) {
    clientData.current[key] = value;
  }

  function submit() {
    setSuccess("");
    setError("");
    setLoading(true);
    const abortController = new AbortController();
    ajax.patch("/client/" + data.id, clientData.current, abortController.signal).then(() => {
      setSuccess("Successfully Updated Details.");
    }).catch(e => {
      console.error(e);
      setError(e.message);
    }).finally(() => {
      onFinally(abortController.signal);
    })
  }

  return (
      <form onSubmit={(e) => {
        e.preventDefault();
        submit()
      }} ref={form}>
        {(success || error) && <ModalSection>
          <ToastInline show={!!success} message={success} variant="success"/>
          <ToastInline show={!!error} message={error} variant="error"/>
        </ModalSection>}
        <ClientDetails loading={loading} onDataChanged={onDataChanged} {...props} data={data}/>
        <ModalSection layout="horizontal">
          <Button type="button" link onClick={props.onClose}>{props.intl.formatMessage(buttonMessages.Cancel)}</Button>
          <div className="flex"></div>
          <Button className={classes.SubmitButton} type="submit">
            {props.intl.formatMessage(messages.SaveChanges)}
          </Button>
        </ModalSection>
      </form>
  )
}

export function UpdateUserDetailsForm(props) {
  const form = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [language, setLanguage] = useState("");
  // const [canSendCode, setCanSendCode] = useState(false);
  // const [hasCodeSent, setHasCodeSent] = useState(false);
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [requiresPassword, setRequiresPassword] = useState(false);
  // const [code, setCode] = useState("");
  const user = useRef({});

  function onFinally(signal) {
    if (!signal || !signal.aborted) {
      setLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    ajax.get("/me", abortController.signal).then(({data}) => {
      user.current = data;
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      setMobile(data.mobile);
      // setLanguage(data.language || "en-au");
      setLanguage(data.language || props.language);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      onFinally(abortController.signal);
    })
    return () => abortController.abort();
  }, [props.language])

  function submit() {
    if (!form.current.reportValidity()) {
      return;
    }
    setSuccess(false);
    setError(false);
    setLoading(true);
    const me = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: mobile,
      language: language
    }
    if (password && requiresPassword) {
      me.password = password;
    }
    saveMe(me);
  }

  function saveMe(me) {
    const abortController = new AbortController();
    ajax.put("/me", me, abortController.signal).then(res => {
      props.updateMe(res.data);
      user.current = res.data;
      setSuccess(true);
      setRequiresPassword(false);
      setPassword("");
    }).catch(e => {
      console.error(e);
      setError(e.message);
    }).finally(() => {
      onFinally(abortController.signal);
    })
  }

  //
  // function sendMobileCode() {
  //   setHasCodeSent(true);
  // }

  function firstNameChanged(e) {
    setFirstName(e.target.value);
  }

  function lastNameChanged(e) {
    setLastName(e.target.value);
  }

  function mobileChanged(e) {
    let regex = new RegExp(/^[+-]?[\d]*$/);
    if (regex.test(e.target.value)) {
      setMobile(e.target.value);
      // setCanSendCode(user.current.mobile !== e.target.value);
    }
  }

  function emailChanged(e) {
    setEmail(e.target.value);
    setRequiresPassword(user.current.email !== e.target.value);
  }

  function passwordChanged(e) {
    setPassword(e.target.value);
  }

  //
  // function codeChanged(e) {
  //   setCode(e.target.value);
  // }

  function onLanguageChange(e) {
    setLanguage(e);
  }

  // const verificationCode = (
  //   <div className="layout vertical" style={{gridColumn: "1 / -1"}}>
  //     <ToastInline message={props.intl.formatMessage(messages.MessageVerification)} icon="key-solid" backgroundColor="#1F6C8E36" color="#002E41" />
  //     <Input
  //       onChange={codeChanged}
  //       value={code}
  //       required
  //       type="text"
  //       label={props.intl.formatMessage(messages.VerificationCode)}
  //     />
  //   </div>
  // )

  const formBody = (
    <ModalBody>
      {(success || verifyEmail || error) && <ModalSection>
        <ToastInline show={success} message={props.intl.formatMessage(messages.SuccessfullyUpdated)} variant="success" />
        <ToastInline show={verifyEmail} message={props.intl.formatMessage(messages.VerifyEmail)} icon="envelope" variant="info" />
        <ToastInline show={error} message={error} variant="error" />
      </ModalSection>}
      <ModalSection layout="grid">
        <Input
          type="text"
          required
          label={props.intl.formatMessage(messages.FirstName)}
          value={firstName}
          onChange={firstNameChanged}
        />
        <Input
          type="text"
          required
          label={props.intl.formatMessage(messages.LastName)}
          value={lastName}
          onChange={lastNameChanged}
        />
        <Select label="Language" selected={language} onChange={onLanguageChange}>
          <option value="en">English (US)</option>
          <option value="en-au">English (Australian)</option>
          <option value="es">Español</option>
        </Select>
      </ModalSection>
      <ModalSection>
        <Input
          required
          type="email"
          label={props.intl.formatMessage(loginMessages.Email)}
          value={email}
          onChange={emailChanged}
        />
        {requiresPassword && <Input
          required
          type="password"
          label={props.intl.formatMessage(messages.PasswordCurrent)}
          value={password}
          onChange={passwordChanged}
          style={{marginTop: "1rem"}}
        />
        }
      </ModalSection>
      <ModalSection layout="grid align-end">
        <Input
          label={props.intl.formatMessage(messages.Mobile)}
          type="text"
          value={mobile}
          onChange={mobileChanged}
        />
        {/*{canSendCode && <Button type="button" onClick={sendMobileCode}>{props.intl.formatMessage(messages.SendVerificationCode)}</Button>}*/}
        {/*{hasCodeSent && canSendCode ? verificationCode : null}*/}
      </ModalSection>
    </ModalBody>
  )

  return (
      <form className={classes.UserDetailsForm} ref={form} onSubmit={(e) => {
        e.preventDefault();
        submit()
      }}>
        <Spinner active={loading}/>
        {formBody}
        <ModalSection layout="horizontal">
          <Button type="button" onClick={props.onClose} link>{props.intl.formatMessage(buttonMessages.Cancel)}</Button>
          <div className="flex"></div>
          <Button type="submit" style={{width: "200px"}}>{props.intl.formatMessage(buttonMessages.SaveChanges)}</Button>
        </ModalSection>
      </form>
  )

}

export function ChangePasswordForm(props) {
  const intl = useIntl();
  const formRef = useRef();
  const passwordConfirmRef = useRef();
  const currentPasswordRef = useRef();
  const [newPassword, setNewPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [breached, setBreached] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (newPassword && newPassword.length >= 8) {
      passwordConfirmRef.current.disabled = !newPassword;

      const ac = new AbortController();
      (async () => {
        try {
          await timeout(1000, ac.signal);
          const { data } = await ajax.put("/password/breach", {
            password: newPassword,
          }, ac.signal);
          setBreached(+data);
        } catch (e) {
          if (e.name !== 'AbortError')
            console.error(e);
        }
      })();
      return () => ac.abort();
    }
  }, [newPassword])

  function passwordConfirmChanged() {
    checkPasswords(newPassword, passwordConfirmRef.current.value);
  }

  function checkPasswords(password, confirm) {
    if (password !== confirm) {
      passwordConfirmRef.current.setCustomValidity(intl.formatMessage(messages.PasswordMatch));
    } else {
      passwordConfirmRef.current.setCustomValidity("");
    }
  }

  function newPasswordChanged({target}) {
    setNewPassword(target.value);
  }

  function submit() {
    checkPasswords(newPassword, passwordConfirmRef.current.value);
    if (!formRef.current.reportValidity()) {
      return;
    }
    const abortController = new AbortController();
    const signal = abortController.signal;
    setLoading(true);
    ajax.put("/password", {
      password: currentPasswordRef.current.value,
      newPassword,
    }, signal).then((req) => {
      setSuccess(true);
      console.log(req);
    }).catch(e => {
      console.error(e);
      // toast.error(intl.formatMessage(messages.PasswordUpdateError));
      toast.error(e.message);

    }).finally(() => {
      setLoading(false);
    });
  }

  const style = {
    backgroundColor: "#43A047",
    borderRadius: "3px",
    color: "white",
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "1.5rem"
  }

  if (success) {
    return (
        <div style={style}>
          <Icon icon="check" style={{marginRight: ".5rem"}}/>
          <div>{intl.formatMessage(messages.PasswordUpdateSuccess)}</div>
        </div>
    )
  }

  const currentPasswordInput = (
      <Input
          ref={currentPasswordRef}
          className={classes.PasswordInput}
          required
          type="password"
          label={intl.formatMessage(messages.PasswordCurrent)}
      />
  )

  const passwordInput = (
      <Input
          className={classes.PasswordInput}
          required
          password={breached}
          type="password"
          minLength="8"
          label={intl.formatMessage(messages.PasswordNew)}
          value={newPassword}
          onChange={newPasswordChanged}
      />
  )

  const confirmPasswordInput = (
      <Input
          ref={passwordConfirmRef}
          required
          disabled
          type="password"
          label={intl.formatMessage(messages.PasswordConfirm)}
          onChange={passwordConfirmChanged}
      />
  )

  return (
      <form ref={formRef} onSubmit={(e) => {
        e.preventDefault();
        submit()
      }}>
        <Spinner fixed active={loading}/>
        <ModalSection>
          {currentPasswordInput}
          {passwordInput}
          {confirmPasswordInput}
        </ModalSection>
        <ModalSection layout="horizontal">
          <Button type="button" link onClick={props.onClose}>Cancel</Button>
          <div className="flex"></div>
          <Button className={classes.SubmitButton} type="submit">
            {intl.formatMessage(buttonMessages.Submit)}
          </Button>
        </ModalSection>
      </form>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    socketConnected: state.socket.connected,
    language: state.locale.language
  }
}

export default connect(
    mapStateToProps,
    {logoutUser, updateMe}
)(UserAccount);
