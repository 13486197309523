import React, {forwardRef, useState, useRef} from "react";
import classes from "./Radio.module.css";
import {guid} from "../../../utils/helpers";

// TODO: Currently this has only been designed for use with buttonMode=true

export default forwardRef(function Radio(props, ref) {
  const [uniqueId] = useState(() => `radio-${guid()}`);
  const {
    id = uniqueId,
    className, inputClassName, buttonMode, children, onChange, variant,
    ...otherProps
  } = props;

  const inputRef = useRef();
  if (!ref)
    ref = inputRef;

  let variantClass = classes.Primary;
  switch (variant) {
    case 'primary': variantClass = classes.Primary; break;
    case 'danger': variantClass = classes.Danger; break;
    default: break;
  }

  function onInnerChange(e) {
    onChange && onChange(e, ref.current.checked);
  }

  return <>
    <input type="radio"
           ref={ref}
           id={id}
           className={classes.RadioInput + ' ' + variantClass + ' ' +
                      (buttonMode ? classes.RadioInputButton : '') + ' ' +
                      (inputClassName || '')}
           onChange={onInnerChange}
           checked={!!props.checked}
           {...otherProps}/>
    <label htmlFor={id}
           className={classes.RadioLabel + ' ' + variantClass + ' ' +
                      (buttonMode ? classes.RadioLabelButton : '') + ' ' +
                      (className || '')}>
      {children}
    </label>
  </>;
});
