import React, {useCallback, useEffect, useRef, useState} from "react";
import classes from "./ClientManagement.module.css";
import DataTable from "react-data-table-component";
import Header from "../Header";
import Button from "../../components/widgets/Button/Button";
import Page from "../Page";
import ajax from "../../utils/ajax";
import Modal, {Body as ModalBody, Section as ModalSection} from "../../components/widgets/Modal/Modal";
import Input from "../../components/widgets/Input/Input";
import tableClasses from "../../components/widgets/TableComponent/TableComponent.module.css";
import Spinner from "../../components/widgets/Spinner/Spinner";
import messages from "./ClientManagement.messages";
import Icon from "../../components/widgets/Icon/Icon";
import sidebarMessages from "../../components/widgets/SideBar/SideBar.messages";
import buttonMessages from "../../components/widgets/Button/Button.messages";
import {FormattedDate, FormattedTime} from "react-intl";
import Dot from "../../components/widgets/Dot/Dot";
import Select from "../../components/widgets/Select/Select";
import ToastInline from "../../components/widgets/Toast/ToastInline";
import Dropdown from "../../components/widgets/Dropdown/Dropdown";
import Footer, {classes as footerClasses, FooterAction} from "../Footer";
import PhoneInput from "../../components/widgets/Input/PhoneInput";

const customStyles = {
  title: {
    style: {
      defaultSortField: {
        opacity: 1,
      }
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#0D668D24',
      borderColor: '#DDDDDD',
      outline: '1px solid #FFFFFF',
    },
  },
};

function ClientManagement(props) {
  return (
      <div className={props.className}>
        <Page1 {...props} />
      </div>
  )
}

function Page1(props) {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [row, setRow] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [resetPaginationToggle] = React.useState(false);
  const [countryOptions, setCountryOptions] = useState();

  const [all, setAll] = useState(false);
  const [enabled, setEnabled] = useState("all");
  const [country, setCountry] = useState("all");
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [direction, setDirection] = useState("asc");
  const [sort, setSort] = useState("")

  const items = useRef([
    {
      text: "Edit Details",
      icon: "edit",
      onClick: (value) => showEditModal(value)
    }
  ])

  const columns = useRef([
    {
      name: props.intl.formatMessage(messages.ClientName),
      selector: 'name',
      sortable: true,
    },
    {
      name: props.intl.formatMessage(messages.CompanyNo),
      selector: 'company_no',
      sortable: true,
    },
    {
      name: props.intl.formatMessage(messages.Country),
      selector: 'country',
      sortable: true,
    },
    {
      name: props.intl.formatMessage(messages.LastModified),
      selector: 'last_modified',
      sortable: true,
      cell: (cell) => <><FormattedDate value={cell.last_modified}/><span>&nbsp;</span><FormattedTime
          hour='numeric' minute='numeric' second='numeric'
          value={cell.last_modified}/></>
    },
    {
      name: props.intl.formatMessage(messages.Enabled),
      selector: 'enabled',
      ignoreRowClick: true,
      allowOverflow: true,
      sortable: true,
      cell: (value) => <div><Dot
          variant={value.enabled ? "success" : "danger"}/><span>{value.enabled ? "Yes" : "No"}</span></div>
    },
    {
      name: <Icon icon="menu" className={tableClasses.OptionsIcon}/>,
      cell: (value) => <Dropdown toggle={<Icon icon="menu" className={tableClasses.OptionsIcon}/>} value={value}
                                 items={items.current.map(x => {
                                   return {...x}
                                 })}/>,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true,
      width: '5rem',
      style: {
        cursor: 'pointer'
      }
    },
  ]);

  useEffect(() => {
    const convertCountriesToOptions = (data) => {
      let options = data.map(item => {
        return <option key={item.country} value={item.country}>{item.country}</option>
      })
      setCountryOptions(options);
    }

    const abortController = new AbortController();
    ajax.get("/client/country", abortController.signal).then(({data}) => {
      convertCountriesToOptions(data);
    }).catch(e => {
      console.error(e);
    });
    return () => abortController.abort();
  }, [])

  const getClientData = useCallback((signal) => {
    setLoading(true);
    const searchParams = new URLSearchParams();
    searchParams.set("page", currentPage.toString());
    searchParams.set("per_page", perPage.toString());
    if (search) {
      searchParams.set("search", search);
    }
    if (sort) {
      searchParams.set("sort", sort + " " + direction);
    }
    if (country !== "all") {
      searchParams.set("country", country);
    }
    if (enabled !== "all") {
      searchParams.set("enabled", enabled);
    }

    const query = searchParams.toString();
    const route = "/client" + (query ? "?" + query : "");
    ajax.get(route, signal).then(({data}) => {
      setData(data);
      if (data && data.length) {
        setTotal(parseInt(data[0].total));
      }
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      onFinally(signal);
    })
  }, [currentPage, perPage, country, enabled, search, sort, direction])

  useEffect(() => {
    const abortController = new AbortController();
    getClientData(abortController.signal);
    return () => abortController.abort();
  }, [currentPage, perPage, country, enabled, search, getClientData])

  function onFinally(signal) {
    if (!signal || !signal.aborted) {
      setLoading(false);
    }
  }

  function showEditModal(data) {
    setRow(data);
    showModal();
  }

  function showAddModal() {
    setRow(null);
    showModal();
  }

  function showModal() {
    setShow(true);
  }

  function closeModal() {
    setShow(false);
  }

  function onSearchChanged(e) {
    setSearch(e.target.value);
    handlePageChange(1);
  }

  function onCountryChanged(e) {
    setCountry(e);
    handlePageChange(1);
  }

  function onEnabledChanged(e) {
    setEnabled(e);
    handlePageChange(1);
  }

  const onSelectedRowsChange = useCallback(e => {
    setSelectedRows(e.selectedRows);
    setAll(e.allSelected);
  }, []);

  useEffect(() => {
    if (all) {
      setSelectedRowCount(total);
    } else {
      setSelectedRowCount(selectedRows.length);
    }
  }, [selectedRows, all, total])

  function enableSelected() {
    if (all) {
      updateAll({enabled: true});
      return;
    }
    let items = [];
    for (let row of selectedRows) {
      items.push({id: row.id, enabled: true});
    }
    updateRows(items);
  }

  function disableSelected() {
    if (all) {
      updateAll({enabled: false});
      return;
    }
    let items = [];
    for (let row of selectedRows) {
      items.push({id: row.id, enabled: false});
    }
    updateRows(items);
  }

  function updateAll(item) {
    if (loading) return;
    setLoading(true);
    ajax.patch("/client/all", item).then(() => {
      getClientData();
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      onFinally();
    })
  }

  function updateRows(items) {
    if (loading) return;
    setLoading(true);
    ajax.patch("/client", items).then(() => {
      getClientData();
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      onFinally();
    })
  }

  function handlePageChange(e) {
    setCurrentPage(e);
  }

  function handlePerRowsChange(e) {
    setPerPage(e);
  }

  function isRowSelected(row) {
    return !!selectedRows.find(x => x.id === row.id);
  }

  const onSort = useCallback((e,sortDirection) => {
    if (!e.sortable) return;
    setDirection(sortDirection);
    setSort(e.selector);
  }, [direction]);

  const header = (
      <Header title={props.intl.formatMessage(sidebarMessages.ClientManagement)}>
        <Select className={classes.Select} label={props.intl.formatMessage(messages.Country) + ':'} inline fill
                selected={country} onChange={onCountryChanged}>
          <option value="all">All</option>
          {countryOptions}
        </Select>
        <Select className={classes.Select} label={props.intl.formatMessage(messages.Enabled) + ':'} inline fill
                selected={enabled} onChange={onEnabledChanged}>
          <option value="all">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Input search intl={props.intl} fill className={classes.SearchInput} value={search} onChange={onSearchChanged}/>
        <div className={classes.NewClientButton}>
          <Button onClick={showAddModal} icon="plus">{props.intl.formatMessage(messages.NewClient)}</Button>
        </div>
      </Header>
  )

  const content = (
      <div className={classes.Table}>
        <NewClientModal {...props} show={show} closeModal={closeModal} getClientData={getClientData} data={row}/>
        <Spinner active={loading}/>
        <DataTable
            className={tableClasses.Table}
            sortIcon={<Icon icon="sort" className={tableClasses.SortIcon}/>}
            columns={columns.current}
            data={data}
            highlightOnHover={true}
            noHeader={true}
            customStyles={customStyles}
            selectableRows
            selectableRowsHighlight
            pagination
            paginationServer
            paginationTotalRows={total}
            onSelectedRowsChange={onSelectedRowsChange}
            paginationResetDefaultPage={resetPaginationToggle}
            progressPending={loading}
            progressComponent={<div/>}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationPerPage={perPage}
            persistTableHead
            sortServer
            onSort={onSort}
            selectableRowSelected={isRowSelected}
        />
      </div>
  );

  const footer = (
      <Footer count={selectedRowCount}>
        <div className={footerClasses.FooterItem}>
          <FooterAction icon="check" iconType="duo" onClick={() => enableSelected()}>Enable Clients</FooterAction>
        </div>
        <div className={footerClasses.FooterItem}>
          <FooterAction icon="exit" iconType="duo" iconStyle={{fontSize: "1.5rem"}} onClick={() => disableSelected()}>Disable
            Clients</FooterAction>
        </div>
      </Footer>
  )

  return (
      <Page header={header} content={content} footer={footer}/>
  )
}

function NewClientModal(props) {
  const form = useRef();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const clientData = useRef({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const mode = props.data ? "edit" : "create";
  const data = props.data || {};

  useEffect(() => {
    setError("");
  }, [props.show])

  useEffect(() => {
    clientData.current = props.data || {};
  }, [props.data])

  function submit() {
    if (!form.current.reportValidity()) {
      return;
    }
    setLoading(true);
    setError("");
    let route = mode === "edit" ? "/client/" + props.data.id : "/client";
    ajax[mode === "edit" ? "put" : "post"](route, clientData.current, signal).then(() => {
      props.closeModal();
      props.getClientData();
      setError("");
    }).catch(e => {
      console.error(e);
      setError(e.message);
    }).finally(() => {
      onFinally();
    })
  }

  function onFinally() {
    if (!signal.aborted) {
      setLoading(false);
    }
  }

  function onDataChanged(key, value) {
    clientData.current[key] = value;
  }

  return (
      <Modal show={props.show} onClose={props.closeModal}
             title={props.intl.formatMessage(mode === "create" ? messages.NewClient : messages.EditClient)} footer>
        <form onSubmit={(e) => {
          e.preventDefault();
          submit()
        }} ref={form}>
          <ClientDetails loading={loading} error={error} onDataChanged={onDataChanged} {...props} data={data}/>
          <ModalSection layout="horizontal">
            <Button type="button" link
                    onClick={props.closeModal}>{props.intl.formatMessage(buttonMessages.Cancel)}</Button>
            <div className="flex"/>
            <Button className={classes.SubmitButton} type="submit">
              {props.intl.formatMessage(mode === "create" ? messages.AddClient : messages.UpdateClient)}
            </Button>
          </ModalSection>
        </form>
      </Modal>
  )
}

export function ClientDetails(props) {
  const [name, setName] = useState("");
  const [company_no, setCompanyNo] = useState(props.data.company_no || "");
  const [street, setStreet] = useState(props.data.street || "");
  const [city, setCity] = useState(props.data.suburb || "");
  const [state, setState] = useState(props.data.state || "");
  const [postcode, setPostCode] = useState(props.data.post_code || "");
  const [country, setCountry] = useState(props.data.country || "");
  const [phone, setPhone] = useState(props.data.phone || "");

  useEffect(() => {
    setName(props.data.name || "");
  }, [props.data.name])

  useEffect(() => {
    setCompanyNo(props.data.company_no || "");
  }, [props.data.company_no])

  useEffect(() => {
    setStreet(props.data.street || "");
  }, [props.data.street])

  useEffect(() => {
    setCity(props.data.suburb || "");
  }, [props.data.suburb])

  useEffect(() => {
    setState(props.data.state || "");
  }, [props.data.state])

  useEffect(() => {
    setPostCode(props.data.post_code || "");
  }, [props.data.post_code])

  useEffect(() => {
    setCountry(props.data.country || "");
  }, [props.data.country])

  useEffect(() => {
    setPhone(props.data.phone || "");
  }, [props.data.phone])

  function onNameChanged(e) {
    setName(e.target.value);
    props.onDataChanged("name", e.target.value);
  }

  function onCompanyChanged(e) {
    setCompanyNo(e.target.value);
    props.onDataChanged("company_no", e.target.value);
  }

  function onStreetChanged(e) {
    setStreet(e.target.value);
    props.onDataChanged("street", e.target.value);
  }

  function onCityChanged(e) {
    setCity(e.target.value);
    props.onDataChanged("suburb", e.target.value);
  }

  function onStateChanged(e) {
    setState(e.target.value);
    props.onDataChanged("state", e.target.value);
  }

  function onPostCodeChanged(e) {
    setPostCode(e.target.value);
    props.onDataChanged("post_code", e.target.value);
  }

  function onCountryChanged(e) {
    setCountry(e.target.value);
    props.onDataChanged("country", e.target.value);
  }

  function onPhoneChanged(e) {
    setPhone(e.target.value);
    props.onDataChanged("phone", e.target.value);
  }
  
  return (
      <ModalBody>
        <Spinner active={props.loading}/>
        {props.error && <ModalSection>
          <ToastInline show={props.error} message={props.error} variant="error"/>
        </ModalSection>}
        <ModalSection layout="grid">
          <Input type="text" required label={props.intl.formatMessage(messages.ClientName)} value={name}
                 onChange={onNameChanged}/>
          <Input type="text" required label={props.intl.formatMessage(messages.CompanyNo)} value={company_no}
                 onChange={onCompanyChanged}/>
          <Input type="text" label={props.intl.formatMessage(messages.Street)} value={street}
                 onChange={onStreetChanged}/>
          <Input type="text" label={props.intl.formatMessage(messages.City)} value={city} onChange={onCityChanged}/>
          <Input type="text" label={props.intl.formatMessage(messages.State)} value={state} onChange={onStateChanged}/>
          <Input type="text" label={props.intl.formatMessage(messages.PostCode)} value={postcode}
                 onChange={onPostCodeChanged}/>
          <Input type="text" label={props.intl.formatMessage(messages.Country)} value={country}
                 onChange={onCountryChanged}/>
        </ModalSection>
        <ModalSection layout="vertical">
          <PhoneInput name="phone"
                      autoComplete="tel"
                      maxLength="30"
                      placeholder={props.intl.formatMessage(messages.Phone)}
                      label={props.intl.formatMessage(messages.Phone)}
                      value={phone || ''}
                      onChange={onPhoneChanged}/>
        </ModalSection>
      </ModalBody>
  )
}

export default ClientManagement;
