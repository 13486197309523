import {ADD_NOTIFICATION, ADD_NOTIFICATIONS, CLEAR_NOTIFICATIONS} from "../types";

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return addNotification(state, action);
    }
    case CLEAR_NOTIFICATIONS: {
      return clearNotifications();
    }
    case ADD_NOTIFICATIONS: {
      return addNotifications(state, action);
    }
    default: {
      return state;
    }
  }
}

function addNotifications(state, action) {
  for (let n of action.payload) {
    n.date = new Date(n.last_modified);
  }
  return action.payload;
}

function addNotification(state, action) {
  let newNotification = {
    name: action.payload.name,
    message: action.payload.message,
    id: action.payload.id,
    date: new Date(action.payload.last_modified)
  }
  return [newNotification, ...state];
}

function clearNotifications() {
  return [];
}