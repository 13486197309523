import { persistReducer } from 'redux-persist';
import storage from 'localforage';

import {
  BOARD_SET_SITES,
  BOARD_SET_SITE_ID,
} from '../types';

const initialState = {
  sites: null,
  siteId: null,
};

export default persistReducer({
  key: 'checkInBoard',
  storage,
  whitelist: ['siteId'],
}, function boardReducer(state = initialState, action) {
  switch (action.type) {
    case BOARD_SET_SITES:
      state = setSites(state, action);
      break;
    case BOARD_SET_SITE_ID:
      state = setSiteId(state, action);
      break;
    default:
      break;
  }
  return state;
});

function setSites(state, { payload: { sites } }) {
  return {
    ...state,
    sites,
    siteId: sites.some(s => s.id === state.siteId)
      ? state.siteId
      : (sites?.[0]?.id || null)
  };
}

function setSiteId(state, { payload: { siteId } }) {
  return {
    ...state,
    siteId,
  };
}
