import React, { forwardRef, useLayoutEffect, useRef } from 'react';

// Attach a DOM tree under a React component
export default forwardRef(function DomRenderer(props, ref) {
  const {elem, ...otherProps} = props;

  const containerRef = useRef();
  if (!ref)
    ref = containerRef;

  useLayoutEffect(() => {
    const oldElem = ref.current.firstElementChild;
    if (oldElem) {
      if (elem) {
        ref.current.replaceChild(elem, oldElem);
      } else {
        ref.current.removeChild(oldElem);
      }
    } else if (elem) {
      ref.current.appendChild(elem);
    }
  }, [elem]);

  return <div ref={ref} {...otherProps}/>;
});
