import { useEffect, useRef, useState, useDebugValue } from 'react';
import { useHistory as useRRHistory, useLocation } from "react-router-dom";

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
const crypto = window.crypto || window.msCrypto;

export function useHtmlId(label = 'uid') {
  const [id] = useState(() => {
    const id = Array.from(crypto.getRandomValues(new Uint8Array(22)), b => chars[b & 0x3f]).join('');
    return `${label}-${id}`;
  });
  useDebugValue(id);
  return id;
}

export function useBusyCounter() {
  const [busy, setBusy] = useState(0);
  useDebugValue(busy);
  return {
    isBusy: !!busy,
    refBusy() {
      setBusy(b => b + 1);
      let released = false;
      return function releaseBusy() {
        if (!released)
          setBusy(b => b - 1);
        released = true;
      };
    },
    async withBusy(fn) {
      setBusy(b => b + 1);
      try {
        return await fn();
      } finally {
        setBusy(b => b - 1);
      }
    },
  };
}

export function useDebounced(value, debounce = 300) {
  const [debounced, setDebounced] = useState(value);
  useDebugValue([value, debounced]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      timeout = -1;
      setDebounced(value);
    }, debounce);

    return () => {
      if (timeout >= 0)
        clearTimeout(timeout);
      timeout = -1;
    };
  }, [value]);

  return debounced;
}

export function useAsyncEffect(effect, deps, { signal = null, refBusy = null } = {}) {
  useDebugValue(effect);

  const { current: state } = useRef({
    abortController: null,
    lastSignal: null,
  });

  function onAbort() {
    // There should be at-most one instance of the async effect running at any
    // one time. If the parent AbortSignal is fired, abort the async effect.
    if (typeof state.abortController?.abort === 'function')
      state.abortController.abort();
  }

  // If the parent signal is different from last call, attach the event listener
  if (signal !== state.lastSignal) {
    // Detach the event listener from the old signal
    if (typeof state.lastSignal?.removeEventListener === 'function')
      state.lastSignal.removeEventListener('abort', onAbort);

    // Attach the event listener to the new signal
    if (typeof signal?.addEventListener === 'function')
      signal.addEventListener('abort', onAbort);

    // If the new signal is aborted, abort the following signal immediately
    if (signal?.aborted && typeof state.abortController?.abort === 'function')
      state.abortController.abort();
  }
  state.lastSignal = signal;

  useEffect(() => {
    // Don't even start effects with an aborted signal
    if (state.lastSignal?.aborted)
      return;

    let releaseBusy = refBusy ? refBusy() : null;

    let ac = (typeof window.AbortController === 'function') ? new window.AbortController() : null;
    state.abortController = ac;

    Promise.resolve(effect(ac?.signal)).then(() => {
      releaseBusy && releaseBusy();
      if (state.abortController === ac)
        state.abortController = null;
      ac = null;
    }, err => {
      releaseBusy && releaseBusy();
      if (state.abortController === ac)
        state.abortController = null;
      ac = null;
      if (err.name !== 'AbortError')
        return Promise.reject(err);
    });

    return () => {
      releaseBusy && releaseBusy();
      if (typeof ac?.abort === 'function')
        ac.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useUnloadBlock(block = true) {
  block = !!block;
  useEffect(() => {
    function onBeforeUnload(e) {
      e.preventDefault();
      e.returnValue = '';
    }

    if (block) {
      window.addEventListener('beforeunload', onBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
      };
    }
  }, [block]);
}

export function useHistory() {
  const history = useRRHistory();
  const location = useLocation();

  return {
    get length() { return history.length; },
    get action() { return history.action; },
    get location() { return history.location; },
    push(...args) { return history.push(...args); },
    replace(...args) { return history.replace(...args); },
    go(...args) { return history.go(...args); },
    goBack(...args) { return history.goBack(...args); },
    goForward(...args) { return history.goForward(...args); },
    block(...args) { return history.block(...args); },

    // Go to the next page in the stack (increment currentPageIndex by 1)
    nextPage(props = {}, {replace = false} = {}) {
      const currentPageIndex = history.location.state?.currentPageIndex ?? 0;
      const refreshCount = history.location.state?.refreshCount ?? 0;
      const stack = [...(history.location.state?.stack ?? [])];
      stack[currentPageIndex] = {
        props: history.location.state?.props ?? {},
        pageConfig: history.location.state?.pageConfig ?? {},
      };

      (replace ? history.replace : history.push)({
        ...history.location,
        state: {
          ...(history.location.state ?? {}),
          refreshCount: refreshCount + 1,
          stack,
          currentPageIndex: currentPageIndex + 1,
          props: props ?? {},
          pageConfig: {},
        },
      });

      return this;
    },

    // Go back to the previous page in the stack (decrement currentPageIndex by 1)
    closePage() {
      const currentPageIndex = history.location.state?.currentPageIndex ?? 0;
      const refreshCount = history.location.state?.refreshCount ?? 0;
      const stack = [...(history.location.state?.stack ?? [])];
      if (currentPageIndex <= 0)
        return;
      const props = stack[currentPageIndex - 1]?.props ?? {};
      const pageConfig = stack[currentPageIndex - 1]?.pageConfig ?? {};
      stack.length = currentPageIndex - 1;

      history.push({
        ...history.location,
        state: {
          ...(history.location.state ?? {}),
          refreshCount: refreshCount + 1,
          stack,
          currentPageIndex: currentPageIndex - 1,
          props: props ?? {},
          pageConfig: pageConfig ?? {},
        },
      });

      return this;
    },

    setProps(props = {}) {
      const refreshCount = history.location.state?.refreshCount ?? 0;

      history.replace({
        ...history.location,
        state: {
          ...(history.location.state ?? {}),
          refreshCount: refreshCount + 1,
          props: props ?? {},
        },
      });

      return this;
    },

    setPageConfig(pageConfig = {}) {
      history.replace({
        ...history.location,
        state: {
          ...(history.location.state ?? {}),
          pageConfig: pageConfig ?? {},
        },
      });

      return this;
    },

    get props() { return location.state?.props ?? {}; },
    set props(value) { this.setProps(value) },

    get pageConfig() { return location.state?.pageConfig ?? {}; },
    set pageConfig(value) { this.setPageConfig(value) },
  };
}
export { useLocation } from "react-router-dom";
