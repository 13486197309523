import classes from "./Header.module.css";
import React from "react";
import Icon from "../components/widgets/Icon/Icon";
import { useHistory, useLocation } from "../utils/react";

export default function Header(props) {
  const history = useHistory();
  const location = useLocation();
  const currentPageIndex = location.state?.currentPageIndex ?? 0;

  function closePage() {
    history.closePage();
  }

  return (
    <div className={classes.PageHeader}>
      {currentPageIndex > 0
      && <div className={`${classes.Icon} ${props.noback ? classes.NoBack : ''}`}>{(!props.noback) ? <Icon className={classes.BackButton} style={{ cursor: "pointer", fontSize: "1.5rem" }} icon="left-arrow" onClick={closePage} /> : <></>}</div>}
      <span title={props.title} className={classes.PageTitle} style={{ fontSize: props.size}}>{props.title}</span>
      <div className={classes.Flex}/>
      {props.children}
    </div>
  )
}
