let debug = window.localStorage && window.localStorage.getItem("debug") === "true";

function loggerMiddleware() {
  return store => next => action => {
    if (!debug) {
      return next(action);
    }
    if (action.type) {
      console.group(action.type);
    } else if (typeof action === "function") {
      console.group("THUNK");
    }
    console.log("Dispatching", action);
    let state = next(action);
    console.log("Next state", store.getState());
    console.groupEnd();
    return state;
  }
}

export default loggerMiddleware();
